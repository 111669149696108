import React from 'react'
import { IonButton } from '@ionic/react'
import styled from 'styled-components'
import { dark, llmPrimary, llmPrimaryBackgroundHighlight } from 'styles/colors'

const Button = styled(IonButton)`
	--background: ${({ theme: { bgColor } }) => bgColor || dark};
`
export const PathButton = ({ children, ...props }) => (
	<Button {...props} theme={{ bgColor: llmPrimary }}>
		{children}
	</Button>
)

export const SecondaryButton = styled(Button)`
	--border-color: ${llmPrimaryBackgroundHighlight};
	--color: ${llmPrimaryBackgroundHighlight};
	--background: transparent;
	--border-width: 1px;
`

export const TertiaryButton = styled(Button)`
	--background: transparent;
	border: 2px solid ${dark};
	border-radius: 5px;
	color: ${dark};

	--background-hover: transparent;
`

export const SubmitButton = styled(IonButton)`
	--background: ${llmPrimaryBackgroundHighlight};
`

export default Button
