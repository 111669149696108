// 0. Constants:
export const DROP_OFF = 'DROP_OFF'
export const PICKUP = 'PICKUP'
export const CROSSDOCK = 'CROSSDOCK'
export const EXPENSE = 'EXPENSE'
export const AVAILABILITY = 'AVAILABILITY'

export const DROP_OFF_SUCCESS = 'DROP_OFF_SUCCESS'
export const DROP_OFF_FAILURE = 'DROP_OFF_FAILURE'
export const DROP_OFF_WAREHOUSE = 'DROP_OFF_WAREHOUSE'

export const PICKUP_LAST_MILE = 'PICKUP_LAST_MILE'
export const PICKUP_FIRST_MILE = 'PICKUP_FIRST_MILE'
export const PICKUP_FAIL = 'PICKUP_FAIL'

export const CROSSDOCK_DROP_OFF = 'CROSSDOCK_DROP_OFF'
export const CROSSDOCK_PICKUP = 'CROSSDOCK_PICKUP'

// 1. Form action types:
export const INIT_FORM = 'INIT_FORM'
export const ARRAY_ADD_BEGIN = 'ARRAY_ADD_BEGIN'
export const ARRAY_ADD_END = 'ARRAY_ADD_END'
export const ARRAY_REMOVE_ID = 'ARRAY_REMOVE_ID'
export const ARRAY_REMOVE_INDEX = 'ARRAY_REMOVE_INDEX'
export const ARRAY_REMOVE_ITEM = 'ARRAY_REMOVE_ITEM'
export const ARRAY_REMOVE_VALUE = 'ARRAY_REMOVE_VALUE'
export const ARRAY_UPDATE_ID = 'ARRAY_UPDATE_ID'
export const ARRAY_UPDATE_INDEX = 'ARRAY_UPDATE_INDEX'
export const ARRAY_UPDATE_VALUE = 'ARRAY_UPDATE_VALUE'
export const ARRAY_CLEAR_ITEMS = 'ARRAY_CLEAR_ITEMS'
export const STRING_CHANGE = 'STRING_CHANGE'
export const STRING_TOGGLE = 'STRING_TOGGLE'
export const SET_FORM = 'SET_FORM'

// 2. Action creators:
export const initForm = (fields, values) => ({
	type: INIT_FORM,
	fields,
	values
})

export const updateForm = (type, tag, value, fields, index) => ({
	type,
	tag,
	value,
	fields,
	index
})

export const setForm = form => ({
	type: SET_FORM,
	form
})

// 3. Initial states:
const initState = {}

// 4. Selectors:
export const getForm = state => state.form

// 5. Reducers:
export default function reducer(
	state = initState,
	{ type, tag, value, fields, values, index, form }
) {
	switch (type) {
		case INIT_FORM: {
			const newState = Object.assign({}, state)
			fields.forEach(field => {
				// fill form values from local storage
				if (values && values[field.tag]) {
					newState[field.tag] = values[field.tag]
				}
				// initialise form values
				// either empty array or empty string
				else {
					newState[field.tag] = field.isArray ? [] : ''
				}
			})
			return newState
		}
		case ARRAY_ADD_BEGIN: {
			// add item to the beginning of array
			return { ...state, [tag]: [value, ...(state[tag] || [])] }
		}
		case ARRAY_ADD_END: {
			// add item to the end of array
			return { ...state, [tag]: [...(state[tag] || []), value] }
		}
		case ARRAY_REMOVE_ITEM: // remove item from array
			return {
				...state,
				[tag]: state[tag].filter(item => item !== value)
			}
		case ARRAY_REMOVE_INDEX: // remove item from array by index
			return { ...state, [tag]: state[tag].filter((_, i) => i !== value) }
		case ARRAY_REMOVE_VALUE: // remove item from array by value
			return {
				...state,
				[tag]: state[tag].filter(item => item.value !== value)
			}
		case ARRAY_REMOVE_ID: // remove item from array by id
			return {
				...state,
				[tag]: state[tag].filter(item => item.id !== value)
			}
		case ARRAY_UPDATE_ID: // update item in array by id
			return {
				...state,
				[tag]: state[tag].map(item =>
					item.id === value.id ? value : item
				)
			}
		case ARRAY_UPDATE_INDEX: // update item in array by index
			return {
				...state,
				[tag]: state[tag].map((item, i) => (i === index ? value : item))
			}
		case ARRAY_UPDATE_VALUE: // update item in array by value
			return {
				...state,
				[tag]: state[tag].map(item =>
					item.value === value.value ? value : item
				)
			}
		case ARRAY_CLEAR_ITEMS: // clear all items from array
			return { ...state, [tag]: [] }
		case STRING_CHANGE: // change string value
			return { ...state, [tag]: value }
		case STRING_TOGGLE: // toggle string value
			return { ...state, [tag]: state[tag] !== value ? value : '' }
		case SET_FORM:
			return { ...state, ...form }
		default:
			// return current state
			return state
	}
}
