import React from 'react'
import { useTranslation } from 'react-i18next'
import { IonContent, IonPage } from '@ionic/react'
import styled from 'styled-components'
import VersionDisplay from 'components/VersionDisplay'
import { dark } from 'styles/colors'

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	margin: 1em;
`

const Title = styled.h1`
	font-size: 18px;
`

const Text = styled.p`
	font-size: 14px;
`

const NotFound = () => {
	const { t } = useTranslation()

	return (
		<IonPage>
			<VersionDisplay color={dark} />
			<IonContent>
				<Container>
					<img
						src="assets/icon/404.svg"
						width="200"
						height="200"
						alt="404"
					/>
					<Title>{t('NotFound.title')}</Title>
					<Text>{t('NotFound.text')}</Text>
				</Container>
			</IonContent>
		</IonPage>
	)
}

export default NotFound
