import React, { Component } from 'react'
import { IonPage } from '@ionic/react'
import styled from 'styled-components'
import Button from 'components/Button'
import log from 'utils/log'

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	margin: 1em;
`

const Title = styled.h1`
	font-size: 18px;
`

export default class ErrorBoundary extends Component {
	// Don't want to configure babel just for this 1 class, so use constructor instead..
	constructor() {
		super()
		this.state = { hasError: false }
		this.handleReload = () => {
			window.location.reload()
		}
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		log.error(`React error: ${error.toString()}`, null, info.componentStack)
	}

	render() {
		if (this.state.hasError) {
			return (
				<IonPage>
					<Container>
						<img
							src="assets/icon/noresult.svg"
							width="200"
							height="200"
							alt=""
						/>
						<Title>Sorry. Something went wrong. :(</Title>
						<Button
							onClick={this.handleReload}
							className="gtm-btn-reload"
						>
							Reload
						</Button>
					</Container>
				</IonPage>
			)
		}
		return this.props.children
	}
}
