import React from 'react'
import {
	IonItem,
	IonLabel,
	IonList,
	IonPopover,
	IonRadio,
	IonRadioGroup
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { setSummaryFilter } from 'redux/summary'

export const DeliveryStagePopover = React.memo(function DeliveryStagePopover({
	onClose
}) {
	const deliveryStage = useSelector(
		state => state.summary.filter.deliveryStage
	)
	const dispatch = useDispatch()
	const setDeliveryStage = deliveryStage =>
		dispatch(setSummaryFilter({ deliveryStage }))
	const onSelect = ({ detail }) => setDeliveryStage(detail.value)

	return (
		<IonPopover isOpen onDidDismiss={onClose}>
			<IonList>
				<IonRadioGroup value={deliveryStage}>
					<IonItem key="all">
						<IonLabel>ALL</IonLabel>
						<IonRadio
							slot="start"
							value="all"
							onIonSelect={onSelect}
						/>
					</IonItem>
					<IonItem key="FIRST_MILE">
						<IonLabel>FIRST_MILE</IonLabel>
						<IonRadio
							slot="start"
							value="FIRST_MILE"
							onIonSelect={onSelect}
						/>
					</IonItem>
					<IonItem key="LAST_MILE">
						<IonLabel>LAST_MILE</IonLabel>
						<IonRadio
							slot="start"
							value="LAST_MILE"
							onIonSelect={onSelect}
						/>
					</IonItem>
				</IonRadioGroup>
			</IonList>
		</IonPopover>
	)
})
