import React from 'react'
import styled from 'styled-components'
import { IonCardContent } from '@ionic/react'
import { StyledIonCard } from 'components/Common'
import { PathButton } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { IonSegment, IonSegmentButton } from '@ionic/react'

import { useSummaryAggregatedCount } from 'pages/Summary/hooks/aggregate'
import { SUMMARY_TAB } from 'utils/constants'

const StyledCard = styled(StyledIonCard)`
	padding: 0;
	text-align: center;
`

const SummaryCard = () => {
	const { t } = useTranslation()
	const { groupedCount } = useSummaryAggregatedCount()

	return (
		<StyledCard>
			<IonCardContent>
				<IonSegment color="dark">
					<IonSegmentButton
						key={SUMMARY_TAB.TO_PICK_UP}
						value={SUMMARY_TAB.TO_PICK_UP}
						id={`${SUMMARY_TAB.TO_PICK_UP}-button`}
						className={`gtm-btn-summary-tab-${SUMMARY_TAB.TO_PICK_UP}`}
						disabled
						style={{ opacity: 1 }}
					>
						<div>
							{t(
								`Summary.label_${SUMMARY_TAB.TO_PICK_UP.toLowerCase()}`
							)}
						</div>
						<div>×{groupedCount[SUMMARY_TAB.TO_PICK_UP]}</div>
					</IonSegmentButton>
					<IonSegmentButton
						key={SUMMARY_TAB.IN_THE_VEHICLE}
						value={SUMMARY_TAB.IN_THE_VEHICLE}
						id={`${SUMMARY_TAB.IN_THE_VEHICLE}-button`}
						className={`gtm-btn-summary-tab-${SUMMARY_TAB.IN_THE_VEHICLE}`}
						disabled
						style={{ opacity: 1 }}
					>
						<div>
							{t(
								`Summary.label_${SUMMARY_TAB.IN_THE_VEHICLE.toLowerCase()}`
							)}
						</div>
						<div>×{groupedCount[SUMMARY_TAB.IN_THE_VEHICLE]}</div>
					</IonSegmentButton>
					<IonSegmentButton
						key={SUMMARY_TAB.OFFLOADED}
						value={SUMMARY_TAB.OFFLOADED}
						id={`${SUMMARY_TAB.OFFLOADED}-button`}
						className={`gtm-btn-summary-tab-${SUMMARY_TAB.OFFLOADED}`}
						disabled
						style={{ opacity: 1 }}
					>
						<div>
							{t(
								`Summary.label_${SUMMARY_TAB.OFFLOADED.toLowerCase()}`
							)}
						</div>
						<div>×{groupedCount[SUMMARY_TAB.OFFLOADED]}</div>
					</IonSegmentButton>
				</IonSegment>
				<NavLink to="/summary">
					<PathButton>{t('Summary.view_summary')}</PathButton>
				</NavLink>
			</IonCardContent>
		</StyledCard>
	)
}

export default React.memo(SummaryCard)
