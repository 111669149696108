import React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSummaryFilter } from 'redux/summary'
import styled from 'styled-components'
import { white } from 'styles/colors'
import { crossDockParcelFilter, FILTERS } from 'utils/helpers'
import { COLOR_MAP, PARCEL_STATUS } from 'utils/constants'
import { useSummaryAggregatedCount } from 'pages/Summary/hooks/aggregate'

/** @typedef {import("redux/summary").SummaryState} SummaryState */

const Filters = styled.div`
	margin: 1em;

	span {
		display: inline-block;
		margin: 0 0.5em 0.5em 0;
	}
`

export const Status = styled.span`
	min-width: 9em;
	border: 1px solid ${({ color }) => color};
	border-radius: 0;
	padding: 0.4em 0;
	text-align: center;
	color: ${({ active, color }) => (active ? white : color)};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	background-color: ${({ active, color }) => (active ? color : white)};
	filter: ${({ disabled }) => (disabled ? 'grayscale(60%)' : 'none')};
`

export const StatusFilter = React.memo(function SummaryHeaderStatusFilter() {
	const { t } = useTranslation()
	const { statusCount } = useSummaryAggregatedCount()
	/** @type {SummaryState["filter"]["statusTag"]} */
	const statusTag = useSelector(state => state.summary.filter.statusTag)
	/** @type {SummaryState["crossdockParcelsMetadata"]} */
	const crossDocks = useSelector(
		state => state.summary.crossdockParcelsMetadata
	)
	/** @type {SummaryState["tab"]} */
	const summaryTab = useSelector(state => state.summary.tab)
	const dispatch = useDispatch()
	const crossdockFilter = useCallback(crossDockParcelFilter, [summaryTab])

	return (
		<Filters>
			{FILTERS[summaryTab].map(type => {
				// Calculate count for crossdock parcels
				let count = statusCount[type]
				if (type === PARCEL_STATUS.CROSSDOCKED) {
					count =
						crossDocks?.list?.filter(parcel =>
							crossdockFilter(parcel, summaryTab)
						).length || 0
				}
				return (
					<Status
						key={`${summaryTab}_${type}`}
						active={type === statusTag}
						color={COLOR_MAP[type]}
						onClick={() =>
							// !isToggleReorder && TODO/yuen: is this still needed?
							dispatch(
								setSummaryFilter({
									statusTag: statusTag !== type ? type : ''
								})
							)
						}
						// disabled={isToggleReorder}
						className={`gtm-btn-summary-filter-${type}`}
					>
						{t(`PARCEL_STATUS.${type}`)} ×{count}
					</Status>
				)
			})}
		</Filters>
	)
})
