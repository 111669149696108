import ShiftRegistrations, {
	ShiftRegistrationStatus
} from 'api/shiftRegistrations'
import { useEffect, useState } from 'react'
import _ from '../assets/lodash.min'

export const useShiftOverViewList = () => {
	const [list, setList] = useState({
		[ShiftRegistrationStatus.PENDING]: [],
		[ShiftRegistrationStatus.APPROVED]: [],
		[ShiftRegistrationStatus.REJECTED]: [],
		full: []
	})

	const fetchRegisteredList = async status => {
		const { data } = await ShiftRegistrations.getShiftRegistrations(status)
		const pendingList = _.filter(data, {
			status: ShiftRegistrationStatus.PENDING
		})
		const approvedList = _.filter(data, {
			status: ShiftRegistrationStatus.APPROVED
		})
		const rejectedList = _.filter(data, {
			status: ShiftRegistrationStatus.REJECTED
		})
		setList(pre => ({
			...pre,
			pending: pendingList,
			approved: approvedList,
			rejected: rejectedList,
			full: data
		}))
		return { pendingList, approvedList, rejectedList, registeredList: data }
	}

	useEffect(() => {
		fetchRegisteredList()
	}, [])

	return {
		pendingList: list[ShiftRegistrationStatus.PENDING],
		approvedList: list[ShiftRegistrationStatus.APPROVED],
		rejectedList: list[ShiftRegistrationStatus.REJECTED],
		registeredList: list.full,
		fetchRegisteredList
	}
}
