import { IonContent, IonCard } from '@ionic/react'
import styled from 'styled-components'
import { white } from 'styles/colors'

export const StyledContent = styled(IonContent)`
	--ion-background-color: #f8f9fc;
	h6 {
		margin: 1em 2em -1em 8px;
	}
`

export const StyledIonCard = styled(IonCard)`
	margin: 2em 0;
	background-color: ${white};
`
