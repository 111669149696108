import React, { useCallback } from 'react'
import { useSummaryRouteRefreshEffect } from 'pages/Summary/hooks/effect'

export const GlobalHooksRegistrationLayer = React.memo(
	function GlobalHooksRegistrationLayer({ children }) {
		useSummaryRouteRefreshEffect(
			useCallback(pathname => {
				return ['/', '/summary', '/dropoff'].includes(pathname)
			}, [])
		)

		return children
	}
)
