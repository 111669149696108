import React, { useEffect, useRef, useState } from 'react'
import {
	IonButton,
	IonCardContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonList,
	IonModal,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	IonCard,
	IonItem,
	IonLabel,
	IonAlert
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import moment from 'moment'
import styled from 'styled-components'
import { StyledContent } from 'components/Common'
import { ParcelAddress } from './ParcelAddress'
import {
	CROSSDOCK_STATUS,
	COLOR_MAP,
	PARCEL_STATUS,
	SUMMARY_TAB
} from 'utils/constants'
import {
	formatDisplay,
	setLocation,
	saveFormValues,
	getFormValues,
	getFormModelsByClientId,
	findFormat,
	getIsDriverMode
} from 'utils/helpers'
import Button from 'components/Button'
import { dark, llmPrimary, white, silver } from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { monospaceFont } from 'styles/fonts'
import Dialog from 'components/Form/Dialog'
import storage, { LOCATION, DISPLAY_FORMATS } from 'utils/storage'
import { submit, saveSummary } from 'containers/ConnectedForm'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
	setSummaryOpenedParcel,
	setPendingParcels,
	updateCrossdockParcel,
	updateSummaryItem,
	updateSummaryItemStatus
} from 'redux/summary'
import { useSummaryGroupedByBarcodeParcels } from 'pages/Summary/hooks/aggregate'
import _ from 'assets/lodash.min'
import { getLastSelectedClientId } from 'redux/config'
import { setForm } from 'redux/form'
import { driverWebviewSdk } from 'utils/driverApp'

const CROSSDOCK_STATUS_MAP = {
	PENDING: PARCEL_STATUS.PENDING,
	REJECTED: PARCEL_STATUS.NOT_CROSSDOCKED,
	APPROVED: PARCEL_STATUS.CROSSDOCKED
}

const ListCard = styled(IonCard)`
	padding: 0.5em 0;
	margin: 0 1em;
	border: 1px solid ${({ active }) => (active ? llmPrimary : silver)};
	border-radius: 0;
	background-color: ${white};
	ion-card-header {
		padding-top: 0.5em;
		border-bottom: 1px solid
			${({ active }) => (active ? llmPrimary : silver)};
	}
	ion-card-title {
		font-size: 16px;
		text-transform: uppercase;
		span:last-child {
			float: right;
			font-size: 14px;
			font-weight: bold;
			ion-icon {
				vertical-align: middle;
				font-size: 20px;
				color: ${({ active }) => (active ? llmPrimary : silver)};
			}
		}
	}
	ion-card-content {
		padding: 0 1em;
	}
`

const ListItem = styled(IonItem)`
	--background: ${white};
	img {
		width: 24px;
		margin-right: 0.5em;
	}
`

const LogList = styled.ul`
	position: relative;
	padding: 0;
	margin: 0;
	list-style: none;

	&:before {
		content: '';
		position: absolute;
		top: 1.5em;
		bottom: 1.5em;
		left: 9px;
		border-left: 2px dotted ${llmPrimary};
	}
`

const LogListItem = styled.li`
	position: relative;
	padding-left: 28px;
`

const LogItemContainer = styled.div`
	padding: 12px 0;
	line-height: 1.4;

	&:before {
		content: '✓';
		position: absolute;
		top: 50%;
		width: 20px;
		height: 20px;
		background-color: ${llmPrimary};
		color: ${white};
		font-size: 0.9em;
		font-weight: bold;
		line-height: 20px;
		text-align: center;
		border-radius: 50%;
		box-shadow: 0 0 0 2px ${white};
		transform: translate(-28px, -10px);
	}
`

const LogItemContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${dark};

	span {
		margin: 0 0.5em;
	}
	span:last-child {
		font-weight: bold;
	}
`

const MainButton = styled(Button)`
	margin: 0;
	--background: ${({ bgColor }) => bgColor || dark};
`

const QuickActionButton = styled(Button)`
	height: 4em;
	margin: 1em;
	white-space: normal;
	font-weight: normal;
	color: ${dark};
	--border-width: 2px;
	--border-style: solid;
	--border-color: ${llmPrimary};
	--border-radius: ${({ squared }) => (squared ? '0' : '0.5em')};
	--background: ${white};
	--background-hover: #f8f9fc;
	--background-focused: #f8f9fc;
	--color-focused: ${dark};
	--color-activated: ${dark};
`

export const Text = styled(IonLabel)`
	margin: 0 0.5em 0 0;

	&& {
		white-space: normal;
		font-family: ${monospaceFont};
		font-size: ${({ large }) => (large ? 24 : 20)}px;
		font-weight: ${({ large }) => (large ? 'bold' : 'normal')};
	}
`

const Status = styled.span`
	min-width: 9em;
	border: 1px solid ${({ color }) => color};
	border-radius: 0;
	padding: 0.4em 0;
	text-align: center;
	color: ${({ active, color }) => (active ? white : color)};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	background-color: ${({ active, color }) => (active ? color : white)};
	filter: ${({ disabled }) => (disabled ? 'grayscale(60%)' : 'none')};
`
/** @type {React.NamedExoticComponent<{ parcel: import('redux/summary').SummaryState["openedParcel"] }>} */
export const ParcelDetail = React.memo(function SummaryParcelDetail({
	parcel: { parcel, availableActions = [] }
}) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const [showQuickActions, setShowQuickActions] = useState(false)
	const [currentQuickAction, setCurrentQuickAction] = useState({})
	const [dialogMessage, setDialogMessage] = useState('')

	const nonBarcodeField = useRef('BARCODE')
	const [detailTabKey, setDetailTabKey] = useState('STOPS')
	const [showDifferentClientAlert, setShowDifferentClientAlert] =
		useState(false)
	const [isNonBarcodeEnabled, setIsNonBarcodeEnabled] = useState(false)
	const meta = useSelector(state => state.meta)
	const groupedByBarcodeParcels = useSummaryGroupedByBarcodeParcels()
	/** @type {import('redux/summary').SummaryState["pendingParcels"]} */
	const pendingParcels = useSelector(state => state.summary.pendingParcels)
	const tab = useSelector(state => state.summary.tab)
	const lastSelectedClientId = useSelector(getLastSelectedClientId)
	const isDriverMode = getIsDriverMode()

	const setSummaryItemStatus = (...args) =>
		dispatch(updateSummaryItemStatus(...args))

	const onActionButtonClick = action => {
		setCurrentQuickAction(action)
		setDialogMessage(t('Summary.message_quick_action_confirmation'))
	}
	const onDialogConfirm = async () => {
		await submitQuickAction()
		setDialogMessage('')
		dispatch(setSummaryOpenedParcel(null))
	}

	const submitQuickAction = async () => {
		const data = currentQuickAction.input
		const isRescheduled = data.fields.some(
			f => f.tag === 'REASONS_FAILURE' && f.values[0].startsWith('17')
		)
		data.submittedAt = new Date().toISOString()
		data.locationId = storage.getItem(LOCATION)
		data.clientId = currentQuickAction.clientId
		data.fields.forEach(field => {
			let status
			if (data.formId.endsWith(PARCEL_STATUS.DROP_OFF_FAILURE)) {
				status = isRescheduled
					? PARCEL_STATUS.RESCHEDULED
					: PARCEL_STATUS.FAILED
			} else {
				status = PARCEL_STATUS.DELIVERED
			}
			if (field.tag === 'BARCODE') {
				field.values.forEach(value => {
					setSummaryItemStatus(value, status, false)
					dispatch(
						updateSummaryItem(value, {
							logs: [
								...parcel.logs,
								{
									status: PARCEL_STATUS.RESCHEDULED,
									time: data.submittedAt
								}
							]
						})
					)
				})
			}
		})
		await setLocation(meta)
		// eslint-disable-next-line require-atomic-updates
		currentQuickAction.input = Object.assign(currentQuickAction.input, {
			meta: { ...meta, submittedVia: 'QUICK_ACTION' }
		})
		saveSummary(
			currentQuickAction.input,
			currentQuickAction.clientId,
			(newParcelsObject, { failureReason, newStatus }) => {
				const submittedAt = currentQuickAction.input.submittedAt
				const pendingRefSet = new Set(pendingParcels)
				for (const [parcelId, parcel] of _.toPairs(newParcelsObject)) {
					const currentStatus =
						newStatus === PARCEL_STATUS.FAILED &&
						failureReason.startsWith('17')
							? PARCEL_STATUS.RESCHEDULED
							: newStatus

					const payload = {
						id: parcelId,
						...parcel,
						status: currentStatus,
						logs: [
							...(parcel.logs || []),
							{
								status: currentStatus,
								time: submittedAt
							}
						]
					}

					// for backward-compatible reason, only need to update non-crossDock parcels
					if (CROSSDOCK_STATUS.includes(currentStatus)) {
						dispatch(updateCrossdockParcel(payload))
					}

					if (parcel.pending) {
						pendingRefSet.add(parcelId)
					}
				}
				dispatch(setPendingParcels([...pendingRefSet]))
			}
		)
		await submit(data, { allParcels: groupedByBarcodeParcels })
	}

	const getTargetFormTag = () => {
		const action = tab === SUMMARY_TAB.TO_PICK_UP ? 'pickup' : 'dropoff'

		const deliveryStage = parcel.deliveryStage || 'LAST_MILE'

		const formMap = {
			FIRST_MILE: {
				pickup: 'PICKUP_FIRST_MILE',
				dropoff: 'DROP_OFF_WAREHOUSE'
			},
			LAST_MILE: {
				pickup: 'PICKUP_LAST_MILE',
				dropoff: 'DROP_OFF_SUCCESS'
			}
		}

		return formMap[deliveryStage][action]
	}

	const generateNonBarcodeFormValuesBarcode = () => {
		const metas = [
			{
				format: 'MANUAL',
				formattedValue: false,
				frame: null,
				hits: 1,
				...(parcel.orderId ? { orderId: parcel.orderId } : {}),
				rawValue: parcel.id,
				scannedAt: new Date().toISOString(),
				timestamp: Date.now(),
				value: parcel.id,
				clientId: parcel.clientId
			}
		]

		const displayFormats = (
			JSON.parse(storage.getItem(DISPLAY_FORMATS)) || []
		)
			.filter(({ clientId }) => parcel.clientId === clientId)
			.map(({ format }) => format)

		return {
			value: parcel.id,
			displayFormat: findFormat(displayFormats, parcel.id),
			duration: 0,
			maunal: true,
			noPickup: false,
			metas
		}
	}

	const onNonBarcodeButtonClick = () => {
		const formValues = getFormValues()
		const fieldTag = nonBarcodeField.current
		if (
			lastSelectedClientId &&
			lastSelectedClientId !== parcel.clientId &&
			formValues[fieldTag].length > 0
		) {
			setShowDifferentClientAlert(true)
		} else {
			const newBarcode = generateNonBarcodeFormValuesBarcode()
			const newFormValues = {
				...formValues,
				[fieldTag]: _.uniqBy(
					[...formValues[fieldTag], newBarcode],
					'value'
				)
			}
			dispatch(setForm(newFormValues))
			saveFormValues(newFormValues)
			goToFormSubmissionPage(parcel.clientId)
		}
	}

	const goToFormSubmissionPage = clientId => {
		setShowDifferentClientAlert(false)
		dispatch(setSummaryOpenedParcel(null))
		const formTag = getTargetFormTag()
		const action = tab === SUMMARY_TAB.TO_PICK_UP ? 'pickup' : 'dropoff'
		const destination = `/${action}`
		if (isDriverMode) {
			driverWebviewSdk.changeTab(destination)
		}
		history.push(destination, {
			formTag,
			clientId
		})
	}

	const onNonBarCodeDeliveryProceed = () => {
		const formValues = getFormValues()
		const newBarcode = generateNonBarcodeFormValuesBarcode()
		const newFormValues = {
			...formValues,
			BARCODE: [],
			ORDER_NO: [],
			[nonBarcodeField.current]: [newBarcode]
		}
		dispatch(setForm(newFormValues))
		saveFormValues(newFormValues)
		goToFormSubmissionPage(parcel.clientId)
	}

	useEffect(() => {
		const targetFormTag = getTargetFormTag()
		const clientId = parcel.clientId
		const clientForms = getFormModelsByClientId(clientId)
		const commonForms = getFormModelsByClientId('COMMON')
		const targetForm =
			clientForms.find(({ tag }) => tag === targetFormTag) ||
			commonForms.find(({ tag }) => tag === targetFormTag)

		if (!targetForm) {
			return
		}
		const fieldTag =
			targetForm.fields.find(
				({ isNonBarcodeDelivery }) => isNonBarcodeDelivery
			)?.tag || 'BARCODE'
		nonBarcodeField.current = fieldTag

		let deliveryHasCorrectStatus

		const enablePickupButtonStatuses = [
			PARCEL_STATUS.AWAITS_PICKUP,
			PARCEL_STATUS.FAILED_PICKUP
		]
		const enableDropOffButtonStatuses = [
			PARCEL_STATUS.IN_PROGRESS, // DELIVERING status is changed to IN_PROGRESS
			PARCEL_STATUS.FAILED,
			PARCEL_STATUS.RESCHEDULED
		]

		if (tab === SUMMARY_TAB.TO_PICK_UP) {
			deliveryHasCorrectStatus = enablePickupButtonStatuses.includes(
				parcel.status
			)
		} else if (tab === SUMMARY_TAB.IN_THE_VEHICLE) {
			deliveryHasCorrectStatus = enableDropOffButtonStatuses.includes(
				parcel.status
			)
		}

		setIsNonBarcodeEnabled(
			Boolean(targetForm.attributes.nonBarcodeDeliveryEnabled) &&
				Boolean(deliveryHasCorrectStatus)
		)

		setDetailTabKey('STOPS')
		return () => dispatch(setSummaryOpenedParcel(null))
	}, [dispatch])

	return (
		<IonModal
			id="summary_overlay"
			backdropDismiss={false}
			isOpen
			animated={false}
		>
			<IonHeader>
				<IonToolbar color="dark">
					<IonButton
						slot="start"
						size="medium"
						color="dark"
						onClick={() => {
							if (showQuickActions) {
								setShowQuickActions(false)
							} else {
								dispatch(setSummaryOpenedParcel(null))
							}
						}}
						className="gtm-btn-summary-back"
					>
						<IonIcon icon={arrowBack} />
					</IonButton>
					<IonTitle color="light">
						{t(
							`Summary.label_${
								showQuickActions
									? 'quick_submission'
									: 'details'
							}`
						)}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<StyledContent>
				<IonList>
					<ListCard>
						<ListItem lines="none">
							<Text large>
								{formatDisplay(parcel.id, parcel.format)}
								{parcel.id === parcel.orderId &&
									` (${t('Summary.label_order')})`}
							</Text>
							<Status active color={COLOR_MAP[parcel.status]}>
								{t(
									`PARCEL_STATUS.${
										CROSSDOCK_STATUS_MAP[parcel.status] ||
										parcel.status
									}`
								)}
							</Status>
						</ListItem>
					</ListCard>
				</IonList>
				{!showQuickActions && (
					<IonSegment
						color="dark"
						value={detailTabKey}
						onIonChange={e => setDetailTabKey(e.target.value)}
					>
						<IonSegmentButton
							value="STOPS"
							className="gtm-btn-summary-tab-STOPS"
						>
							{t('Summary.label_stops')}
						</IonSegmentButton>
						<IonSegmentButton
							value="LOGS"
							className="gtm-btn-summary-tab-LOGS"
						>
							{t('PORTAL.Deliveries.heading_logs')}
						</IonSegmentButton>
					</IonSegment>
				)}
				<br />
				{!showQuickActions && detailTabKey === 'STOPS' && (
					<ParcelAddress parcel={parcel} />
				)}
				{!showQuickActions &&
					detailTabKey === 'LOGS' &&
					parcel.logs &&
					parcel.logs.length > 0 && (
						<ListCard>
							<IonCardContent>
								<LogList>
									{parcel.logs
										.sort(
											(a, b) =>
												new Date(a.time) -
												new Date(b.time)
										)
										.map((log, i) => (
											<LogListItem key={i}>
												<LogItemContainer>
													<LogItemContent>
														<div>
															<span>
																{moment(
																	log.time
																).format(
																	t(
																		'Datetime.date_display'
																	)
																)}
															</span>
															<span>
																{moment(
																	log.time
																).format(
																	t(
																		'Datetime.time_display'
																	)
																)}
															</span>
														</div>
														<Status
															active
															color={
																COLOR_MAP[
																	log.status
																]
															}
														>
															{t(
																`PARCEL_STATUS.${
																	CROSSDOCK_STATUS_MAP[
																		log
																			.status
																	] ||
																	log.status
																}`
															)}
														</Status>
													</LogItemContent>
												</LogItemContainer>
											</LogListItem>
										))}
								</LogList>
							</IonCardContent>
						</ListCard>
					)}
				{showQuickActions &&
					availableActions.map((action, index) => (
						<QuickActionButton
							key={index}
							expand="block"
							size="large"
							bgColor={action.attributes.bgColor}
							onClick={() => onActionButtonClick(action)}
							className="gtm-btn-summary-quick-action-item"
						>
							{action.translation.value}
						</QuickActionButton>
					))}
			</StyledContent>
			{!showQuickActions && availableActions.length > 0 && (
				<IonFooter>
					<IonToolbar>
						<MainButton
							expand="full"
							size="large"
							bgColor={llmPrimary}
							onClick={() => setShowQuickActions(true)}
							className="gtm-btn-summary-quick-action"
						>
							{t('Summary.label_quick_submission')}
						</MainButton>
					</IonToolbar>
				</IonFooter>
			)}
			{isNonBarcodeEnabled && (
				<IonFooter>
					<IonToolbar>
						<MainButton
							expand="full"
							size="large"
							bgColor={llmPrimary}
							onClick={onNonBarcodeButtonClick}
							className="gtm-btn-summary-quick-action"
						>
							{t(
								tab === SUMMARY_TAB.TO_PICK_UP
									? 'Summary.button_details_pickup'
									: 'Summary.button_details_dropoff'
							)}
						</MainButton>
					</IonToolbar>
				</IonFooter>
			)}
			<IonAlert
				isOpen={showDifferentClientAlert}
				message={t('Summary.message_nonbarcode_form_mismatch_warning', {
					clientId: lastSelectedClientId
				})}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						handler: () => {
							goToFormSubmissionPage(lastSelectedClientId)
						}
					},
					{
						text: 'Proceed',
						handler: onNonBarCodeDeliveryProceed
					}
				]}
			/>
			{dialogMessage && (
				<Dialog
					isOpen
					id="summary_overlay"
					header={dialogMessage}
					onNo={() => setDialogMessage('')}
					onDismiss={() => setDialogMessage('')}
					onYes={onDialogConfirm}
					hasOptions
				/>
			)}
		</IonModal>
	)
})
