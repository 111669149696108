import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setPendingParcels, setSummaryOpenedParcel } from 'redux/summary'
import { DRIVER_APP_NAVIGATION_EVENT } from 'utils/constants'
import { clearCacheData } from 'utils/helpers'

export const DriverAppWebviewAccessLayer = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		document.onDriverAppClearCache = () => {
			clearCacheData()
			dispatch(setPendingParcels([]))
		}

		const handleDriverAppNavigation = e => {
			const overlays = document.querySelectorAll(`[id^=ion-overlay]`)
			overlays.forEach(overlay => {
				/**
				 * https://lalamove.atlassian.net/browse/DEMAND-9744
				 * When using ion-modal with Angular, React, or Vue, the component you pass in will be destroyed when the modal is dismissed.
				 * then it can't reopen when trigger DRIVER_APP_NAVIGATION_EVENT
				 * so when dismiss modal, skip client-selection-modal
				 */
				if (!overlay.className.includes('client-selection-modal')) {
					overlay.dismiss()
				}
			})
			dispatch(setSummaryOpenedParcel(null))
			history.push(e.detail.route)
		}

		document.onDriverAppNavigation = route => {
			document.dispatchEvent(
				new CustomEvent(DRIVER_APP_NAVIGATION_EVENT, {
					detail: { route }
				})
			)
		}

		document.addEventListener(
			DRIVER_APP_NAVIGATION_EVENT,
			handleDriverAppNavigation
		)

		return () => {
			document.removeEventListener(
				DRIVER_APP_NAVIGATION_EVENT,
				handleDriverAppNavigation
			)
		}
	}, [])

	return null
}
