import axios from 'axios'
import storage, { TOKEN, DATA_CENTER } from 'utils/storage'
import { getIsDriverMode, logout } from 'utils/helpers'
import { v4 as uuidV4 } from 'uuid'
import * as Sentry from '@sentry/react'

const {
	REACT_APP_ENV,
	REACT_APP_SG_API_URL,
	REACT_APP_NAME,
	REACT_APP_VERSION,
	REACT_APP_BR_API_URL
} = process.env

const getApiUrl = () => {
	// only decide on prd
	if (REACT_APP_ENV !== 'prd' || typeof REACT_APP_BR_API_URL !== 'string') {
		return REACT_APP_SG_API_URL
	}
	const currentDataCenter = storage.getItem(DATA_CENTER) || 'sg'
	switch (currentDataCenter) {
		case 'sg':
			return REACT_APP_SG_API_URL
		default:
			return REACT_APP_BR_API_URL
	}
}

const axiosApiClient = axios.create({
	baseURL: getApiUrl(),
	headers: {
		common: {
			'X-LLM-APPNAME': REACT_APP_NAME,
			'X-LLM-VERSION': REACT_APP_VERSION
		}
	},
	timeout: 5000
})

axiosApiClient.interceptors.request.use(config => {
	const isDriverMode = getIsDriverMode()
	if (isDriverMode) {
		config.headers['X-IS-DRIVER-APP'] = true
	}
	config.headers['X-Request-Id'] = uuidV4()
	const token = storage.getItem(TOKEN)
	if (token) {
		config.headers.Authorization = isDriverMode ? token : `Bearer ${token}`
	}

	return config
})

axiosApiClient.interceptors.response.use(undefined, error => {
	const isDriverMode = getIsDriverMode()

	if (error.response && error.response.status === 401) {
		if (isDriverMode) {
			Sentry.captureMessage('isDriverMode:logout error status 401', {
				level: 'warning',
				extra: {
					error,
					method: error.response.config.method,
					path: error.response.config.url,
					requestId: error.config.headers['X-Request-Id']
				}
			})
			logout()
		} else {
			alert('Session expired!')
			logout()
		}
	}
	return Promise.reject(error)
})

axiosApiClient.CancelToken = axios.CancelToken
axiosApiClient.isCancel = axios.isCancel

export default axiosApiClient
