const LLMToLLMPlusLangMap = {
	'gu-IN': 'en-IN',
	'kn-IN': 'en-IN',
	'ta-IN': 'en-IN',
	'zh-CN': 'zh-HK'
}

export const getDriverURIFragment = () => {
	// We only get once on app init and there is read only logic,
	// so we decided to use global object instead of revamp the whole app init flow
	// eslint-disable-next-line no-restricted-globals
	const url = new URLSearchParams(location.hash.replace('#', ''))
	return [
		url.get('token'),
		url.get('city_id'),
		formatUrlHLang(url.get('hlang'))
	]
}

/**
 * @param {string} hlang - hlang from hash, like en_hk
 * @returns {string} like en-HK
 */
const formatUrlHLang = hlang => {
	const formatLang = hlang?.replace(/_([a-z]+)/, (s, s1) => {
		return '-' + s1.toUpperCase()
	})
	return LLMToLLMPlusLangMap[formatLang] ?? formatLang
}
