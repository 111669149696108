import { IonRadio } from '@ionic/react'
import styled from 'styled-components'
import { dark } from 'styles/colors'

const RadioButton = styled(IonRadio)`
	--color: ${({ theme: { bgColor } }) => bgColor || dark};
	--color-checked: ${({ theme: { bgColor } }) => bgColor || dark};
`

export default RadioButton
