import { getUserId } from 'utils/helpers'

export const pushEvent = (name, prop = {}) => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		event: name,
		userId: getUserId(),
		...prop
	})
}
