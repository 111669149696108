import storage, {
	FORM_MODELS,
	CLIENTS,
	SUMMARY_QUICK_ACTIONS,
	DISPLAY_FORMATS
} from 'utils/storage'
import { safeJSONParse, getTranslationValue } from 'utils/helpers'
import { useState, useEffect, useRef } from 'react'
import { getSummaryQuickActions } from 'api/summaries'

/**	@typedef {Record<string, string>} SummaryClientNames */
/**	@typedef {{format: string, clientId: string}[]} SummaryDisplayFormats */

export const useSummaryAttributes = () => {
	const { clients } = useSummaryBarcodeAttributes()
	/** @type {React.MutableRefObject<SummaryDisplayFormats>} */
	const displayFormats = useRef(null)
	/** @type {[SummaryClientNames, React.Dispatch<SetStateAction<SummaryClientNames>>]} */
	const [clientNames, setClientNames] = useState({})

	useEffect(() => {
		displayFormats.current =
			safeJSONParse(storage.getItem(DISPLAY_FORMATS)) || []

		setClientNames(
			clients.current?.reduce((acc, client) => {
				const { id, translations } = client
				acc[id] = getTranslationValue(translations, id, 'name')
				return acc
			}, {}) || {}
		)
	}, [clients])

	return { clientNames, displayFormats }
}

/**
	@typedef SummaryAttributes
	@property {{
		clientId: string,
		format: string,
		name: string,
		regex: string[],
		type: string
	}} valueFormats
 */

/**
	@typedef {{
		createdAt: Date,
		id: string,
		logo: string | null,
		translations: object[],
		domains: string[],
		baseClientId: object | null,
		attributes: object,
		canCancelOrders: boolean,
		displayInLabel: string[],
		orderTags: string[],
		updatedAt: Date
	}} SummaryClients
 */

export const useSummaryBarcodeAttributes = () => {
	/** @type {import("react").MutableRefObject<SummaryAttributes>} */
	const attributes = useRef({})
	/** @type {import("react").MutableRefObject<SummaryClients>} */
	const clients = useRef([])

	useEffect(() => {
		const clientList = safeJSONParse(storage.getItem(CLIENTS))
		const forms = safeJSONParse(storage.getItem(FORM_MODELS))
		if (clientList) {
			clients.current = clientList
		}

		if (forms && forms['COMMON']) {
			let attrs = {}
			for (const form of forms['COMMON']) {
				const field = form.fields.find(
					field => field.id === 'COMMON_BARCODE'
				)
				if (field) {
					attrs = { ...field.attributes }
					if (!attrs.valueFormats) {
						attrs.valueFormats = []
					}
					// filter out formats of unknown clients:
					attrs.valueFormats = attrs.valueFormats.filter(
						obj =>
							!obj.clientId ||
							clientList.find(c => c.id === obj.clientId)
					)
					// convert all regexp string to RegExp object:
					attrs.valueFormats.forEach(obj => {
						obj.regexp = (obj.regexp || []).map(
							str => new RegExp(str)
						)
					})
					break
				}
			}
			attributes.current = attrs
		}
	}, [])

	return { attributes, clients }
}

/**
 * @typedef {{
 * 	[key: string]: import('api/summaries').SummaryQuickAction
 * }} SummaryQuickActionState
 */

export const useSummaryQuickActions = () => {
	/** @type {[SummaryQuickActionState, React.Dispatch<SetStateAction<SummaryQuickActionState>>]} */
	const [quickActions, setQuickActions] = useState([])

	useEffect(() => {
		const existingQuickActions =
			safeJSONParse(storage.getItem(SUMMARY_QUICK_ACTIONS)) || []

		getSummaryQuickActions()
			.then(quickActions => {
				if (!quickActions) {
					setQuickActions(existingQuickActions)
					return
				}

				/** @type {SummaryQuickActionState} */
				const newQuickActions = quickActions.reduce(
					(accumulator, value) => {
						const { clientId } = value
						if (accumulator[clientId] === undefined) {
							accumulator[clientId] = [value]
						} else {
							accumulator[clientId].push(value)
						}
						return accumulator
					},
					{}
				)
				setQuickActions(newQuickActions)
				storage.setItem(
					SUMMARY_QUICK_ACTIONS,
					JSON.stringify(newQuickActions)
				)
			})
			.catch(e => {
				// do nothing?
			})
	}, [])

	return quickActions
}
