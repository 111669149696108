import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import ErrorBoundary from 'components/ErrorBoundary'
import store, { persistor } from 'redux/store'
import i18n from 'utils/i18n'
import log from 'utils/log'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { PersistGate } from 'redux-persist/integration/react'

const UPDATE_INTERVAL = 10 // minutes

if (process.env.REACT_APP_ENV !== 'ldev') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENV,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		release: process.env.REACT_APP_VERSION,
		ignoreErrors: [
			'Network Error',
			'timeout of 5000ms exceeded',
			'document.onDriverAppNavigation is not a function'
		]
	})
}

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<I18nextProvider i18n={i18n}>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</I18nextProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// manually instruct the SW to fetch update
const fetchUpdate = async () => {
	log.info('Try to fetch update.')
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.ready
			log.info('Fetching update...')
			const swRegObj = await registration.update()
			log.info('fetchUpdate-reg', swRegObj)
		} catch (e) {
			log.error('Service worker update failed', null, e.stack)
		}
	}
}

let updateTimer
if (!updateTimer) {
	updateTimer = setInterval(() => {
		fetchUpdate()
	}, UPDATE_INTERVAL * 60000)
}

// upon successfull SW update, tell the SW to skip waiting
// and immediately apply the updates, then reload the page
const updateNow = registration => {
	log.info('updateNow-reg', registration)
	const waitingServiceWorker = registration.waiting
	if (waitingServiceWorker) {
		log.info('Waiting...')
		waitingServiceWorker.addEventListener('statechange', event => {
			log.info('swstate', event.target.state)
			if (event.target.state === 'activated') {
				alert(`New version available. App will be updated.`)
				window.location.reload(true)
			}
		})
		waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
	}
}

const config = {
	onUpdate: updateNow,
	onSuccess: () => log.info('SW installed successfully')
}
serviceWorker.register(config)
