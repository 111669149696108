import React from 'react'
import _ from 'assets/lodash.min'
import {
	IonCol,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonSelect,
	IonSelectOption
} from '@ionic/react'
import ShiftRegistrations, {
	ShiftRegistrationStatus
} from 'api/shiftRegistrations'
import Header from 'components/Header'
import { useShiftOverViewList } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
	llmPrimaryBackground,
	llmPrimaryBackgroundHighlight,
	llmPrimaryBackgroundTextColor,
	llmBlue,
	llmSuccess,
	llmError
} from 'styles/colors'
import { getDates } from 'utils/helpers'
import { getHourSecondString } from 'utils/shift'
import { SecondaryButton, SubmitButton } from 'components/Button'
import { Trans, useTranslation } from 'react-i18next'
import MessageToast from 'components/Form/MessageToast'

// Styled
const StyledSegment = styled(IonSegment)`
	--background: ${llmPrimaryBackground};
	display: grid;
	grid-template-columns: repeat(7, 1fr);
`
const StyledButton = styled(IonSegmentButton)`
	--background-checked: ${llmPrimaryBackgroundHighlight};
	--color: ${llmPrimaryBackgroundTextColor};
	--color-checked: ${llmPrimaryBackgroundTextColor};
	--indicator-color-checked: transparent;
	min-width: initial;
`

const StyledMonth = styled.div`
	background-color: ${llmPrimaryBackground};
	height: 40px;
	text-align: center;
	font-size: 20px;
	color: ${llmPrimaryBackgroundTextColor};
	line-height: 40px;
`

const StyledContainer = styled.div`
	padding: 16px 0 80px;
	max-height: calc(100vh - 190px);
	overflow-y: scroll;
`

const StyledSubtitle = styled.div`
	padding: 12px 8px;
	font-weight: 500;
`

const StyledCol = styled(IonCol)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`
const StyledLabel = styled.div`
	color: ${llmPrimaryBackgroundHighlight};
	border-radius: 4px;
	font-weight: 400;
	margin: 4px 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const StyledSelect = styled(IonSelect)`
	border: 1px solid ${llmPrimaryBackgroundHighlight};
	width: 180px;
	padding: 0.5em 1em;
	border-radius: 4px;
	color: ${llmPrimaryBackgroundHighlight};
`
const ActionContainer = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const NoShiftsMessage = styled.div`
	padding: 8px 0;
	text-align: center;
	font-weight: bold;
`

export const RegisterButton = styled(SecondaryButton)`
	width: fit-content;
	${prop => {
		switch (prop.status) {
			case ShiftRegistrationStatus.PENDING:
				return css`
					--border-color: ${llmBlue};
					--color: ${llmBlue};
				`
			case ShiftRegistrationStatus.APPROVED:
				return css`
					--border-color: transparent;
					--color: ${llmSuccess};
					--opacity: 1;
				`
			case ShiftRegistrationStatus.REJECTED:
				return css`
					--border-color: transparent;
					--color: ${llmError};
					--opacity: 1;
				`
			default:
				return
		}
	}}
`
const isSameDate = (date1, date2) =>
	date1.toDateString() === date2.toDateString()

const getShiftsOfSpecifiedDate = (shiftList, dateToBeDisplayed) =>
	shiftList.filter(shift =>
		isSameDate(new Date(shift.shiftStartAt), dateToBeDisplayed)
	)

const Page = {
	RegisterList: 'REGISTER_LIST',
	ShiftRegisterForm: 'SHIFT_REGISTER_FORM'
}

const TODAY = '0'

const TimeList = ({ shifts, makeRegister, registeredList, applyWithdraw }) => {
	const { t } = useTranslation()
	const getShouldDisableButton = (shiftCutoffAt, status) =>
		moment(shiftCutoffAt).isBefore(new Date()) ||
		status === ShiftRegistrationStatus.APPROVED ||
		status === ShiftRegistrationStatus.REJECTED

	return (
		<StyledContainer>
			{_.isEmpty(shifts) ? (
				<NoShiftsMessage>
					{t('Shift.text_no_shifts_today')}
				</NoShiftsMessage>
			) : (
				shifts.map(shift => {
					const registeredRecord = registeredList.find(
						record => record.shift.id === shift.id
					)
					const shouldDisableButton = getShouldDisableButton(
						shift.registrationCutoffAt,
						registeredRecord?.status
					)
					const button = (
						<RegisterButton
							fill="outline"
							onClick={() =>
								_.isNil(registeredRecord)
									? makeRegister(shift)
									: applyWithdraw(shift.id)
							}
							disabled={shouldDisableButton}
							status={registeredRecord?.status}
						>
							{_.isNil(registeredRecord)
								? t('Shift.button_register')
								: t(
										`Shift.button_shift_page_${_.toLower(
											registeredRecord.status
										)}`
								  )}
						</RegisterButton>
					)

					return (
						<IonRow key={shift.id}>
							<StyledCol>{getHourSecondString(shift)}</StyledCol>
							<StyledCol>
								<StyledLabel>{button}</StyledLabel>
							</StyledCol>
						</IonRow>
					)
				})
			)}
		</StyledContainer>
	)
}

const RegisterForm = ({ shiftDate, shift, handleSubmit, handleBack }) => {
	const { t } = useTranslation()
	const [districts, setDistricts] = useState([])
	const [showErrorMessageToast, setShowErrorMessageToast] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const submitClick = async () => {
		const isCutOffed = moment(shift.registrationCutoffAt).isBefore(
			new Date()
		)

		if (isCutOffed) {
			setErrorMessage(t('Shift.message_shift_cutoff_warning'))
			setShowErrorMessageToast(true)
			return
		}
		try {
			await handleSubmit({ districts, shiftId: shift.id })
		} catch (e) {
			setErrorMessage(
				_.get(
					e,
					'response.data.errors.0.detail',
					t('Shift.error_registration_fail')
				)
			)
			setShowErrorMessageToast(true)
		}
	}
	const backClick = () => {
		handleBack()
	}

	useEffect(() => {
		let timeoutId
		if (showErrorMessageToast) {
			timeoutId = setTimeout(() => setShowErrorMessageToast(false), 3000)
		}
		return () => clearTimeout(timeoutId)
	}, [showErrorMessageToast])

	return (
		<StyledContainer>
			<IonRow>
				<StyledCol size={4}>{t('Shift.label_shift_date')}</StyledCol>
				<StyledCol>
					<StyledLabel>
						{`${moment.months(shiftDate.getMonth())}, ${moment(
							shiftDate
						).format('Do')} `}

						<br />
						{moment.weekdays(shiftDate.getDay())}
					</StyledLabel>
				</StyledCol>
			</IonRow>
			<IonRow>
				<StyledCol size={4}>{t('Shift.label_shift_time')}</StyledCol>
				<StyledCol>
					<StyledLabel>{getHourSecondString(shift)}</StyledLabel>
				</StyledCol>
			</IonRow>
			{shift.districts && (
				<IonRow>
					<StyledCol size={4}>
						<Trans i18nKey="Shift.label_preferred_district" />
					</StyledCol>
					<StyledCol>
						<StyledLabel>
							<StyledSelect
								interfaceOptions={{
									header: t('Shift.text_max_select_district')
								}}
								onIonChange={e => setDistricts(e.detail.value)}
								mode="ios"
								multiple
							>
								{(shift.districts || []).map(ele => (
									<IonSelectOption key={ele} value={ele}>
										{ele}
									</IonSelectOption>
								))}
							</StyledSelect>
						</StyledLabel>
					</StyledCol>
				</IonRow>
			)}
			<ActionContainer>
				<div>
					<SubmitButton expand="block" onClick={submitClick}>
						{t('Common.button_submit')}
					</SubmitButton>
				</div>

				<div>
					<SecondaryButton expand="block" onClick={backClick}>
						{t('Common.button_cancel')}
					</SecondaryButton>
				</div>
			</ActionContainer>
			{showErrorMessageToast && (
				<MessageToast
					type="FAILURE"
					message={errorMessage}
					buttonText={t('Common.button_close')}
					buttonClick={() => setShowErrorMessageToast(false)}
					shouldShowButton
					sticky
				/>
			)}
		</StyledContainer>
	)
}

const DateOfWeekSelector = ({
	dateList,
	shiftDateIdx,
	setShiftDateIdx,
	disabled
}) => {
	const selectedMonth = moment.months(
		dateList[parseInt(shiftDateIdx)].getMonth()
	)
	return (
		<>
			<StyledMonth>{selectedMonth}</StyledMonth>
			<StyledSegment
				scrollable
				disabled={disabled}
				value={shiftDateIdx}
				onIonChange={e => {
					if (e.detail?.value) {
						setShiftDateIdx(e.detail.value)
					}
				}}
			>
				{dateList.map((date, idx) => (
					<StyledButton key={idx.toString()} value={idx.toString()}>
						{/* week string */}
						{moment.weekdaysShort(date.getDay())}
						<br />
						{/* date string */}
						{date.getDate()}
					</StyledButton>
				))}
			</StyledSegment>
		</>
	)
}

const Shift = () => {
	const { t } = useTranslation()
	const currentDate = new Date()
	const dateList = getDates(currentDate, 7)
	const [showErrorMessageToast, setShowErrorMessageToast] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [registeredList, setRegisteredList] = useState([])
	const [shiftDateIdx, setShiftDateIdx] = useState(TODAY)
	const shiftDate = dateList[parseInt(shiftDateIdx)]
	const [page, setPage] = useState(Page.RegisterList)
	const [shifts, setShifts] = useState([])
	const [preRegistrationShift, setPreRegistrationShift] = useState({
		id: '',
		locationId: '',
		registrationCutoffAt: '',
		shiftStartAt: '',
		shiftEndAt: '',
		districts: [],
		createdAt: '',
		updatedAt: ''
	})

	const { fetchRegisteredList } = useShiftOverViewList()
	const fetchList = async () => {
		const { registeredList: list } = await fetchRegisteredList()
		const { data } = await ShiftRegistrations.getShifts()
		setRegisteredList(list)
		setShifts(data)
	}
	const deleteRegistration = async shiftId => {
		const registrationId = registeredList.find(
			element => element.shift.id === shiftId
		)?.id

		if (!registrationId) {
			return
		}
		try {
			await ShiftRegistrations.deleteShiftRegistration(registrationId)
		} catch (e) {
			setErrorMessage(
				_.get(
					e,
					'response.data.errors.0.detail',
					t('Shift.error_withdraw_fail')
				)
			)
			setShowErrorMessageToast(true)
		}

		await fetchList()
	}
	const postRegistration = async arg => {
		await ShiftRegistrations.postShiftRegistration(arg)
		await fetchList()
		setPage(Page.RegisterList)
	}
	const backToList = async () => {
		await fetchList()
		setPage(Page.RegisterList)
	}
	const makeRegister = shift => {
		setPreRegistrationShift(shift)
		setPage(Page.ShiftRegisterForm)
	}

	useEffect(() => {
		let timeoutId
		if (showErrorMessageToast) {
			timeoutId = setTimeout(() => setShowErrorMessageToast(false), 3000)
		}
		return () => clearTimeout(timeoutId)
	}, [showErrorMessageToast])

	useEffect(() => {
		fetchList()
		const interval = setInterval(async () => {
			fetchList()
		}, 1000 * 30)
		return () => clearInterval(interval)
	}, [])

	return (
		<IonPage id="formpage">
			<div>
				<Header title={t('Shift.title')} hideMenu={false}></Header>
				<StyledSubtitle>
					{t('Shift.heading_shift_sign_page')}
				</StyledSubtitle>
				<DateOfWeekSelector
					dateList={dateList}
					shiftDateIdx={shiftDateIdx}
					setShiftDateIdx={setShiftDateIdx}
					disabled={page === Page.ShiftRegisterForm}
				/>
				{page === Page.RegisterList && (
					<TimeList
						shifts={getShiftsOfSpecifiedDate(shifts, shiftDate)}
						makeRegister={makeRegister}
						registeredList={registeredList}
						applyWithdraw={deleteRegistration}
					/>
				)}
				{page === Page.ShiftRegisterForm && (
					<RegisterForm
						shiftDate={shiftDate}
						shift={preRegistrationShift}
						handleSubmit={postRegistration}
						handleBack={backToList}
					/>
				)}
			</div>
			{showErrorMessageToast && (
				<MessageToast
					type="FAILURE"
					message={errorMessage}
					buttonText={t('Common.button_close')}
					buttonClick={() => setShowErrorMessageToast(false)}
					shouldShowButton
					sticky
				/>
			)}
		</IonPage>
	)
}
export default Shift
