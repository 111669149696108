import React, { useState } from 'react'
import ShiftRegistrations, {
	ShiftRegistrationStatus
} from 'api/shiftRegistrations'
import { Trans, useTranslation } from 'react-i18next'
import { useShiftOverViewList } from 'hooks'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'assets/lodash.min'
import {
	getMonthDateHourSecondString,
	getShiftDataFormRegistration
} from 'utils/shift'
import storage, { SHIFT_REGISTRATION_CUTOFF_AT } from 'utils/storage'
import { StyledIonCard } from './Common'
import { PathButton } from 'components/Button'
import moment from 'moment'

const StyledCard = styled(StyledIonCard)`
	padding: 1rem 0.3rem;
	text-align: center;
	img {
		max-width: 80px;
		margin: auto;
	}
`

const StyledContainer = styled.div`
	display: flex;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`
const StyledContainerChild = styled.div`
	margin-bottom: 10px;
	width: 100%;
`

const DateList = ({ type, list }) => {
	const { t } = useTranslation()
	const status = _.toLower(type)
	return (
		<StyledContainerChild>
			<b>{t(`Shift.status.${status}`)}</b>

			{_.isEmpty(list) ? (
				<p>{t('Shift.text_no_registered_records')}</p>
			) : (
				list.map(date => <p key={Math.random().toString()}>{date}</p>)
			)}
		</StyledContainerChild>
	)
}
const ShiftOverView = () => {
	const { t } = useTranslation()
	const [pendingList, setPendingList] = useState([])
	const [approvedList, setApprovedList] = useState([])
	const [cutoffAt, setCutoffAt] = useState(
		storage.getItem(SHIFT_REGISTRATION_CUTOFF_AT)
	)
	const { fetchRegisteredList } = useShiftOverViewList()

	useEffect(() => {
		const fetchList = async () => {
			const { pendingList, approvedList } = await fetchRegisteredList()
			setPendingList(pendingList)
			setApprovedList(approvedList)
		}
		fetchList()

		const updateCutoffAt = async () => {
			const { data } = await ShiftRegistrations.getShifts()
			const tomorrowShift = data.find(shift => {
				const shiftStartAt = _.get(shift, 'shiftStartAt')
				return (
					moment(shiftStartAt).get('date') ===
					moment().add(1, 'day').get('date')
				)
			})
			if (tomorrowShift) {
				const cutoffAt = moment(
					tomorrowShift.registrationCutoffAt
				).format('HH:mm')
				setCutoffAt(cutoffAt)
				storage.setItem(SHIFT_REGISTRATION_CUTOFF_AT, cutoffAt)
			} else {
				setCutoffAt(null)
				storage.setItem(SHIFT_REGISTRATION_CUTOFF_AT, null)
			}
		}
		updateCutoffAt()

		const interval = setInterval(async () => {
			fetchList()
		}, 1000 * 30)
		return () => clearInterval(interval)
	}, [])

	return (
		<>
			<h6>{t('Shift.heading_shift_overview')}</h6>
			<StyledCard>
				{!_.isEmpty(approvedList) || !_.isEmpty(pendingList) ? (
					<StyledContainer>
						<DateList
							type={ShiftRegistrationStatus.APPROVED}
							list={approvedList
								.map(getShiftDataFormRegistration)
								.map(getMonthDateHourSecondString)}
						/>
						<DateList
							type={ShiftRegistrationStatus.PENDING}
							list={pendingList
								.map(getShiftDataFormRegistration)
								.map(getMonthDateHourSecondString)}
						/>
					</StyledContainer>
				) : (
					<div>
						<img src={`assets/icon/courierbox.png`} alt="icon" />
						<Trans i18nKey="Shift.text_apply">
							Apply to shifts to schedule your work in advance and
							earn more!
						</Trans>
					</div>
				)}
				<NavLink to="/shift">
					<PathButton>{t('Shift.button_sign_in_out')}</PathButton>
				</NavLink>
				{cutoffAt && (
					<div>
						<b>{t('Shift.label_cutoff_time')}: </b>
						{`${cutoffAt} ${t('Datetime.today')}`}
					</div>
				)}
			</StyledCard>
		</>
	)
}

export default ShiftOverView
