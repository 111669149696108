import { checkTokenValidity, safeJSONParse } from 'utils/helpers'
import { SUMMARY_TAB } from 'utils/constants'
import _ from 'assets/lodash.min'
import {
	defaultOffloadedParcelsSorting,
	defaultParcelsSorting
} from 'pages/Summary/components/Header/SortingPopover'
import storage, { CROSSDOCKS } from 'utils/storage'
import * as Sentry from '@sentry/react'

export const SUMMARY_INIT = 'SUMMARY_INIT' // keep for backwards compatibility
export const SUMMARY_UPDATE = 'SUMMARY_UPDATE'
export const SUMMARY_ITEM_ADD = 'SUMMARY_ITEM_ADD'
export const SUMMARY_ITEM_REMOVE = 'SUMMARY_ITEM_REMOVE'
export const SUMMARY_ITEM_UPDATE_STATUS = 'SUMMARY_ITEM_UPDATE_STATUS'
export const SUMMARY_ITEM_UPDATE = 'SUMMARY_ITEM_UPDATE'
export const SUMMARY_GET = 'SUMMARY_GET'
export const SUMMARY_SET_API_END_STATE_PARCELS =
	'SUMMARY_SET_API_END_STATE_PARCELS'
export const SUMMARY_UPDATE_SINGLE_API_END_STATE_PARCEL =
	'SUMMARY_UPDATE_SINGLE_API_END_STATE_PARCEL'
export const SUMMARY_SET_API_PARCELS = 'SUMMARY_SET_API_PARCELS'
export const SUMMARY_UPDATE_API_PARCEL = 'SUMMARY_UPDATE_API_PARCEL'

export const SUMMARY_SET_CROSS_DOCK_PARCELS_METADATA =
	'SUMMARY_SET_CROSS_DOCK_PARCELS_METADATA'
export const SUMMARY_UPDATE_V1_CROSS_DOCK_PARCEL =
	'SUMMARY_UPDATE_V1_CROSS_DOCK_PARCEL'
export const SUMMARY_SET_FILTER = 'SUMMARY_SET_FILTER'
export const SUMMARY_SET_TAB = 'SUMMARY_SET_TAB'
export const SUMMARY_SET_OPENED_PARCEL = 'SUMMARY_SET_OPENED_PARCEL'
export const SUMMARY_SET_CROSSDOCK_PARCELS = 'SUMMARY_SET_CROSSDOCK_PARCELS'
export const SUMMARY_UPDATE_CROSSDOCK_PARCEL = 'SUMMARY_UPDATE_CROSSDOCK_PARCEL'
export const SUMMARY_SET_PENDING_PARCELS = 'SUMMARY_SET_PENDING_PARCELS'
export const SUMMARY_SET_V1_SORTING = 'SUMMARY_SET_V1_SORTING'

/** @typedef {import('api/summaries').DeliveryItem} DeliveryItem */

export const initSummary = (list = []) => ({
	type: SUMMARY_INIT,
	list
})
export const updateSummaryItemStatus = (itemId, newStatus, isUploaded) => ({
	type: SUMMARY_ITEM_UPDATE_STATUS,
	itemId,
	newStatus,
	isUploaded
})
export const updateSummaryItem = (itemId, data) => ({
	type: SUMMARY_ITEM_UPDATE,
	itemId,
	data
})

export const setApiEndStateParcels = parcels => ({
	type: SUMMARY_SET_API_END_STATE_PARCELS,
	parcels
})

export const updateApiEndStateParcel = singleParcel => ({
	type: SUMMARY_UPDATE_SINGLE_API_END_STATE_PARCEL,
	singleParcel
})

export const setApiParcels = parcels => ({
	type: SUMMARY_SET_API_PARCELS,
	parcels
})

export const updateApiParcel = singleParcel => ({
	type: SUMMARY_UPDATE_API_PARCEL,
	singleParcel
})

export const setCrossdockParcelsMetadata = crossdockParcelsMetadata => ({
	type: SUMMARY_SET_CROSS_DOCK_PARCELS_METADATA,
	crossdockParcelsMetadata
})

export const setCrossdockParcels = parcels => ({
	type: SUMMARY_SET_CROSSDOCK_PARCELS,
	parcels
})

export const updateCrossdockParcel = singleParcel => ({
	type: SUMMARY_UPDATE_CROSSDOCK_PARCEL,
	singleParcel
})

export const setSummaryFilter = (filter = {}) => ({
	type: SUMMARY_SET_FILTER,
	filter
})

export const setSummaryTab = (tab = SUMMARY_TAB.IN_THE_VEHICLE) => ({
	type: SUMMARY_SET_TAB,
	tab
})

export const setSummaryOpenedParcel = (openedParcel = null) => ({
	type: SUMMARY_SET_OPENED_PARCEL,
	openedParcel
})

export const setPendingParcels = pendingParcels => ({
	type: SUMMARY_SET_PENDING_PARCELS,
	pendingParcels
})

/**
 *
 * @param {import("pages/Summary/components/Header/SortingPopover.js").SortingCriteriaItem["value"]} sorting
 * @returns
 */
export const setSummarySorting = sorting => ({
	type: SUMMARY_SET_V1_SORTING,
	sorting
})

/**
 * @typedef {{
 * 	data: {
 * 		DOCKID: string,
 * 		BARCODE: string,
 * 		DOCKSTATUS: any
 * 	},
 * 	submittedAt: Date
 * }} CrossdockParcelMetadataData
 */

/**
 * @typedef {{
 * 	filter: {
 * 		deliveryStage: "all" | "FIRST_MILE" | "LAST_MILE",
 * 		text: string,
 * 		scannedBarCode: {value: string}[],
 * 		statusTag: string // delivery status
 * 	},
 * 	tab: 'IN_THE_VEHICLE' | 'OFFLOADED' | 'TO_PICK_UP',
 * 	apiEndStateParcels: DeliveryItem[],
 * 	apiParcels: DeliveryItem[],
 * 	crossdockParcels: DeliveryItem[],
 * 	openedParcel: {
 * 		parcel: DeliveryItem,
 * 		availableActions: import('api/summaries').SummaryItemAction[] | null,
 * 	},
 * 	pendingParcels: string[],
 * 	crossdockParcelsMetadata: {
 * 		list: CrossdockParcelMetadataData[],
 * 		updatedAt: Date
 *	},
 * 	list: object[]
 * }} SummaryState
 */

/** @type {SummaryState} */
const initState = {
	filter: {
		deliveryStage: 'all',
		text: '',
		scannedBarCode: [],
		statusTag: ''
	},
	apiEndStateParcels: [],
	apiParcels: [],
	crossdockParcelsMetadata: {}, // crossdock metadata from form page
	crossdockParcels: [], // keep for mutating by courier app for the purpose of displaying approved crossdock parcel
	pendingParcels: [],
	openedParcel: null,
	tab: SUMMARY_TAB.IN_THE_VEHICLE,
	sorting: defaultParcelsSorting,
	offloadedSorting: defaultOffloadedParcelsSorting,

	// legacy state, to be removed
	list: []
}

export const getSummaryList = state => state.summary.list

export default function reducer(state = initState, action) {
	const {
		type,
		itemId,
		newStatus,
		isUploaded,
		data,
		parcels,
		singleParcel,
		crossdockParcelsMetadata,
		filter,
		tab,
		openedParcel,
		pendingParcels,
		sorting
	} = action

	switch (type) {
		case SUMMARY_INIT: {
			let newState = state

			// for backward compatibility reason, pour old cross dock data into redux state
			// before version 1.70.1
			const legacyCrossdockParcelsMetadata = safeJSONParse(
				storage.getItem(CROSSDOCKS),
				null
			)
			if (legacyCrossdockParcelsMetadata?.list?.length > 0) {
				const listInDescendingOrderSubmittedAt = _.reverse(
					_.sortBy(
						[
							...legacyCrossdockParcelsMetadata.list,
							...(newState.crossdockParcelsMetadata.list || [])
						],
						'submittedAt'
					)
				)
				const newList = _.uniqBy(
					listInDescendingOrderSubmittedAt,
					'data.BARCODE'
				)
				newState = {
					...newState,
					crossdockParcelsMetadata: {
						list: newList,
						updatedAt: new Date().toISOString()
					}
				}

				Sentry.captureMessage(
					'redux::summary_init - pour old cross dock data into redux state',
					{
						level: 'debug',
						extra: {
							legacyCrossdockParcelsMetadata: JSON.stringify(
								legacyCrossdockParcelsMetadata
							),
							crossdockParcelsMetadata: JSON.stringify(
								newState.crossdockParcelsMetadata
							)
						}
					}
				)
			}

			if (!checkTokenValidity()) {
				return {
					...newState,
					apiEndStateParcels: [],
					apiParcels: [],
					crossdockParcels: [],
					pendingParcels: []
				}
			}
			return newState
		}

		case SUMMARY_ITEM_UPDATE_STATUS: {
			const newList = state.list.map(item => {
				if (item.id === itemId) {
					item.status = newStatus
					if (isUploaded) {
						delete item.pending
					} else {
						item.pending = true
					}
				}
				return item
			})

			const pendingParcels = new Set(state.pendingParcels)
			if (isUploaded) {
				pendingParcels.delete(itemId)
			} else {
				pendingParcels.add(itemId)
			}

			return {
				...state,
				pendingParcels: [...pendingParcels],
				list: newList
			}
		}

		case SUMMARY_ITEM_UPDATE: {
			return {
				...state,
				list: state.list.map(item => {
					if (item.id === itemId) {
						item = { ...item, ...data }
					}
					return item
				})
			}
		}

		case SUMMARY_SET_API_PARCELS: {
			return { ...state, apiParcels: parcels }
		}

		case SUMMARY_UPDATE_API_PARCEL: {
			return {
				...state,
				apiParcels: state.apiParcels.map(parcel => {
					if (parcel.id === singleParcel.id) {
						return { ...parcel, ...singleParcel }
					}
					return parcel
				})
			}
		}

		case SUMMARY_SET_API_END_STATE_PARCELS: {
			return { ...state, apiEndStateParcels: parcels }
		}

		case SUMMARY_UPDATE_SINGLE_API_END_STATE_PARCEL: {
			return {
				...state,
				apiEndStateParcels: state.apiEndStateParcels.map(parcel => {
					if (parcel.id === singleParcel.id) {
						return { ...parcel, ...singleParcel }
					}
					return parcel
				})
			}
		}

		case SUMMARY_SET_CROSS_DOCK_PARCELS_METADATA: {
			return { ...state, crossdockParcelsMetadata }
		}

		case SUMMARY_SET_FILTER: {
			return { ...state, filter: { ...state.filter, ...filter } }
		}

		case SUMMARY_SET_TAB: {
			return { ...state, tab }
		}

		case SUMMARY_SET_OPENED_PARCEL: {
			return { ...state, openedParcel }
		}

		case SUMMARY_SET_CROSSDOCK_PARCELS: {
			/**
			 * crossdock meta with single parcel: clientId:parcelRef
			 * crossdock meta with multi parcels: clientId:parcelRef1,parcelRef2,parcelRef3
			 *
			 */
			const existingCrossDockIds = _.flatMap(
				state.crossdockParcelsMetadata.list || [],
				parcel => {
					const [clientId, parcelRefs = ''] =
						parcel.data.BARCODE?.split(':') || []
					return _.compact(
						parcelRefs
							.split(',')
							.map(parcelRef =>
								clientId ? `${clientId}:${parcelRef}` : ''
							)
					)
				}
			)

			// should keep previous cross parcels for offloaded cross dock parcels
			// it wont response from API(delivery user id changed) and in case offloaded cross dock parcels cannot display in detail page
			const current = _.uniqBy(
				[...state.crossdockParcels, ...parcels],
				'deliveryUniqueId'
			).filter(parcel =>
				existingCrossDockIds.includes(`${parcel.clientId}:${parcel.id}`)
			)

			return {
				...state,
				crossdockParcels: current
			}
		}

		case SUMMARY_UPDATE_CROSSDOCK_PARCEL: {
			return {
				...state,
				crossdockParcels: state.crossdockParcels.map(parcel => {
					if (parcel.id === singleParcel.id) {
						return { ...parcel, ...singleParcel }
					}
					return parcel
				})
			}
		}

		case SUMMARY_SET_PENDING_PARCELS: {
			return {
				...state,
				pendingParcels
			}
		}

		case SUMMARY_SET_V1_SORTING: {
			const currentSorting =
				state.tab === SUMMARY_TAB.OFFLOADED
					? { offloadedSorting: sorting }
					: { sorting }

			return { ...state, ...currentSorting }
		}

		default:
			return state
	}
}
