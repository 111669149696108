import { combineReducers } from 'redux'
import config from './config'
import error from './error'
import form from './form'
import message from './message'
import meta from './meta'
import summary from './summary'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const summaryPersistConfig = {
	key: 'summary',
	storage,
	whitelist: [
		'apiEndStateParcels',
		'apiParcels',
		'crossdockParcelsMetadata',
		'crossdockParcels',
		'pendingParcels'
	]
}

export default combineReducers({
	config,
	error,
	form,
	message,
	meta,
	summary: persistReducer(summaryPersistConfig, summary)
})
