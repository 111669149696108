import React from 'react'
import {
	IonHeader,
	IonMenuButton,
	IonTitle,
	IonToolbar,
	IonIcon
} from '@ionic/react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { TertiaryButton } from 'components/Button'
import { dark } from 'styles/colors'
import { useSelector } from 'react-redux'
import { getDriverAppMode } from 'redux/config'
import { arrowBack } from 'ionicons/icons'

// top header bar
const StyledToolbar = styled(IonToolbar)`
	--background: ${({ bgColor }) => bgColor || dark};

	img {
		margin-right: 10px;
		vertical-align: middle;
	}
`

const StyledTitle = styled(IonTitle)`
	padding: ${({ addPadding }) => (addPadding ? '0.5em' : '0')};

	img,
	ion-icon,
	span {
		vertical-align: middle;
	}
	img {
		filter: brightness(0) invert(1);
	}
	ion-icon {
		margin-right: 0.5em;
	}
`

const Container = styled.div`
	display: flex;
	align-items: center;
`

const StyledBackButton = styled(TertiaryButton)`
	--webkit-box-shadow: none;
	--moz-box-shadow: none;
	--box-shadow: none;
	--background-hover: transparent;
	padding: 0px;
	border: none;
	margin: 0;

	ion-icon {
		margin-top: 2px;
	}
`

const Header = ({ title, icon, onClickTitle, hideMenu, bgColor, children }) => {
	const history = useHistory()
	const location = useLocation()
	const isDriverMode = useSelector(getDriverAppMode)
	const showMenu = !isDriverMode && !hideMenu
	const showBackButtonOnPages = [
		'/summary',
		'/expense',
		'/shift',
		'/incorrect-delivery-info'
	]
	const showBackButton =
		isDriverMode && showBackButtonOnPages.includes(location.pathname)

	const goToHomePage = () => {
		history.push('/')
	}

	return (
		<IonHeader>
			<StyledToolbar bgColor={bgColor}>
				<Container>
					{showMenu ? (
						<IonMenuButton
							slot="start"
							color="light"
							className="gtm-btn-menu"
						/>
					) : showBackButton ? (
						<StyledBackButton onClick={goToHomePage}>
							<IonIcon icon={arrowBack} color="light"></IonIcon>
						</StyledBackButton>
					) : null}
					<StyledTitle
						color="light"
						onClick={onClickTitle}
						addPadding={!showMenu}
					>
						<span>{title}</span>
					</StyledTitle>
				</Container>
				{children}
			</StyledToolbar>
		</IonHeader>
	)
}

export default Header
