import React, { useState } from 'react'
import { IonIcon, IonInput, IonItem } from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { noop } from 'utils/helpers'
import Heading from './Heading'

const DEFAULT_VALUE = 0

const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const FieldContainer = styled.div`
	display: flex;
	align-items: center;
`

const InputWrapper = styled(IonItem)`
	flex: 1;
	border: 1px solid silver;
	--min-height: 38px;
	--inner-padding-end: 0.25em;

	/* removes browser's number spin buttons: */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
`

const LeftButton = styled(Button)`
	height: 45px;
	margin: 0;
	--border-radius: 4px 0 0 4px;
`

const RightButton = styled(Button)`
	height: 45px;
	margin: 0;
	--border-radius: 0 4px 4px 0;
`

const Counter = ({
	label,
	isRequired,
	min,
	max,
	step,
	value,
	onChange,
	formTag,
	fieldTag,
	fieldId
}) => {
	const { t } = useTranslation()
	const [intValue, setIntValue] = useState(DEFAULT_VALUE)
	const [prevValue, setPrevValue] = useState(null)

	// getDerivedStateFromProps:

	if (value !== prevValue) {
		let modified = false
		let iValue = parseInt(value, 10)
		if (isNaN(iValue)) {
			iValue = DEFAULT_VALUE
			modified = true
		} else if (iValue < min) {
			iValue = min
			modified = true
		} else if (iValue > max) {
			iValue = max
			modified = true
		}
		setIntValue(iValue)
		if (modified) {
			onChange(iValue)
		}
		setPrevValue(value)
	}

	const handleReset = () => {
		onChange(DEFAULT_VALUE)
	}

	const handleDecrement = e => {
		onChange(intValue - step)
		e.preventDefault()
	}

	const handleIncrement = e => {
		onChange(intValue + step)
		e.preventDefault()
	}

	const handleChange = e => {
		onChange(e.target.value)
	}

	const handleFocus = e => {
		e.target.select()
	}

	return (
		<>
			<HeadingContainer>
				<Heading label={label} isRequired={isRequired} />
				{!!intValue && (
					<Button
						size="small"
						onClick={handleReset}
						className={`gtm-btn-clear-${formTag}.${fieldTag}`}
					>
						{t('Common.button_reset')}
					</Button>
				)}
			</HeadingContainer>
			<FieldContainer>
				<LeftButton
					disabled={intValue <= min}
					onClick={handleDecrement}
					className={`gtm-btn-counter-decrease-${formTag}.${fieldTag}`}
				>
					<IonIcon icon={remove} />
				</LeftButton>
				<InputWrapper lines="none">
					<IonInput
						type="number"
						inputmode="numeric"
						min={min}
						max={max}
						step={step}
						value={intValue}
						onIonChange={handleChange}
						onFocus={handleFocus}
						id={fieldId}
					/>
				</InputWrapper>
				<RightButton
					disabled={intValue >= max}
					onClick={handleIncrement}
					className={`gtm-btn-counter-increase-${formTag}.${fieldTag}`}
				>
					<IonIcon icon={add} />
				</RightButton>
			</FieldContainer>
		</>
	)
}

Counter.defaultProps = {
	label: '',
	isRequired: false,
	min: Number.MIN_SAFE_INTEGER,
	max: Number.MAX_SAFE_INTEGER,
	step: 1,
	value: DEFAULT_VALUE,
	onChange: noop,
	formTag: '',
	fieldTag: '',
	fieldId: ''
}

export default Counter
