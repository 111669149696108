import { useSelector } from 'react-redux'
import { FILTERS, crossDockParcelFilter } from 'utils/helpers'
import { PARCEL_STATUS, SUMMARY_TAB } from 'utils/constants'
import { useState, useEffect } from 'react'
import { getCancelledParcelTab, sortSummaryParcels } from 'utils/summary'
import _ from 'assets/lodash.min'

/** @typedef {import("api/summaries").DeliveryItem} DeliveryItem */
/** @typedef {import("redux/summary").SummaryState} SummaryState */

export const useSummaryParcels = () => {
	/** @type {DeliveryItem[]} */
	const apiEndStateParcels = useSelector(
		state => state.summary.apiEndStateParcels
	)
	/** @type {DeliveryItem[]} */
	const apiParcels = useSelector(state => state.summary.apiParcels)
	/** @type {SummaryState["tab"]} */
	const summaryTab = useSelector(state => state.summary.tab)
	/** @type {SummaryState["filter"]["deliveryStage"]} */
	const deliveryStage = useSelector(
		state => state.summary.filter.deliveryStage
	)
	/** @type {SummaryState["filter"]["text"]} */
	const text = useSelector(state => state.summary.filter.text)
	/** @type {SummaryState["filter"]["scannedBarCode"]} */
	const scannedBarCode = useSelector(
		state => state.summary.filter.scannedBarCode
	)
	const summarySorting = useSelector(state => {
		return state.summary.tab === SUMMARY_TAB.OFFLOADED
			? state.summary.offloadedSorting
			: state.summary.sorting
	})

	/** @type {SummaryState["filter"]["statusTag"]} */
	const statusTag = useSelector(state => state.summary.filter.statusTag)
	/** @type {[(DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[], React.SetStateAction<(DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[]>]} */
	const [parcels, setParcels] = useState([])

	useEffect(() => {
		let current =
			scannedBarCode.length > 0 || text
				? [...apiParcels, ...apiEndStateParcels] // if user is searching parcel by text or barcode, all parcels shd be included
				: summaryTab === SUMMARY_TAB.OFFLOADED
				? apiEndStateParcels
				: apiParcels

		// filter by deliveryStage
		current =
			deliveryStage !== 'all'
				? current.filter(
						parcel => parcel?.deliveryStage === deliveryStage
				  )
				: current

		if (scannedBarCode.length > 0) {
			// filter by barcode
			current = current.filter(
				parcel =>
					scannedBarCode.some(
						barCode => barCode.value === parcel.id
					) ||
					(parcel.orderId &&
						scannedBarCode.some(
							barCode => barCode.value === parcel.orderId
						))
			)
		} else if (text) {
			// filter by parcel ref
			current = current.filter(
				parcel =>
					parcel.id.includes(text) ||
					(parcel.orderId && parcel.orderId.includes(text))
			)
		} else if (statusTag) {
			// filter by status tag
			current = current.filter(parcel => {
				if (statusTag === PARCEL_STATUS.CANCELED) {
					return (
						getCancelledParcelTab(parcel) === summaryTab &&
						statusTag === parcel.status
					)
				}
				return statusTag === parcel.status
			})
		} else {
			// filter by tab
			current = current.filter(parcel => {
				if (parcel.status === PARCEL_STATUS.CANCELED) {
					return getCancelledParcelTab(parcel) === summaryTab
				}
				return FILTERS[summaryTab].includes(parcel.status)
			})
		}
		current = sortSummaryParcels(current, summarySorting.value)
		setParcels(current)
	}, [
		summaryTab,
		statusTag,
		text,
		scannedBarCode,
		deliveryStage,
		apiEndStateParcels,
		apiParcels,
		summarySorting
	])

	return parcels
}

export const useSummaryCrossDockParcels = () => {
	/** @type {import("redux/summary").CrossdockParcelMetadataData[]} */
	const crossdockParcelsMetadataList = useSelector(
		state => state.summary.crossdockParcelsMetadata.list
	)
	/** @type {DeliveryItem[]} */
	const crossdockParcels = useSelector(
		state => state.summary.crossdockParcels
	)
	/** @type {SummaryState["filter"]["text"]} */
	const text = useSelector(state => state.summary.filter.text)
	/** @type {SummaryState["filter"]["deliveryStage"]} */
	const deliveryStage = useSelector(
		state => state.summary.filter.deliveryStage
	)
	/** @type {SummaryState["filter"]["scannedBarCode"]} */
	const scannedBarCode = useSelector(
		state => state.summary.filter.scannedBarCode
	)
	/** @type {SummaryState["filter"]["statusTag"]} */
	const statusTag = useSelector(state => state.summary.filter.statusTag)
	/** @type {SummaryState["tab"]} */
	const summaryTab = useSelector(state => state.summary.tab)
	/** @type {[(DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[], Dispatch<SetStateAction<(DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[]>>]} */
	const [parcels, setParcels] = useState([])

	const summarySorting = useSelector(state => {
		return state.summary.tab === SUMMARY_TAB.OFFLOADED
			? state.summary.offloadedSorting
			: state.summary.sorting
	})

	useEffect(() => {
		/** @type {{ [key: string]: DeliveryItem[] }} */
		const crossDockParcelsMap = _.groupBy(
			crossdockParcels,
			parcel => `${parcel.clientId}:${parcel.id}`
		)

		/** @type {(DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[]} */
		let current = (crossdockParcelsMetadataList || []).map(
			crossdockMetadata => {
				/**
				 * crossdock meta with single parcel: clientId:parcelRef
				 * crossdock meta with multi parcels: clientId:parcelRef1,parcelRef2,parcelRef3
				 */
				const [clientId, parcelRefs = ''] =
					crossdockMetadata.data?.BARCODE?.split(':') || []

				/** @type {Record<string, DeliveryItem>} */
				const apiParcelsMap = {}
				const parcelRefsList = _.compact(parcelRefs.split(','))

				parcelRefsList.forEach(parcelRef => {
					const current = `${clientId}:${parcelRef}`
					apiParcelsMap[current] = crossDockParcelsMap[current]?.[0]
				})

				return {
					...crossdockMetadata,
					...(crossDockParcelsMap[
						`${clientId}:${parcelRefsList[0]}`
					]?.[0] || {}),
					apiParcelsMap,
					id: crossdockMetadata.data?.BARCODE,
					orderId: crossdockMetadata.data?.BARCODE
				}
			}
		)

		current =
			deliveryStage !== 'all'
				? current.filter(
						parcel => parcel?.deliveryStage === deliveryStage
				  )
				: current

		if (scannedBarCode.length > 0) {
			// filter by barcode
			/**
			 * @param {DeliveryItem & SummaryState["crossdockParcelsMetadata"] & {apiParcelsMap: Record<string, DeliveryItem>}} parcel
			 * @returns {boolean}
			 */
			const filterer = parcel => {
				const parcelIds = _.compact(
					Object.keys(parcel.apiParcelsMap).map(
						id => id.split(':')[1]
					)
				)
				return scannedBarCode.some(barcode =>
					parcelIds.includes(barcode.value)
				)
			}
			current = current.filter(filterer)
		} else if (text) {
			// filter by parcel ref
			current = current.filter(parcel => parcel.id.includes(text))
		} else if (statusTag) {
			// filter by status tag
			current = statusTag === PARCEL_STATUS.CROSSDOCKED ? current : []
		}

		// filter by tab and special handling for approved status
		current = current.filter(parcel =>
			crossDockParcelFilter(parcel, summaryTab)
		)

		current = sortSummaryParcels(current, summarySorting.value)
		setParcels(current)
	}, [
		text,
		summaryTab,
		deliveryStage,
		scannedBarCode,
		statusTag,
		crossdockParcelsMetadataList,
		crossdockParcels,
		summarySorting
	])

	return parcels
}
