// Action types:
export const FETCH_ERROR = 'FETCH_ERROR'
export const OTHER_ERROR = 'OTHER_ERROR'
export const DISMISS_ERROR = 'DISMISS_ERROR'

// Action creators:
export const fetchError = error => ({
	type: FETCH_ERROR,
	error
})

export const otherError = error => ({
	type: OTHER_ERROR,
	error
})

export const dismissError = name => ({
	type: DISMISS_ERROR,
	name
})

// Initial state:
const initState = {}

// Selector:
export const makeErrorSelector = actionTypes => state =>
	actionTypes
		.filter(name => Boolean(state.error[name]))
		.map(name => ({ name, ...state.error[name] }))
		.reduce((foundErr, errObj) => foundErr || errObj, undefined)

// Reducer:
export default function errorReducer(state = initState, action) {
	const { type, error, name } = action

	if (type === DISMISS_ERROR) {
		// remove error from store:
		const { [name]: _, ...updatedState } = state
		return updatedState
	}

	// handle all `_ERROR` actions:
	const matches = /(.*)_ERROR/.exec(type)
	if (!matches) {
		return state
	}

	let blocking = false
	let message = error.message
	let requestId = ''
	if (error.response) {
		// has response, get error response headers & data (request-id, code):
		const { data, headers } = error.response
		blocking = false
		message =
			data && data.errors
				? `${data.errors[0].code} ${data.errors[0].detail}`
				: error.message
		requestId = headers['x-request-id']
	} else if (error.request) {
		// no response, assume network error -> non-blocking
		blocking = false
	} else {
		// other unknown error? -> blocking
		blocking = true
	}

	// save error to store with action name as key:
	const [, actionName] = matches
	return {
		...state,
		[actionName]: { blocking, message, requestId, errorName: actionName }
	}
}
