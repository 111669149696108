import { getUserId, getValueByPath } from './helpers'
import { CROSSDOCK_STATUS, PARCEL_STATUS, SUMMARY_TAB } from './constants'
import storage from './storage'
import _ from 'assets/lodash.min'
import { getCourierV1Summaries } from 'api/summaries'
import i18n from './i18n'
import moment from 'moment'
import store from 'redux/store'
import { updateCrossdockParcel } from 'redux/summary'

/** @typedef {import("api/summaries").DeliveryItem} DeliveryItem */
/** @typedef {import('redux/summary').CrossdockParcelMetadataData} CrossdockParcelMetadataData */

/** @type {function(DeliveryEntity): string} */
function getOrderRemarkString(delivery = {}) {
	const { notes, extras, parcelNum, totalWeight, clientId } = delivery
	const notesData = notes
		? notes.flatMap(item =>
				_.isObject(item)
					? Object.entries(item).map(
							([key, val]) =>
								`${key}: ${_.castArray(val).join(', ')}`
					  )
					: [item]
		  )
		: []

	const remarkInfo = {
		notes: notesData,
		extras: {
			...extras,
			numberOfParcels: parcelNum,
			weight: totalWeight
		}
	}
	const clientList = JSON.parse(storage.getItem('clients'))
	const client = clientList.find(i => i.id === clientId)
	const remarksTpl = getValueByPath(
		client,
		'attributes.orderRemarksTemplate',
		'{ notes }',
		[]
	)
	return i18n.t(remarksTpl, remarkInfo)
}

/** @type {function({isEndState: boolean}): import("api/summaries").CourierV1SummariesResponse} */
export const fetchSummaryParcels = async ({ isEndState = false }) => {
	const { data: deliveries } = await getCourierV1Summaries({
		userId: getUserId(),
		minUpdatedAt: isEndState
			? moment().startOf('day').utcOffset(0, true).toISOString()
			: moment().subtract(14, 'days').toISOString(),
		limit: 250,
		page: 1,
		isEndState
	})

	const parcels = []
	const crossDockParcels = []
	for (const delivery of deliveries) {
		const deliveryData = {
			...delivery,
			changed: [],
			remarks: getOrderRemarkString(delivery)
		}
		if (CROSSDOCK_STATUS.includes(deliveryData.status)) {
			crossDockParcels.push(deliveryData)
		} else if (_.keys(PARCEL_STATUS).includes(deliveryData.status)) {
			parcels.push(deliveryData)
		}
	}

	return { parcels, crossDockParcels }
}

const summaryAPIThrottleThreshold = 3000

/** @type {function(): import("api/summaries").CourierV1SummariesResponse} */
export const fetchEndStateSummaryParcels = _.throttle(
	() => fetchSummaryParcels({ isEndState: true }),
	summaryAPIThrottleThreshold
)

/** @type {function(): import("api/summaries").CourierV1SummariesResponse} */
export const fetchNonEndStateSummaryParcels = _.throttle(
	() => fetchSummaryParcels({ isEndState: false }),
	summaryAPIThrottleThreshold
)

/** @type {function(ParcelsGroupedByParcelRef): void} */
export const updateV1ParcelsFromAggregatedParcels = parcelsMap => {
	for (const [id, parcel] of _.toPairs(parcelsMap)) {
		const current = {
			id,
			...parcel
		}
		if (CROSSDOCK_STATUS.includes(current.status)) {
			store.dispatch(updateCrossdockParcel(current))
		}
	}
}

/**
 *
 * @param {(DeliveryItem & CrossdockParcelMetadataData)[]} parcels
 * @param {import("pages/Summary/components/Header/SortingPopover.js").SortingCriteriaItem["value"]} sortingConfig
 * @returns {(DeliveryItem & CrossdockParcelMetadataData)[]}
 */
export const sortSummaryParcels = (parcels, sortingConfig) => {
	const { sorting, direction } = sortingConfig

	const sortBy =
		sorting === 'SLA'
			? 'deliveryBy'
			: sorting === 'parcelRef'
			? 'id'
			: 'updatedAt'

	const sortedParcels = _.sortBy(parcels, sortBy) // sorted in ascending
	return direction === 'DESC' ? _.reverse(sortedParcels) : sortedParcels
}

/**
 *
 * @param {DeliveryItem} parcel
 * @returns {keyof typeof import("./constants").SUMMARY_TAB}
 */
export const getCancelledParcelTab = parcel => {
	const isPickedUp = parcel.logs.some(log =>
		[PARCEL_STATUS.IN_PROGRESS, PARCEL_STATUS.RETURNED].includes(log.status)
	)
	return isPickedUp ? SUMMARY_TAB.IN_THE_VEHICLE : SUMMARY_TAB.TO_PICK_UP
}
