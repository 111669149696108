import React from 'react'
import { useSelector } from 'react-redux'
import SummarySection from './SummarySection'
import styled from 'styled-components'
import { IonCard, IonList } from '@ionic/react'
import { llmPrimary, silver, white } from 'styles/colors'
import { DEFAULT_LOCALE } from 'utils/helpers'
import { useSummaryQuickActions } from 'pages/Summary/hooks/config'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setSummaryOpenedParcel } from 'redux/summary'
import { FIELD_TYPE } from 'components/Form/constants'
import * as Sentry from '@sentry/react'

const ListCard = styled(IonCard)`
	padding: 0.5em 0;
	margin: 0 1em;
	border: 1px solid ${({ active }) => (active ? llmPrimary : silver)};
	border-radius: 0;
	background-color: ${white};
	ion-card-header {
		padding-top: 0.5em;
		border-bottom: 1px solid
			${({ active }) => (active ? llmPrimary : silver)};
	}
	ion-card-title {
		font-size: 16px;
		text-transform: uppercase;
		span:last-child {
			float: right;
			font-size: 14px;
			font-weight: bold;
			ion-icon {
				vertical-align: middle;
				font-size: 20px;
				color: ${({ active }) => (active ? llmPrimary : silver)};
			}
		}
	}
	ion-card-content {
		padding: 0 1em;
	}
`

/**
 * @typedef {{
 * 	parcels: (import("api/summaries").DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[],
 * 	displayFormats: import('pages/Summary/hooks/config').SummaryDisplayFormats,
 * 	clientNames: SummaryClientNames
 * }} CrossDockParcelListProps
 */

/**@type {React.NamedExoticComponent<CrossDockParcelListProps>} */
export const CrossDockParcelList = React.memo(
	function SummaryCrossDockParcelList({
		parcels,
		displayFormats,
		clientNames
	}) {
		const { i18n } = useTranslation()
		const dispatch = useDispatch()
		/** @type {import('redux/summary').SummaryState["filter"]["text"]} */
		const searchText = useSelector(state => state.summary.filter.text)
		const summaryQuickActions = useSummaryQuickActions()

		/**
		 *
		 * @param {import("api/summaries").DeliveryItem & import('redux/summary').CrossdockParcelMetadataData} parcel
		 * @param apiParcel
		 * @param currentParcelId
		 */
		const onItemClick = (apiParcel, currentParcelId) => {
			const parcel = apiParcel.apiParcelsMap[currentParcelId]
			if (!parcel) {
				Sentry.captureMessage(
					'CrossDockParcelList:onItemClick - no cross dock parcel details found',
					{
						level: 'debug',
						extra: {
							apiParcel: JSON.stringify(apiParcel),
							currentParcelId
						}
					}
				)
				return
			}
			const quickActions = summaryQuickActions[parcel.clientId] || []
			let actions = quickActions.filter(i =>
				i.availableFor.includes(parcel.status)
			)

			const currentParcels = [parcel.id]
			const locale = i18n.language
			actions = actions
				.map(i => {
					const items = i.summaryItemActions.map(action => {
						action.input.fields.forEach(f => {
							if (f.tag === FIELD_TYPE.BARCODE) {
								f.values = currentParcels
							}
						})
						const translation =
							action.translations.find(
								i => i.locale === locale
							) ||
							action.translations.find(
								i => i.locale === DEFAULT_LOCALE
							)
						action.translation = translation
						action.clientId = parcel.clientId
						return action
					})
					return items
				})
				.flat()

			dispatch(
				setSummaryOpenedParcel({
					parcel: {
						id: parcel.id || parcel.data?.BARCODE.split(':')[1],
						...parcel
					},
					availableActions: actions
				})
			)
		}
		return (
			<IonList>
				{parcels.map(parcel => (
					<ListCard
						key={parcel.data.BARCODE}
						style={{ marginBottom: '1em' }}
					>
						<SummarySection
							data={parcel.data}
							searchString={searchText}
							submittedAt={parcel.submittedAt}
							onItemClick={parcelId =>
								onItemClick(parcel, parcelId)
							}
							displayFormats={displayFormats}
							clientNames={clientNames}
						/>
					</ListCard>
				))}
			</IonList>
		)
	}
)
