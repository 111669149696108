import { IonCard, IonList } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSummaryOpenedParcel } from 'redux/summary'
import styled from 'styled-components'
import { llmPrimary, llmWarning, silver, white } from 'styles/colors'
import ParcelItem from './ParcelItem'
import { DEFAULT_LOCALE, setLocation } from 'utils/helpers'
import storage, { LOCATION, SUBMIT_ATTEMPTS } from 'utils/storage'
import { FIELD_TYPE } from 'components/Form/constants'
import { submit } from 'containers/ConnectedForm'
import { useSummaryGroupedByBarcodeParcels } from 'pages/Summary/hooks/aggregate'
import _ from 'assets/lodash.min'
import { useSummaryQuickActions } from 'pages/Summary/hooks/config'

const ListCard = styled(IonCard)`
	padding: 0.5em 0;
	margin: 0 1em;
	border: 1px solid ${({ active }) => (active ? llmPrimary : silver)};
	border-radius: 0;
	background-color: ${white};
	ion-card-header {
		padding-top: 0.5em;
		border-bottom: 1px solid
			${({ active }) => (active ? llmPrimary : silver)};
	}
	ion-card-title {
		font-size: 16px;
		text-transform: uppercase;
		span:last-child {
			float: right;
			font-size: 14px;
			font-weight: bold;
			ion-icon {
				vertical-align: middle;
				font-size: 20px;
				color: ${({ active }) => (active ? llmPrimary : silver)};
			}
		}
	}
	ion-card-content {
		padding: 0 1em;
	}
`
const ParcelRecordsContainer = styled(ListCard)`
	padding: 0;
	background-color: rgb(248, 249, 252);
	border: 0;
	box-shadow: none;
	padding-bottom: 5px;
`

const ParcelReorderGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Legend = styled.div`
	margin: 1em;
	color: ${llmWarning};
	display: flex;
	align-items: center;
	gap: 4px;
`

const OfflineIcon = styled.img`
	&&& {
		width: 20px;
	}
`

/** @type {React.NamedExoticComponent<{ parcels: (import('api/summaries').DeliveryItem & import('redux/summary').CrossdockParcelMetadataData)[] }>} */
export const ParcelList = React.memo(function SummaryParcelList({ parcels }) {
	const { t, i18n } = useTranslation()
	const meta = useSelector(state => state.meta)
	const dispatch = useDispatch()
	const [hasRetryQueue, setHasRetryQueue] = useState(false)
	const [showUploadPending, setShowUploadPending] = useState(false)
	/** @type {import('redux/summary').SummaryState["pendingParcels"]} */
	const pendingParcels = useSelector(state => state.summary.pendingParcels)
	const groupedByBarcodeParcels = useSummaryGroupedByBarcodeParcels()
	const summaryQuickActions = useSummaryQuickActions()

	useEffect(() => {
		setHasRetryQueue(!!storage.getItem(SUBMIT_ATTEMPTS))
	}, [])

	useEffect(() => {
		setShowUploadPending(
			_.intersection(
				pendingParcels,
				parcels.map(parcel => parcel.id)
			).length > 0
		)
	}, [pendingParcels, parcels])

	const manualRetry = async (e, parcel) => {
		e.stopPropagation()
		await setLocation(meta)
		const data = {
			clientId: parcel.clientId,
			fields: [{ tag: FIELD_TYPE.BARCODE, values: [parcel.id] }],
			formId: `${
				parcel.form && parcel.form.startsWith('PICKUP')
					? parcel.clientId
					: 'COMMON'
			}_${parcel.form}`,
			locationId: storage.getItem(LOCATION),
			meta: { ...meta, submittedVia: 'MANUAL_RETRY' },
			submittedAt: new Date().toISOString()
		}
		await submit(data, { allParcels: groupedByBarcodeParcels })
	}

	/** @param {import('api/summaries').DeliveryItem & import('redux/summary').CrossdockParcelMetadataData} parcel */
	const onItemClick = parcel => {
		let actions = (summaryQuickActions[parcel.clientId] || []).filter(
			(
				i // TODO: SummaryQuickActionState is not array
			) => i.availableFor.includes(parcel.status)
		)

		const currentParcels = [parcel.id]
		const locale = i18n.language
		actions = actions
			.map(i => {
				const items = i.summaryItemActions.map(action => {
					action.input.fields.forEach(f => {
						if (f.tag === FIELD_TYPE.BARCODE) {
							f.values = currentParcels
						}
					})
					const translation =
						action.translations.find(i => i.locale === locale) ||
						action.translations.find(
							i => i.locale === DEFAULT_LOCALE
						)
					action.translation = translation
					action.clientId = parcel.clientId
					return action
				})
				return items
			})
			.flat()

		dispatch(
			setSummaryOpenedParcel({
				parcel: {
					id: parcel.id || parcel.data?.BARCODE.split(':')[1],
					...parcel
				},
				availableActions: actions
			})
		)
	}

	return (
		<IonList>
			{parcels.length > 0 && (
				<ParcelRecordsContainer>
					<ParcelReorderGroup>
						{parcels.map(parcel => {
							const isPending = pendingParcels.includes(parcel.id)
							return (
								<ParcelItem
									key={parcel.deliveryUniqueId + parcel.id}
									parcel={parcel}
									handleEvents={{
										onClick: e => {
											e.stopPropagation()
											onItemClick(parcel)
										},
										onRetryUpload: manualRetry
									}}
									showPendingUpload={
										isPending &&
										(hasRetryQueue ||
											process.env
												.REACT_APP_ENABLE_MANUAL_RETRY !==
												'true')
									}
									showRetryUpload={
										process.env
											.REACT_APP_ENABLE_MANUAL_RETRY ===
											'true' &&
										isPending &&
										!hasRetryQueue
									}
								/>
							)
						})}
					</ParcelReorderGroup>
				</ParcelRecordsContainer>
			)}
			{showUploadPending && (
				<Legend>
					<OfflineIcon src="assets/icon/offline.png" />
					{`${t('Form.message_upload_pending_short')}`}
				</Legend>
			)}
		</IonList>
	)
})
