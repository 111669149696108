import { PARCEL_STATUS } from 'utils/constants'
import storage, { LOCATIONS, LOCATION } from 'utils/storage'
import _ from 'assets/lodash.min'
import styled from 'styled-components'
import React from 'react'
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonIcon
} from '@ionic/react'
import {
	dark,
	llmPrimary,
	llmSuccess,
	medium,
	silver,
	white
} from 'styles/colors'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
	call,
	checkmarkCircle,
	person,
	pin,
	radioButtonOff,
	radioButtonOn,
	time,
	paper,
	navigate
} from 'ionicons/icons'
import moment from 'moment'

const labelWrapperStyle = {
	marginRight: '0.25em',
	fontWeight: 'bold'
}

const changedLabelStyle = { color: llmSuccess }

const StopCardContainer = styled.div`
	position: relative;
	margin-left: 1.5em;
	padding-left: 0.5em;
	padding-bottom: 1em;
	border-left-color: ${({ disabled }) => (disabled ? medium : llmPrimary)};
	border-left-style: solid;
	border-left-width: ${({ index, length }) =>
		index === 0 && length > 1 ? '2px' : '0'};
`

const ListCard = styled(IonCard)`
	padding: 0.5em 0;
	margin: 0 1em;
	border: 1px solid ${({ active }) => (active ? llmPrimary : silver)};
	border-radius: 0;
	background-color: ${white};
	ion-card-header {
		padding-top: 0.5em;
		border-bottom: 1px solid
			${({ active }) => (active ? llmPrimary : silver)};
	}
	ion-card-title {
		font-size: 16px;
		text-transform: uppercase;
		span:last-child {
			float: right;
			font-size: 14px;
			font-weight: bold;
			ion-icon {
				vertical-align: middle;
				font-size: 20px;
				color: ${({ active }) => (active ? llmPrimary : silver)};
			}
		}
	}
	ion-card-content {
		padding: 0 1em;
	}
`

const TimelineIconBg = styled.span`
	position: absolute;
	top: -0.25em;
	left: -0.75em;
	width: 20px;
	height: 30px;
	border-radius: 50%;
	background-color: #f8f9fc;
`

const TimelineIcon = styled(IonIcon)`
	position: absolute;
	top: -0.1em;
	left: ${({ index }) => (index === 0 ? '-0.5' : '-0.4')}em;
	width: 28px;
	height: 28px;
	color: ${({ disabled }) => (disabled ? medium : llmPrimary)};
`

const DeliveryInfoContainer = styled.div`
	flex-grow: 1;
`

const DeliveryInfo = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5em 0;
	color: ${dark};
	ion-icon {
		min-width: 32px;
		margin-right: 0.5em;
		color: ${({ active }) => (active ? llmPrimary : silver)};
	}
	a {
		color: ${dark};
	}
`

const NavigationButton = styled.a`
	display: flex;
	width: 32px;
	height: 32px;
	ion-icon {
		font-size: 20px;
		transform: rotate(45deg);
		margin: auto;
	}
`

const DeliveryInfoLabel = ({ label, changedText }) => (
	<span style={labelWrapperStyle}>
		<span>{label}</span>
		{changedText && (
			<span style={changedLabelStyle}> {`(${changedText})`}</span>
		)}
		:
	</span>
)

const { AWAITS_PICKUP, CANCELED, DELIVERED, APPROVED } = PARCEL_STATUS

/** @type {React.NamedExoticComponent<{ parcel: (import('api/summaries').DeliveryItem & import('redux/summary').CrossdockParcelMetadataData) }>} */
export const ParcelAddress = React.memo(function ParcelAddress({ parcel }) {
	const { t } = useTranslation()

	const addresses = parcel.address ? [...parcel.address] : []

	while (addresses.length < 2) {
		addresses.push({})
	}

	const canceled = parcel.status === CANCELED
	const shouldMarkAsChanged = key =>
		!canceled && parcel.changed && parcel.changed.includes(key) // TODO: what is changed
	const active = i =>
		!canceled &&
		((i === 0 && parcel.status === AWAITS_PICKUP) ||
			(i === 1 &&
				parcel.status !== AWAITS_PICKUP &&
				![DELIVERED, APPROVED].includes(parcel.status)))
	const done = i =>
		(i === 0 && ![AWAITS_PICKUP, CANCELED].includes(parcel.status)) ||
		(i === 1 && [DELIVERED, APPROVED].includes(parcel.status))

	const locationInfoArray = JSON.parse(storage.getItem(LOCATIONS)) || []
	const locationInfo = locationInfoArray.filter(
		location => location.id === storage.getItem(LOCATION)
	)
	const phoneFormats =
		(locationInfo[0] && locationInfo[0]?.phoneFormats?.templateIntl) || ''
	return addresses.map((addr, i) => {
		const navigationFromAddress = encodeURIComponent(
			_.get(addresses, '0.text')
		)
		const navigationToAddress = encodeURIComponent(
			_.get(addresses, '1.text')
		)
		// PUL: current location to PUL, DOL: PUL to DOL
		let navigationUrl
		if (!_.isEmpty(addr.text)) {
			navigationUrl =
				i === 0
					? `https://www.google.com/maps/dir/?api=1&destination=${navigationFromAddress}&travelmode=driving`
					: `https://www.google.com/maps/dir/?api=1&origin=${navigationFromAddress}&destination=${navigationToAddress}&travelmode=driving`
		}
		const phoneNumber =
			addr.contacts && addr.contacts.length
				? phoneFormats && !addr.contacts[0]?.phone?.startsWith('+')
					? phoneFormats.replace(
							'{{base}}',
							addr.contacts[0].phone || ''
					  )
					: addr.contacts[0].phone || ''
				: null

		return (
			<StopCardContainer
				key={i}
				index={i}
				length={addresses.length}
				disabled={canceled}
			>
				<ListCard active={active(i)}>
					<IonCardHeader>
						<IonCardTitle>
							<span>
								{t(`${i === 0 ? 'PICKUP' : 'DROP_OFF'}.title`)}
							</span>
							<span>
								<IonIcon icon={time} size="medium"></IonIcon>
								{i === 0 && parcel.pickupAt && (
									<span style={{ lineHeight: '20px' }}>
										{shouldMarkAsChanged('pickupAt') && (
											<span
												style={{
													...labelWrapperStyle,
													...changedLabelStyle
												}}
											>
												{t('Summary.label_changed')}:
											</span>
										)}
										<span>
											{moment(parcel.pickupAt).format(
												t('Datetime.time_display')
											)}
										</span>
									</span>
								)}
								{i === 1 && parcel.deliveryBy && (
									<span style={{ lineHeight: '20px' }}>
										{shouldMarkAsChanged('deliveryBy') && (
											<span
												style={{
													...labelWrapperStyle,
													...changedLabelStyle
												}}
											>
												{t('Summary.label_changed')}:
											</span>
										)}
										<span>
											{moment(parcel.deliveryBy).format(
												t('Datetime.time_display')
											)}
										</span>
									</span>
								)}
							</span>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<DeliveryInfo active={active(i)}>
							<IonIcon icon={pin} size="large"></IonIcon>
							<DeliveryInfoContainer>
								<DeliveryInfoLabel
									label={t(
										`PORTAL.Place Order.table_header_${
											i === 0 ? 'pickup' : 'dropoff'
										}_address`
									)}
									changedText={
										shouldMarkAsChanged(`address${i}`)
											? t('Summary.label_changed')
											: ''
									}
								/>
								<span>{addr.text}</span>
							</DeliveryInfoContainer>
							{navigationUrl && (
								<NavigationButton
									href={navigationUrl}
									target="_blank"
									rel="noreferrer"
								>
									<IonIcon
										icon={navigate}
										size="medium"
									></IonIcon>
								</NavigationButton>
							)}
						</DeliveryInfo>
						<DeliveryInfo active={active(i)}>
							<IonIcon icon={person} size="large"></IonIcon>
							<div>
								<DeliveryInfoLabel
									label={t('Summary.label_contact_name')}
									changedText={
										shouldMarkAsChanged(`name${i}`)
											? t('Summary.label_changed')
											: ''
									}
								/>
								{addr.contacts && addr.contacts.length && (
									<span>{addr.contacts[0]?.name || ''}</span>
								)}
							</div>
						</DeliveryInfo>
						<DeliveryInfo active={active(i)}>
							<IonIcon icon={call} size="large"></IonIcon>
							<div>
								<DeliveryInfoLabel
									label={t('Login.label_phone')}
									changedText={
										shouldMarkAsChanged(`phone${i}`)
											? t('Summary.label_changed')
											: ''
									}
								/>
								{addr.contacts && addr.contacts.length && (
									<span>
										<a href={`tel:${phoneNumber}`}>
											{phoneNumber}
										</a>
									</span>
								)}
							</div>
						</DeliveryInfo>
						<DeliveryInfo active={active(i)}>
							<IonIcon icon={paper} size="large"></IonIcon>
							<div>
								<DeliveryInfoLabel
									label={t('Summary.label_remarks')}
									changedText={
										shouldMarkAsChanged(`remarks`)
											? t('Summary.label_changed')
											: ''
									}
								/>
								<Trans>{parcel.remarks}</Trans>{' '}
								{/* TODO: remarks */}
								{/* { parcel.remarks && <span>{parcel.remarks}</span> } */}
							</div>
						</DeliveryInfo>
					</IonCardContent>
				</ListCard>
				<TimelineIconBg />
				<TimelineIcon
					icon={
						done(i)
							? checkmarkCircle
							: active(i)
							? radioButtonOn
							: radioButtonOff
					}
					size="large"
					index={i}
					disabled={canceled}
				></TimelineIcon>
			</StopCardContainer>
		)
	})
})
