import React, { useEffect, useRef } from 'react'
import { IonPage, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { StyledContent } from 'components/Common'
import { trySubmitQueue } from 'containers/ConnectedForm'
import { EmptyState } from './components/EmptyState'
import { useLocation, useHistory } from 'react-router'

import {
	setCrossdockParcels,
	setCrossdockParcelsMetadata,
	setApiEndStateParcels,
	setSummaryFilter,
	setSummaryTab,
	setApiParcels
} from 'redux/summary'
import { SUMMARY_TAB } from 'utils/constants'
import {
	getNonOutdatedCrossdockParcelsMetadata,
	validateUser
} from 'utils/helpers'
import storage, { LAST_PAGE } from 'utils/storage'
import { Header } from './components/Header'
import { ParcelDetail } from './components/ParcelDetail'
import { CrossDockParcelList } from './components/CrossDockParcelList'
import { ParcelList } from './components/ParcelList'
import {
	fetchEndStateSummaryParcels,
	fetchNonEndStateSummaryParcels
} from 'utils/summary'
import { useSummaryCrossDockParcels, useSummaryParcels } from './hooks/filter'
import { useSummaryCrossDockEffect } from './hooks/effect'
import { useSummaryAttributes } from './hooks/config'
import { useSummaryGroupedByBarcodeParcels } from './hooks/aggregate'
import styled from 'styled-components'

const SummaryPageContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

/** @type {React.NamedExoticComponent<{ courierMenuTranslation: any }>} */
export const Summary = function Summary({ courierMenuTranslation }) {
	/** @type {import('redux/summary').SummaryState["openedParcel"]} */
	const openedParcel = useSelector(state => state.summary.openedParcel)
	/** @type {import('redux/summary').SummaryState["crossdockParcelsMetadata"]} */
	const crossdockParcelsMetadata = useSelector(
		state => state.summary.crossdockParcelsMetadata
	)
	const dispatch = useDispatch()
	const parcels = useSummaryParcels()
	const crossDockParcels = useSummaryCrossDockParcels()
	const { displayFormats, clientNames } = useSummaryAttributes()
	const groupedByBarcodeParcels = useSummaryGroupedByBarcodeParcels()
	const timer = useRef(null)
	const location = useLocation()
	const history = useHistory()

	useSummaryCrossDockEffect()
	useIonViewWillEnter(() => {
		const newCrossdockParcelsMetadata =
			getNonOutdatedCrossdockParcelsMetadata(crossdockParcelsMetadata)
		if (newCrossdockParcelsMetadata) {
			dispatch(setCrossdockParcelsMetadata(newCrossdockParcelsMetadata))
		}
		storage.setItem(LAST_PAGE, window.location.pathname)
		validateUser()
	})
	useIonViewDidEnter(() => {
		dispatch(
			setSummaryFilter({ text: '', statusTag: '', scannedBarCode: [] })
		)
		// reset tabs/filters/search to default:
		trySubmitQueue(groupedByBarcodeParcels)
	})

	useEffect(() => {
		if (location.pathname === '/summary') {
			if (location.state?.tab) {
				dispatch(setSummaryTab(location.state.tab))
				history.replace('/summary', {})
			} else {
				dispatch(setSummaryTab(SUMMARY_TAB.IN_THE_VEHICLE))
			}
		}
	}, [location.pathname])

	useEffect(() => {
		const refreshAllSummaryParcels = async () => {
			const [
				{ parcels: apiEndStateParcels },
				{ parcels: apiParcels, crossDockParcels }
			] = await Promise.all([
				fetchEndStateSummaryParcels(),
				fetchNonEndStateSummaryParcels()
			])
			dispatch(setApiParcels(apiParcels))
			dispatch(setApiEndStateParcels(apiEndStateParcels))
			dispatch(setCrossdockParcels(crossDockParcels))
		}

		if (location.pathname === '/summary') {
			clearInterval(timer.current)
			timer.current = setInterval(() => {
				refreshAllSummaryParcels()
			}, 30000) // refresh summary parcels every 30 seconds
		} else {
			clearInterval(timer.current)
		}
		return () => clearInterval(timer.current)
	}, [dispatch, location.pathname])

	return (
		<IonPage id="formpage">
			<StyledContent>
				<SummaryPageContent>
					<Header translation={courierMenuTranslation} />

					{parcels.length > 0 && <ParcelList parcels={parcels} />}

					{crossDockParcels.length > 0 && (
						<CrossDockParcelList
							parcels={crossDockParcels}
							displayFormats={displayFormats.current}
							clientNames={clientNames}
						/>
					)}

					{parcels.length === 0 && crossDockParcels.length === 0 && (
						<EmptyState />
					)}
				</SummaryPageContent>
			</StyledContent>

			{openedParcel && <ParcelDetail parcel={openedParcel} />}
		</IonPage>
	)
}

export default React.memo(Summary)
