import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import XHRBackend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const { REACT_APP_VERSION } = process.env

i18n.use(initReactI18next)
	.use(ChainedBackend)
	.on('languageChanged', async () => {
		// do stuff when language changed..
	})
	.init({
		preload: ['en-HK'],
		fallbackLng: 'en-HK',
		backend: {
			backends: [LocalStorageBackend, XHRBackend],
			backendOptions: [
				{
					expirationTime: 24 * 60 * 60 * 1000, // 1 day,
					defaultVersion: REACT_APP_VERSION
				},
				{
					loadPath: '/locales/{lng}.json',
					queryStringParams: {
						version: REACT_APP_VERSION
					}
				}
			]
		},
		react: {
			wait: true,
			useSuspense: false
		},
		interpolation: {
			prefix: '{',
			suffix: '}'
		},
		returnEmptyString: false,
		nsSeparator: false
	})

export default i18n
