import _ from '../assets/lodash.min'
import axiosApiClient from './axiosApiClient'
// const { REACT_APP_NAME, REACT_APP_VERSION } = process.env

/**
 * @typedef Shift
 * @property {string} id
 * @property {string} locationId
 * @property {string} registrationCutoffAt
 * @property {string} shiftStartAt
 * @property {string} shiftEndAt
 * @property {string[]} districts
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @typedef ShiftRegistration
 * @property {string} id
 * @property {string} shiftId
 * @property {number} userId
 * @property {string[]} districts
 * @property {ShiftRegistrationStatus} status
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {Shift} shift
 */

export const ShiftRegistrationStatus = {
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED'
}

/**
 * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status]
 * @returns { Promise<{ data: ShiftRegistration[] }>}
 */
const getShiftRegistrations = async status => {
	return _.get(
		await axiosApiClient.get('/courier/v1/shift-registrations', { status }),
		'data',
		{}
	)
}

/**
 * @returns {Promise<{ data: Shift[] }>}
 */
const getShifts = async () => {
	return _.get(await axiosApiClient.get('/courier/v1/shifts'), 'data', {})
}

/**
 * @param {string} shiftRegistrationId
 * @returns {Promise<void>}
 */
const deleteShiftRegistration = shiftRegistrationId => {
	return axiosApiClient.delete(
		`/courier/v1/shift-registrations/${shiftRegistrationId}`
	)
}

/**
 *
 * @param {{shiftId:string,districts:string[]}} param0
 * @returns {Promise<ShiftRegistration>}
 */
const postShiftRegistration = async ({ shiftId, districts }) => {
	return _.get(
		await axiosApiClient.post('/courier/v1/shift-registrations', {
			shiftId,
			districts
		}),
		'data',
		{}
	)
}

const ShiftRegistrations = {
	getShiftRegistrations,
	getShifts,
	deleteShiftRegistration,
	postShiftRegistration
}

export default ShiftRegistrations
