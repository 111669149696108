import _ from 'assets/lodash.min'
import { hot } from 'react-hot-loader/root'
import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	IonApp,
	IonModal,
	IonRouterOutlet,
	IonSpinner,
	setupConfig
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { createGlobalStyle } from 'styled-components'
import Button from 'components/Button'
import Menu from 'components/Menu'
import VersionDisplay from 'components/VersionDisplay'
import FormPage from 'pages/FormPage'
import Home from 'pages/Home'
import Login from 'pages/Login'
import NotFound from 'pages/NotFound'
import Summary from 'pages/Summary'
import Shift from 'pages/Shift'
import Verification from 'pages/Verification'
import Settings from 'pages/Settings'
import { dark, llmInfo, llmPrimary, white } from 'styles/colors'
import {
	checkTokenValidity,
	fetchClientsOfCurrentLocation,
	fetchFormsOfCurrentLocation,
	initLocale,
	setLocation,
	validateUser,
	getMenuConfig,
	getRoles,
	logout,
	getIsDriverMode,
	changeLocale,
	getLocations,
	getUserId
} from 'utils/helpers'
import storage, {
	LAST_PAGE,
	ZOOM_SCALE,
	DRIVER_ROLES,
	DRIVER_USER_ID,
	LOCATION,
	PHONE
} from 'utils/storage'
import { DriverAppWebviewAccessLayer } from 'components/DriverAppWebviewAccessLayer'
import { getRegions } from 'utils/region'
import { featureFlags } from 'config/index'
import { GlobalHooksRegistrationLayer } from 'components/GlobalHooksRegistrationLayer'
import { getDriverURIFragment } from 'hooks/useDriverURIFragment'
import { CITY_ID, getDriverModeLocale } from 'utils/region'
import { setDriverAppToken } from 'redux/config'
import axiosApiClient from 'api/axiosApiClient'
import * as Sentry from '@sentry/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import { initSummary } from 'redux/summary'

// Global style to override Ionic's Components:
const GlobalStyle = createGlobalStyle`
	body {
		zoom: ${({ zoomScale }) => zoomScale || 1};
	}
	/* Dropdown Popover */
	.select-dropdown-popover .popover-content {
		width: calc(100% - 34px);
		ion-label {
			white-space: normal;
		}
	}
	/* Alert (Dialog) */
	.default-dialog {
		button.alert-button {
			color: ${dark};
		}
	}
	.custom-dialog {
		button.alert-button.no-button {
			color: ${llmPrimary};
		}
		button.alert-button.yes-button, button.alert-button.ok-button {
			color: ${white};
			background-color: ${llmPrimary}
		}
	}
	/* Modal */
	.custom-modal .modal-wrapper {
		height: 100%;
		border-radius: 4px;
		/* from ionic alert md style: */
		box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
	}
	/* Enable user text selection */
	* {
			-webkit-user-select: text;
			-moz-user-select: text;
			-ms-user-select: text;
			user-select: text;
	}
`

const BlockingModal = styled(IonModal)`
	.modal-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: ${dark};
	}
`
const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	margin: 1em;

	input {
		width: 100%;
		outline: none;
		border: none;
		background: none;
		text-align: center;
		color: ${llmInfo};
	}
`

const FETCH_INTERVAL = 10 // minutes

const { REACT_APP_ENV } = process.env

setupConfig({
	rippleEffect: false,
	mode: 'md' // enforce Material Design on Ionic components
	// animated: false, // disable all ionic animations?
})

let fetchTimer

const App = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [clients, setClients] = useState([]) // client list
	const [forms, setForms] = useState({})
	const [menuConfig, setMenuConfig] = useState({
		defaultGroupId: null,
		items: new Map()
	})
	const [loading, setLoading] = useState(true)
	const [verifyBlock, setVerifyBlock] = useState(false)
	const [clientCommonForms, setClientCommonForms] = useState({})
	const [showReimbursement, setShowReimbursement] = useState(false)
	const showVersionDisplay = !(
		featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE &&
		process.env.REACT_APP_ENV === 'prd'
	)

	const handleVisibilityChange = () => {
		if (document.visibilityState === 'visible') {
			if (!window.location.pathname.startsWith('/login')) {
				validateUser()
			}
		}
	}

	const getUserInfo = async () => {
		const res = await axiosApiClient.get('/users/self')
		localStorage.setItem(DRIVER_USER_ID, res.data.id)
		localStorage.setItem(DRIVER_ROLES, JSON.stringify(res.data.roles))
	}

	useEffect(() => {
		const fetchClients = async () => {
			let data = await fetchClientsOfCurrentLocation()
			if (!getRoles().includes('ADM')) {
				data = data.filter(item => item.id !== 'DEVTEST')
			}
			setClients(data)
			return data
		}

		const fetchMenuConfig = async () => {
			const config = await getMenuConfig()

			const mappedConfig = {
				items: new Map(),
				defaultGroupId: config.defaultItemId
			}

			const locationId = storage.getItem('location')

			for (const item of config.items.filter(
				i =>
					i.locations.indexOf(locationId) !== -1 &&
					i.disabled === false
			)) {
				mappedConfig.items.set(item.id, {
					id: item.id,
					translations: item.translations,
					tags: item.formTags,
					defaultTag: item.formDefaultTag,
					// specific for HOME, the path is root '/'
					path:
						item.id === 'HOME'
							? '/'
							: `/${item.id.replace(/_/g, '').toLowerCase()}`,
					iconPath: item.icon || `assets/icon/empty.png`,
					clientSelect: item.formClientSelect
				})
			}

			return mappedConfig
		}

		const fetchForms = async () => {
			// this fetches all forms of current location and cache them:
			const data = await fetchFormsOfCurrentLocation()
			const config = await fetchMenuConfig()

			setClientCommonForms(data['COMMON'])
			setMenuConfig(config)
			// - Show this card only when reimbursement feature is activated for the market
			setShowReimbursement(
				Boolean(
					[...config.items.keys()].find(
						key => key.toLowerCase() === 'expense'
					)
				)
			)
			const commonForms = {}
			// populate common forms:
			for (const [key, group] of config.items) {
				commonForms[key] = []
				group.tags.forEach(tag => {
					if (data['COMMON']) {
						const form = data['COMMON'].find(
							item => item.tag === tag
						)
						if (form) {
							if (form.attributes.groupDefault) {
								group.defaultTag = tag
							}
							if (!form.clientId) {
								form.clientId = 'COMMON'
							}
							commonForms[key].push(form)
						}
					}
				})
			}
			setForms(commonForms)
		}

		const fetchData = async () => {
			await getRegions()
			await fetchClients()
			await fetchForms()
			setLoading(false)

			if (!fetchTimer) {
				fetchTimer = setInterval(() => {
					fetchForms()
				}, FETCH_INTERVAL * 60000)
			}
		}

		const initialize = async () => {
			// driver mode setup, make sure the token, location, userId and userRole is ready
			const [token, cityId, hlang] = getDriverURIFragment()
			if (token && cityId) {
				const locationsData = await getLocations()
				const cityCodes = _.invert(CITY_ID)
				const locationId = cityCodes[cityId]
				const currentLocation =
					locationsData.find(
						location => location.id === locationId
					) || locationsData[0]

				dispatch(
					setDriverAppToken({
						token,
						locationId
					})
				)
				storage.setItem(LOCATION, locationId)

				changeLocale(
					getDriverModeLocale(
						_.map(currentLocation.locales, 'id'),
						hlang
					)
				)
				await getUserInfo()
			}

			dispatch(initSummary())
			// if user is loggedin and verified, we can init data for him/her;
			// if not, we will redirect to login page
			if (checkTokenValidity()) {
				Sentry.setUser({
					id: getUserId(),
					roles: getRoles(),
					// phone only available in PWA login
					phone: storage.getItem(PHONE),
					// for search in Sentry
					email: storage.getItem(PHONE)
				})

				initLocale()
				let verifyBlock = true
				if (getIsDriverMode()) {
					verifyBlock = false
				} else {
					const roles = getRoles()
					for (const r of roles) {
						if (['COR', 'ADM', 'OPS'].includes(r)) {
							verifyBlock = false
						}
					}
					setVerifyBlock(verifyBlock)
				}
				if (!verifyBlock) {
					fetchData()
				}
			} else {
				const { pathname } = window.location
				if (pathname !== '/' && !pathname.startsWith('/login')) {
					storage.setItem(LAST_PAGE, window.location.pathname)
				}
				setLoading(false)
			}
			// trigger location permission:
			setLocation()
		}
		initialize()

		document.addEventListener(
			'visibilitychange',
			handleVisibilityChange,
			false
		)

		if (REACT_APP_ENV !== 'prd') {
			// just for us to quickly identify env from title on browser tabs:
			document.title = `${REACT_APP_ENV.toUpperCase()} Courier`
		}

		// executes on componentWillUnmount:
		return () => {
			clearInterval(fetchTimer)
			fetchTimer = undefined
			document.removeEventListener(
				'visibilitychange',
				handleVisibilityChange
			)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	let defaultPath = ''
	if (!checkTokenValidity()) {
		defaultPath = '/login'
	} else if (
		menuConfig.defaultGroupId &&
		menuConfig.items.has(menuConfig.defaultGroupId)
	) {
		defaultPath = menuConfig.items.get(menuConfig.defaultGroupId).path
	} else if (menuConfig.items.size > 0) {
		// if can't find the default group in the list just pick the first
		defaultPath = menuConfig.items.values().next().value.path
	}

	const defaultTo = {
		pathname: defaultPath,
		state: { referrer: window.location.pathname }
	}

	const createFormRoute = formGroup => {
		const group = menuConfig.items.get(formGroup)

		if (formGroup === 'HOME') {
			return (
				<Route
					key="home"
					path="/"
					render={() => (
						<Home
							translations={group.translations}
							icon={group.iconPath}
							showReimbursement={
								showReimbursement &&
								featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE
							}
						/>
					)}
					exact
				/>
			)
		}

		if (formGroup === 'SUMMARY') {
			return (
				<Route
					key="summary"
					path="/summary"
					render={() => (
						<Summary courierMenuTranslation={group.translations} />
					)}
					exact
				/>
			)
		}
		if (formGroup === 'SHIFT') {
			return (
				<Route
					key="shift"
					path="/shift"
					render={() => <Shift />}
					exact
				/>
			)
		}

		let defaultIndex =
			featureFlags.REACT_APP_ENABLE_LLP_18517_NON_BARCODE_DELIVERY
				? formGroup === 'CROSSDOCK'
					? group.tags.indexOf(group.defaultTag)
					: 0
				: group.tags.indexOf(group.defaultTag)

		// set first tab active if only 1 form available
		if (forms[formGroup] && forms[formGroup].length === 1) {
			defaultIndex = 0
		}
		return (
			<Route
				key={formGroup}
				path={group.path}
				render={() => (
					<FormPage
						tags={group.tags}
						formGroup={formGroup}
						defaultForms={forms[formGroup]}
						defaultIndex={defaultIndex}
						defaultPath={defaultPath}
						clients={clients}
						clientSelect={group.clientSelect}
						translations={group.translations}
						icon={group.iconPath}
						clientCommonForms={clientCommonForms}
					/>
				)}
				exact
			/>
		)
	}

	const loginRoute = (
		<Route key="login" path="/login" component={Login} exact />
	)
	const verificationRoute = (
		<Route
			key="verification"
			path="/login/verification"
			component={Verification}
			exact
		/>
	)

	const allRoutes = [
		// routes to all pages
		loginRoute,
		verificationRoute
	]

	if (menuConfig.items.size > 0) {
		menuConfig.items.forEach((_, k) => {
			if (forms[k]) {
				allRoutes.push(createFormRoute(k))
			}
		})
	}

	allRoutes.push(
		<Route
			key="settings"
			path="/settings"
			render={() => <Settings />}
			exact
		/>
	)

	return (
		<IonApp>
			{showVersionDisplay && <VersionDisplay />}
			<BlockingModal backdropDismiss={false} isOpen={verifyBlock}>
				<Container>
					<h3>{t('App.message_unverified')}</h3>
					<Button
						onClick={logout}
						color="primary"
						className="gtm-btn-logout"
					>
						{t('Common.button_logout')}
					</Button>
				</Container>
			</BlockingModal>
			{loading ? (
				<IonSpinner style={{ margin: 'auto' }} />
			) : (
				<IonReactRouter>
					<GlobalHooksRegistrationLayer />
					{featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
						<DriverAppWebviewAccessLayer />
					)}
					<Menu formGroups={menuConfig} />
					<IonRouterOutlet animated={false}>
						{allRoutes.map(route => route)}
						<Redirect from="" to={defaultTo} exact />
						<Route component={NotFound} />
					</IonRouterOutlet>
				</IonReactRouter>
			)}
			<GlobalStyle zoomScale={storage.getItem(ZOOM_SCALE)} />
		</IonApp>
	)
}

export default hot(App)
