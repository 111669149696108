import React from 'react'
import {
	IonItem,
	IonLabel,
	IonRadioGroup,
	IonSelect,
	IonSelectOption
} from '@ionic/react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import RadioButton from 'components/RadioButton'
import { noop } from 'utils/helpers'
import Heading from './Heading'

// section heading container, contains section title and clear button
const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

// radio button container
const RadioContainer = styled(IonItem)`
	--padding-start: 0;
`

// radio button label
const RadioLabel = styled(IonLabel)`
	padding-left: 0.5em;

	&& {
		white-space: normal;
	}
`

const SelectWrapper = styled.div`
	border: 1px solid silver;
`

// main component, radio group section of dropoff form
const Radio = ({
	options,
	selectedTag,
	onSelect,
	label,
	isRequired,
	formTag,
	fieldTag,
	fieldId,
	dropdown
}) => {
	const { t } = useTranslation()

	// clear selection
	const handleClear = () => {
		onSelect('')
	}

	// on radio button click
	const handleClick = e => {
		onSelect(e.target.value)
	}
	// for the dropdown variant
	const handleChange = e => {
		onSelect(e.detail.value)
	}

	return (
		<>
			<HeadingContainer>
				<Heading label={label} isRequired={isRequired} />
				{selectedTag && (
					<Button
						size="small"
						onClick={handleClear}
						className={`gtm-btn-clear-${formTag}.${fieldTag}`}
					>
						{t('Common.button_clear')}
					</Button>
				)}
			</HeadingContainer>
			{dropdown ? ( // renders dropdown radio
				<SelectWrapper>
					<IonSelect
						interface="popover"
						interfaceOptions={{
							cssClass: 'select-dropdown-popover',
							animated: false
						}}
						placeholder={t('Form.label_enter_item', {
							item: label
						})}
						value={selectedTag}
						onIonChange={handleChange}
						id={fieldId}
					>
						{options.map(option => (
							<IonSelectOption
								key={option.tag}
								value={option.tag}
								className={`gtm-radio-${formTag}.${fieldTag}`}
							>
								{option.label}
							</IonSelectOption>
						))}
					</IonSelect>
				</SelectWrapper>
			) : (
				// renders regular inline radio
				<IonRadioGroup onClick={handleClick} id={fieldId}>
					{options.map(option => (
						<RadioContainer lines="none" key={option.tag}>
							<RadioButton
								value={option.tag}
								checked={option.tag === selectedTag}
								id={`${fieldId}-${option.tag}`}
								className={`gtm-radio-${formTag}.${fieldTag}`}
							/>
							<RadioLabel>{option.label}</RadioLabel>
						</RadioContainer>
					))}
				</IonRadioGroup>
			)}
		</>
	)
}

Radio.defaultProps = {
	options: [], // array of options
	selectedTag: '', // selected option tag
	onSelect: noop,
	label: '',
	isRequired: false,
	formTag: '',
	fieldTag: '',
	fieldId: '',
	dropdown: false // renders dropdown if true, otherwise regular inline radio
}

export default Radio
