import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { IonCardContent } from '@ionic/react'
import { StyledIonCard } from './Common'
import { PathButton } from 'components/Button'

const StyledCard = styled(StyledIonCard)`
	padding: 11px 5px;
	text-align: center;
`

const StyledContainer = styled.div`
	margin: 4px 0;
`

export const ReimbursementCard = () => {
	const { t } = useTranslation()
	return (
		<StyledCard>
			<IonCardContent>
				<StyledContainer>
					{t('EXPENSE.reimbursement_card_description')}
				</StyledContainer>
				<StyledContainer>
					<NavLink to="/expense">
						<PathButton>
							{t('EXPENSE.request_button_title')}
						</PathButton>
					</NavLink>
				</StyledContainer>
			</IonCardContent>
		</StyledCard>
	)
}
