import React, { useEffect, useState } from 'react'
import { useHistory, withRouter, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	IonContent,
	IonModal,
	IonPage,
	IonSpinner,
	useIonViewWillEnter
} from '@ionic/react'
import styled from 'styled-components'
import Button from 'components/Button'
import ButtonGrid from 'components/ButtonGrid'
import ConnectedForm from 'containers/ConnectedForm'
import {
	getNonOutdatedCrossdockParcelsMetadata,
	getFormModelsByClientId,
	validateUser
} from 'utils/helpers'
import storage, { LAST_PAGE } from 'utils/storage'
import { setCrossdockParcelsMetadata } from 'redux/summary'
import { setLastSelectedClientId } from 'redux/config'

const ClientSelectionModal = styled(IonModal)`
	.ion-page {
		padding-top: var(--ion-safe-area-top, 0);
	}
`

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	margin: 1em;
`

const Title = styled.h1`
	font-size: 18px;
`

const FormPage = ({
	clients,
	clientSelect,
	tags,
	formGroup,
	defaultForms,
	defaultIndex,
	defaultPath,
	translations,
	icon,
	clientCommonForms
}) => {
	const defaultClientId = clients.length === 1 ? clients[0].id : null
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [forms, setForms] = useState(defaultForms)
	const [displayIndex, setDisplayIndex] = useState(defaultIndex)
	const [clientId, setClientId] = useState(defaultClientId)
	const crossDocks = useSelector(
		state => state.summary.crossdockParcelsMetadata
	)

	useIonViewWillEnter(() => {
		const nonOutdatedCrossdockParcelsMetadata =
			getNonOutdatedCrossdockParcelsMetadata(crossDocks)
		if (nonOutdatedCrossdockParcelsMetadata) {
			dispatch(
				setCrossdockParcelsMetadata(nonOutdatedCrossdockParcelsMetadata)
			)
		}
		storage.setItem(LAST_PAGE, window.location.pathname)
		validateUser()
	})
	useEffect(() => {
		// reset clientId when leave the page
		return () => {
			setClientId(null)
		}
	}, [])

	// this useEffect will execute on enter & leave
	useEffect(() => {
		const tabIndex = tags.indexOf(location.state?.formTag)
		if (formGroup !== 'CROSSDOCK') {
			setDisplayIndex(tabIndex === -1 ? 0 : tabIndex)
		}
		// coming from summary page
		if (location.state?.clientId) {
			selectClient(location.state.clientId)
		} else {
			// set the client id to default ON LEAVE so next time when user navigate the same route, it will show the select client popup
			setClientId(defaultClientId)
		}
		// If the user only has one client
		if (defaultClientId !== null) {
			selectClient(defaultClientId)
		}
	}, [location.state])

	const handleIndexChange = index => {
		setDisplayIndex(index)
	}

	const selectClient = async id => {
		dispatch(setLastSelectedClientId(id))
		setLoading(true)
		setClientId(id)
		// get forms by client ID from storage:
		const data = getFormModelsByClientId(id)
		const formsInTheGroup = []
		tags.forEach(tag => {
			const form = data.find(d => d.tag === tag)
			const commonForm = clientCommonForms.find(d => d.tag === tag)
			if (form) {
				formsInTheGroup.push(form)
			} else if (commonForm) {
				formsInTheGroup.push(commonForm)
			}
		})
		if (formsInTheGroup.length) {
			// if there are forms available for this clientId, use them
			setForms(formsInTheGroup)
		} else {
			// otherwise, fallback to defaultForms (COMMON) if any
			setForms(defaultForms)
		}
		if (formsInTheGroup.length === 1) {
			setDisplayIndex(0)
		}
		setLoading(false)
	}

	const renderContent = () => {
		if (loading) {
			return <IonSpinner style={{ margin: 'auto' }} />
		} else if (forms.length) {
			return (
				<ConnectedForm
					translations={translations}
					icon={icon}
					clients={clients}
					clientId={clientId}
					formGroup={formGroup}
					models={forms}
					displayIndex={Number(displayIndex)}
					onIndexChange={handleIndexChange}
				/>
			)
		} else {
			return (
				<Container>
					<img
						src="assets/icon/noresult.svg"
						width="200"
						height="200"
						alt=""
					/>
					{/* TODO: Confirm texts/translations */}
					<Title>{`No ${t(`${formGroup}.title`)} form for ${
						clientId || 'COMMON'
					}`}</Title>
					<Button
						routerLink={defaultPath}
						className="gtm-btn-return-home"
					>
						Return Home
					</Button>
				</Container>
			)
		}
	}

	const formGroupPathMap = {
		PICKUP: '/pickup',
		DROP_OFF: '/dropoff',
		'INCORRECT-DELIVERY-INFO': '/incorrect-delivery-info'
	}

	return (
		<IonPage id="formpage">
			{!location.state?.clientId && (
				<ClientSelectionModal
					className="client-selection-modal"
					isOpen={
						clientSelect &&
						!clientId &&
						formGroupPathMap[formGroup] ===
							history.location.pathname
					}
					backdropDismiss={false}
					animated={false}
				>
					<IonContent>
						<p style={{ textAlign: 'center' }}>
							{t('Form.label_select_client')}:
						</p>
						<ButtonGrid list={clients} onClick={selectClient} />
					</IonContent>
				</ClientSelectionModal>
			)}
			{renderContent()}
		</IonPage>
	)
}

FormPage.defaultProps = {
	defaultForms: [],
	defaultIndex: 0,
	defaultPath: '/',
	clientSelect: true
}

export default withRouter(FormPage)
