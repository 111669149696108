import React from 'react'
import {
	IonItem,
	IonLabel,
	IonList,
	IonPopover,
	IonRadio,
	IonRadioGroup
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setSummarySorting } from 'redux/summary'
import { SUMMARY_TAB } from 'utils/constants'

const Container = styled(IonPopover)`
	--width: calc(100% - 24px);
`

const RadioContainer = styled(IonItem)`
	--min-height: 30px;
`

const RadioItem = styled(IonRadio)`
	margin: 0 10px 0 0;
`

/**
 * @typedef {{
 * key: 'SLA_ASC' | 'SLA_DESC' | 'PARCEL_REF_ASC' | 'PARCEL_REF_DESC' | 'UPDATED_TIME_ASC' | 'UPDATED_TIME_DESC',
 * value: { sorting: 'SLA' | 'parcelRef' | 'updatedTime', direction: 'ASC' | 'DESC' }
 * }} SortingCriteriaItem
 */

/** @type {SortingCriteriaItem[]} */
export const sortingCriteria = [
	{ key: 'SLA_ASC', value: { sorting: 'SLA', direction: 'ASC' } },
	{ key: 'SLA_DESC', value: { sorting: 'SLA', direction: 'DESC' } },
	{
		key: 'PARCEL_REF_ASC',
		value: { sorting: 'parcelRef', direction: 'ASC' }
	},
	{
		key: 'PARCEL_REF_DESC',
		value: { sorting: 'parcelRef', direction: 'DESC' }
	},
	{
		key: 'UPDATED_TIME_DESC',
		value: { sorting: 'updatedTime', direction: 'DESC' }
	},
	{
		key: 'UPDATED_TIME_ASC',
		value: { sorting: 'updatedTime', direction: 'ASC' }
	}
]
export const defaultOffloadedParcelsSorting = sortingCriteria[4]
export const defaultParcelsSorting = sortingCriteria[0]

export const SortingPopover = React.memo(function SortingPopover({ onClose }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const summarySorting = useSelector(state => {
		return state.summary.tab === SUMMARY_TAB.OFFLOADED
			? state.summary.offloadedSorting
			: state.summary.sorting
	})
	const onSelect = ({ detail }) => dispatch(setSummarySorting(detail.value))

	return (
		<Container isOpen onDidDismiss={onClose}>
			<IonList>
				<IonRadioGroup value={summarySorting}>
					{sortingCriteria.map(sorting => (
						<RadioContainer lines="none" key={sorting.key}>
							<RadioItem
								slot="start"
								value={sorting}
								onIonSelect={onSelect}
							/>
							<IonLabel>
								{t(`Summary.sorting.${sorting.key}`)}
							</IonLabel>
						</RadioContainer>
					))}
				</IonRadioGroup>
			</IonList>
		</Container>
	)
})
