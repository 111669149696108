import React from 'react'
import { IonItem, IonLabel, IonList, IonCheckbox } from '@ionic/react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { dark } from 'styles/colors'
import { noop } from 'utils/helpers'
import Heading from './Heading'

// section heading container, contains section title and clear button
const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

// checkbox container
const CheckboxContainer = styled(IonItem)`
	--padding-start: 0;
`

// checkbox label
const CheckboxLabel = styled(IonLabel)`
	padding-left: 0.5em;

	&& {
		white-space: normal;
	}
`

// styled checkbox based on form's bgColor color passed via theme, fallback to dark
const StyledCheckbox = styled(IonCheckbox)`
	--border-color: ${({ theme: { bgColor } }) => bgColor || dark};
	--border-color-checked: ${({ theme: { bgColor } }) => bgColor || dark};
	--background-checked: ${({ theme: { bgColor } }) => bgColor || dark};
`

// main component, checkbox list
const Checkbox = ({
	options,
	selectedTag,
	selectedTags,
	onSelect,
	onAdd,
	onRemove,
	onClear,
	label,
	isArray,
	isRequired,
	formTag,
	fieldTag,
	fieldId
}) => {
	const { t } = useTranslation()

	// clear selection
	const handleClear = () => {
		if (isArray) {
			onClear()
		} else {
			onSelect('')
		}
	}

	// on checkbox click
	const handleClick = e => {
		const { value } = e.target
		if (isArray) {
			if (selectedTags.includes(value)) {
				onRemove(value)
			} else {
				onAdd(value)
			}
		} else {
			onSelect(value)
		}
	}

	const hasValue = !!(isArray ? selectedTags.length : selectedTag)

	return (
		<>
			<HeadingContainer>
				<Heading label={label} isRequired={isRequired} />
				{hasValue && (
					<Button size="small" onClick={handleClear}>
						{t('Common.button_clear')}
					</Button>
				)}
			</HeadingContainer>
			<IonList id={fieldId}>
				{options.map(option => (
					<CheckboxContainer lines="none" key={option.tag}>
						<StyledCheckbox
							value={option.tag}
							checked={
								isArray
									? selectedTags.includes(option.tag)
									: option.tag === selectedTag
							}
							onClick={handleClick}
							className={`gtm-checkbox-${formTag}.${fieldTag}`}
						/>
						<CheckboxLabel>{option.label}</CheckboxLabel>
					</CheckboxContainer>
				))}
			</IonList>
		</>
	)
}

Checkbox.defaultProps = {
	options: [], // array of options
	selectedTag: '',
	selectedTags: [], // selected option tags
	onSelect: noop,
	onAdd: noop,
	onRemove: noop,
	onClear: noop,
	label: '',
	isArray: false,
	isRequired: false,
	formTag: '',
	fieldTag: '',
	fieldId: ''
}

export default Checkbox
