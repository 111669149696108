import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IonPage, IonSpinner, useIonViewWillEnter } from '@ionic/react'
import Header from 'components/Header'
import ErrorDisplay from 'components/ErrorDisplay'

// import Dialog from 'components/Form/Dialog'
import SummaryCard from 'components/SummaryCard'
import {
	fetchError,
	otherError,
	dismissError,
	makeErrorSelector
} from 'redux/error'
import {
	validateUser,
	getNonOutdatedCrossdockParcelsMetadata,
	getTranslationValue,
	noop
} from 'utils/helpers'
import storage, { LAST_PAGE } from 'utils/storage'
import { StyledContent } from 'components/Common'
import ShiftOverView from 'components/ShiftOverview'
import { ReimbursementCard } from 'components/ReimbursementCard'
import { IncorrectDeliveryInfoCard } from 'components/IncorrectDeliveryInfoCard'
import { CrossdockCard } from 'components/CrossdockCard'
import { setCrossdockParcelsMetadata } from 'redux/summary'

// let shiftTimer // for auto-refresh interval

const Home = ({
	translations,
	icon,
	error,
	dismissError,
	showReimbursement,
	setCrossdockParcelsMetadata,
	crossDocks
}) => {
	const { t } = useTranslation()
	const [loading, _setLoading] = useState(false)

	// things to do when user navigate into this page
	useIonViewWillEnter(() => {
		const crossdockParcelsMetadata =
			getNonOutdatedCrossdockParcelsMetadata(crossDocks) // clear summary data of the previous day, if any
		if (crossdockParcelsMetadata) {
			setCrossdockParcelsMetadata(crossdockParcelsMetadata)
		}
		storage.setItem(LAST_PAGE, window.location.pathname) // set this as the last visited page (for redirect after login)
		validateUser() // force re-login if needed
		// start refresh interval:
		// if (!shiftTimer) {
		// 	shiftTimer = setInterval(() => {
		// 		refreshAllShifts()
		// 	}, 10 * 1000)
		// }
	})

	// things to do when user navigate away from this page
	// useIonViewWillLeave(() => {
	// 	// stop refresh interval:
	// 	// (can't do on componentWillUnmount, because Ionic doesn't unmount pages)
	// 	clearInterval(shiftTimer)
	// 	shiftTimer = undefined
	// })

	// closes dialog
	// const closeDialog = () => {
	// 	setIsDialogOpen(false)
	// }

	// props for apply shift dialog
	// const applyDialogProps = {
	// 	header: 'Shift.message_confirm_availability',
	// 	buttonNoText: 'Shift.button_later',
	// 	buttonYesText: 'Shift.button_confirm',
	// 	onNo: closeDialog,
	// 	onYes: applyShift
	// }

	// props for sign off (withdraw) shift dialog
	// const signOffDialogProps = {
	// 	header: 'Shift.message_confirm_signoff',
	// 	buttonNoText: 'Shift.button_confirm_signoff',
	// 	buttonYesText: 'Shift.button_cancel_signoff',
	// 	onNo: signOffShift,
	// 	onYes: closeDialog
	// }

	// dismiss error
	const handleErrorDismissed = () => {
		if (error) {
			dismissError(error.errorName)
		}
	}

	return (
		<IonPage id="formpage">
			{loading ? (
				<IonSpinner style={{ margin: 'auto' }} />
			) : (
				<>
					<ErrorDisplay
						isOpen={!!error}
						blocking={false}
						requestId={error && error.requestId}
						message={error && error.message}
						onDismiss={handleErrorDismissed}
					/>
					<Header
						title={getTranslationValue(
							translations,
							t('Home.title')
						)}
						icon={icon}
					/>
					<StyledContent>
						<>
							<h6>{t('Summary.summary_overview_title')}</h6>
							<SummaryCard />
						</>
						<ShiftOverView />
						{showReimbursement && (
							<>
								<h6>{t('EXPENSE.title')}</h6>
								<ReimbursementCard />
							</>
						)}
						<>
							<h6>{t('INCORRECT_DELIVERY_INFO.title')}</h6>
							<IncorrectDeliveryInfoCard />
						</>
						<>
							<h6>{t('CROSSDOCK.title')}</h6>
							<CrossdockCard />
						</>
					</StyledContent>
					{/* <Dialog
						isOpen={isDialogOpen}
						onDismiss={closeDialog}
						dialogClass="custom-dialog"
						{...(dialogType === 'signOff'
							? signOffDialogProps
							: applyDialogProps)}
					/> */}
				</>
			)}
		</IonPage>
	)
}

Home.defaultProps = {
	translations: [],
	icon: '',
	error: null,
	fetchError: noop,
	otherError: noop,
	dismissError: noop,
	setCrossdockParcelsMetadata: noop,
	crossDocks: {}
}

const mapStateToProps = state => ({
	error: makeErrorSelector(['FETCH', 'OTHER'])(state),
	crossDocks: state.summary.crossdockParcelsMetadata
})

export default connect(mapStateToProps, {
	fetchError,
	otherError,
	dismissError,
	setCrossdockParcelsMetadata
})(Home)
