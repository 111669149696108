import { FIELD_TYPE } from 'components/Form/constants'

// 0. Constants (message variants):
export const FAILURE = 'FAILURE'
export const SUCCESS = 'SUCCESS'
export const WARNING = 'WARNING'

// 1. Message action types:
export const RESET = 'RESET'
export const SUBMIT_REQUEST = 'SUBMIT_REQUEST'
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export const SUBMIT_FAILURE = 'SUBMIT_FAILURE'
export const RETRY_REQUEST = 'RETRY_REQUEST'
// cross-docking status message
export const REVIEWING = 'REVIEWING'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'

// 2. Action creators:
export const resetMessage = () => ({
	type: RESET
})

export const updateMessage = (
	type,
	{ priority, countdown, currentItem, totalItem, field } = {}
) => ({
	type,
	priority,
	field,
	countdown,
	currentItem,
	totalItem
})

// 3. Initial states:
export const initState = {
	field: '',
	variant: '',
	content: '',
	priority: false,
	hasAction: false,
	dismissable: false,
	autoDismiss: false
}

// 4. Selectors:
export const getMessage = state => state.message
export const getMessageVariant = state => state.message.variant
export const getMessageContent = state => state.message.content
export const getMessageHasAction = state => state.message.hasAction

// 5. Reducers:
export default function messageReducer(
	state = initState,
	{ type, priority, field, countdown, currentItem, totalItem }
) {
	if (type !== RESET && state.priority && state.variant && !priority) {
		return state
	}
	// if form message is being displayed, field message should not overwrite it:
	if (!state.field && state.variant && field) {
		return state
	}

	const suffix = field === FIELD_TYPE.PHOTO ? '_photo' : ''

	switch (type) {
		case RESET:
			return initState
		case SUBMIT_REQUEST:
			return {
				field,
				variant: SUCCESS,
				content: { key: 'Form.message_uploading' + suffix },
				priority,
				hasAction: false,
				dismissable: true,
				autoDismiss: true
			}
		case SUBMIT_SUCCESS:
			return {
				field,
				variant: SUCCESS,
				content: { key: 'Form.message_upload_success' + suffix },
				priority,
				hasAction: true,
				dismissable: true,
				autoDismiss: true
			}
		case SUBMIT_FAILURE:
			return {
				field,
				variant: WARNING,
				content: {
					key: 'Form.message_upload_pending' + suffix,
					data: { totalItem, countdown }
				},
				priority,
				hasAction: !field,
				dismissable: false,
				autoDismiss: false
			}
		case RETRY_REQUEST:
			return {
				field,
				variant: WARNING,
				content: {
					key: 'Form.message_uploading_items' + suffix,
					data: { currentItem, totalItem }
				},
				priority,
				hasAction: false,
				dismissable: false,
				autoDismiss: false
			}
		case REVIEWING:
			return {
				variant: WARNING,
				content: { key: 'Form.message_crossdock_request' },
				priority,
				hasAction: true,
				dismissable: true,
				autoDismiss: false
			}
		case APPROVED:
			return {
				variant: SUCCESS,
				content: { key: 'Form.message_crossdock_success' },
				priority,
				hasAction: true,
				dismissable: true,
				autoDismiss: true
			}
		case REJECTED:
			return {
				variant: FAILURE,
				content: { key: 'Form.message_crossdock_failure' },
				priority,
				hasAction: true,
				dismissable: true,
				autoDismiss: true
			}
		default:
			return state
	}
}
