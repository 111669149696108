import _ from 'lodash'
import stgConfig from './stg.config'
import preConfig from './pre.config'
import prdConfig from './prd.config'
import ldevConfig from './ldev.config'

export const APP_ENV = process.env.REACT_APP_ENV

const configs = {
	stg: stgConfig,
	pre: preConfig,
	prd: prdConfig
}
/**
 * @type {{
 *     featureFlags: Record<string, boolean>
 * }}
 */
export const envConfig = _.has(configs, APP_ENV) ? configs[APP_ENV] : ldevConfig
