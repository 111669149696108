import { getCurrentTimezone } from 'utils/helpers'
import api from './axiosApiClient'

/**
 * @typedef {{
 * 	deliveryStage: string,
 * 	status: string,
 * 	orderStatus: string,
 * 	orderId: string | null,
 * 	id: string,
 * 	orderUniqueId: number,
 * 	deliveryUniqueId: number,
 *	notes: object,
 * 	extras: object,
 * 	totalWeight: string,
 * 	parcelNum: number,
 * 	clientId: string,
 * 	pickupAt: Date,
 * 	deliveryBy: Date,
 * 	address: object[],
 * 	logs: DeliveryFormSubmissionLogs[],
 * 	updatedAt: Date
 * }} DeliveryItem
 */

/**
	@typedef CourierV1SummariesResponse
	@property {DeliveryItem[]} data
	@property {number} page
	@property {number} limit
	@property {number} total
 */

/**
	@typedef CourierV1SummariesRequest
	@property {number} userId
	@property {Date} minUpdatedAt
	@property {boolean} isEndState
	@property {number} limit
	@property {number} page
 */

/**
 * @param {CourierV1SummariesRequest} request
 * @returns {Promise<CourierV1SummariesResponse>}
 */
export const getCourierV1Summaries = async ({
	userId,
	minUpdatedAt,
	isEndState,
	limit,
	page
}) => {
	const resp = await api.get('/courier/v1/summaries', {
		params: {
			userId,
			minUpdatedAt,
			isEndState,
			limit,
			page,
			timezone: getCurrentTimezone()
		}
	})
	return resp.data
}

/**
 * @typedef {{
 * 	id: string,
 * 	clientId: string,
 * 	tag: string,
 * 	action: string,
 * 	translations: {
 * 		value: string,
 * 		locale: string
 * 	}[],
 * 	input: {
 * 		formId: string,
 * 		fields: {
 * 			tag: string,
 * 			values: string[]
 * 		}[]
 * 	},
 * 	attributes: {
 * 		bgColor: string
 * 	},
 * 	createdAt: string,
 * 	updatedAt: string
 *	}} SummaryItemAction
 */

/**
 * @typedef {{
 * 	id: number,
 * 	clientId: string,
 * 	availableFor: string[],
 * 	summaryItemActions: SummaryItemAction[],
 * 	createdAt: string,
 * 	updatedAt: string
 * }} SummaryQuickAction
 */

/**
 * @typedef {SummaryQuickAction[]} GetSummaryQuickActionsResponse
 */

/**
 *
 * @returns {Promise<GetSummaryQuickActionsResponse>}
 */
export const getSummaryQuickActions = async () => {
	const resp = await api.get('summary-status-actions')
	return resp.data
}
