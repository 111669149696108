import React from 'react'
import { IonButton } from '@ionic/react'
import styled, { css } from 'styled-components'
import { FAILURE, SUCCESS, WARNING } from 'redux/message'
import { llmError, llmSuccess, llmWarning, white } from 'styles/colors'
import { ALERT } from 'styles/zIndex'
import { noop } from 'utils/helpers'

const Container = styled.div`
	top: 60px;
	${({ sticky }) =>
		sticky &&
		css`
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		`}
	z-index: ${ALERT};
	display: flex;
	justify-content: space-between;
	border-radius: 0;
	box-shadow: 0 10px 6px -6px #777;
	padding: 0.5em;
	color: ${white};
	background-color: ${({ type }) => {
		switch (type) {
			case FAILURE: {
				return llmError
			}
			case WARNING: {
				return llmWarning
			}
			default: {
				return llmSuccess
			}
		}
	}};
	transition: top 0.5s ease-in-out;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Button = styled(IonButton)`
	width: ${({ show }) => (show ? 'auto' : 0)};
	margin-left: 1em;
	--padding-start: 0;
	--padding-end: 0;
	--color: ${white};
`

const MessageToast = ({
	type,
	message,
	buttonText,
	buttonClick,
	shouldShowButton,
	sticky
}) => {
	const showButton = shouldShowButton && buttonText && buttonClick
	return (
		<Container type={type} sticky={sticky} id={`messageToast-${type}`}>
			<Content>{message}</Content>
			<Button
				show={showButton}
				color="light"
				fill="clear"
				onClick={buttonClick}
				className="messageToast-button"
			>
				{buttonText}
			</Button>
		</Container>
	)
}

MessageToast.defaultProps = {
	type: SUCCESS, // enum: ['FAILURE', 'SUCCESS', 'WARNING']
	message: '',
	buttonText: '',
	buttonClick: noop,
	shouldShowButton: false,
	sticky: false
}

export default MessageToast
