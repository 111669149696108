import moment from 'moment'

export const getShiftDataFormRegistration = registrations => registrations.shift

/**
 * @param {{shiftStartAt:string, shiftEndAt:string}}shift
 * @returns {string} example: 09:17 ~ 019:19
 */
export const getHourSecondString = shift => {
	const { shiftStartAt, shiftEndAt } = shift
	const startAtMoment = moment(shiftStartAt)
	const endAtMoment = moment(shiftEndAt).add(1, 'second')
	return `${startAtMoment.format('HH:mm')} ~ ${endAtMoment.format('HH:mm')}`
}

/**
 * @param {{shiftStart:string}} shiftData
 * @returns {string} example: June 23rd 09:17 ~ 019:19
 */
export const getMonthDateHourSecondString = shiftData => {
	const { shiftStartAt } = shiftData

	const startAtMoment = moment(shiftStartAt)

	return `${moment.months(startAtMoment.month())} ${startAtMoment.format(
		'Do'
	)} ${getHourSecondString(shiftData)}`
}
