import React, { createRef, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
	IonButton,
	IonChip,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	useIonViewWillLeave
} from '@ionic/react'
import {
	alert,
	checkbox,
	close,
	closeCircle,
	flash,
	flashOff,
	warning,
	reverseCamera
} from 'ionicons/icons'
import styled, { css } from 'styled-components'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import ZXing from 'assets/zxing_reader.js'
import axiosApiClient from 'api/axiosApiClient'
import Button from 'components/Button'
import ButtonGrid from 'components/ButtonGrid'
import ErrorDisplay from 'components/ErrorDisplay'
import { otherError, dismissError, makeErrorSelector } from 'redux/error'
import {
	getSelectedCamera,
	selectCamera,
	setCameras,
	getCameras
} from 'redux/config'
import {
	danger,
	llmError,
	llmSuccess,
	llmWarning,
	medium,
	secondary,
	silver,
	white
} from 'styles/colors'
import { monospaceFont } from 'styles/fonts'
import { GROUND, MODAL } from 'styles/zIndex'
import { CROSSDOCK_STATUS, PARCEL_STATUS, SUMMARY_TAB } from 'utils/constants'
import {
	cleanClientRef,
	findFormat,
	findFormatAndDisplay,
	formatDisplay,
	noop,
	setFocus,
	fetchDelivery,
	getTranslationValue,
	FILTERS,
	getIsDriverMode
} from 'utils/helpers'
import log from 'utils/log'
import storage, {
	DISPLAY_FORMATS,
	SELECTED_CAMERA,
	SELECTED_CAMERA_LABEL
} from 'utils/storage'
import Dialog from './Dialog'
import Heading from './Heading'
import { inflateQRCodeData, parseCrossDockQRCodeData } from 'utils/qrcode'
import { CLIENT_REF_LIMIT } from 'config'
import MessageToast from './MessageToast'
import { useHistory } from 'react-router'
import {
	updateCrossdockParcel,
	updateApiEndStateParcel,
	updateApiParcel
} from 'redux/summary'
import { driverWebviewSdk } from 'utils/driverApp'
import stringify from 'json-stringify-safe'

const TIME_BETWEEN_SCANS = 50 // milliseconds
const UPLOAD_TIMEOUT = 10 // seconds
const UPLOAD_LIMIT = 5 // limit of concurrent uploads
const NOTIF_TIMEOUT = 3 // seconds
const MANUAL = 'MANUAL' // manual barcode input
const MAX_FRAME_LENGTH = 1000 // max snapshot frame length (px)
const BARCODES_2D_FORMATS = [
	'Aztec',
	'DataMatrix',
	'MaxiCode',
	'PDF417',
	'QRCode'
] // zxing-cpp 2D formats
const MAX_NUMBER_OF_ID_SUBMITTABLE = 300

const {
	REACT_APP_ENABLE_LLMP_223_TORCH,
	REACT_APP_ENABLE_LLMP_224_DETECTION_AREA,
	REACT_APP_DEFAULT_SCAN_WINDOW_MS
} = process.env

// TODO:
const JS_TO_CPP_FORMAT = {
	CODABAR: 'Codabar',
	CODE_39: 'Code39',
	CODE_128: 'Code128',
	QR_CODE: 'QRCode'
}

const CustomIonModal = styled(IonModal)`
	.ion-page {
		padding-top: var(--ion-safe-area-top, 0);
	}
`

// section heading container, contains section title and some info
const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

// wrapper for scanner's video element
const VideoWrapper = styled.div`
	position: relative;
	display: ${({ show }) => (show ? 'block' : 'none')};
	margin: 1em -1em;

	/* canvas to draw lines: */
	canvas {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
	}
`

// barcode scanner video stream
const Video = styled.video`
	width: 100%;
`

const DetectionArea = styled.div`
	position: absolute;
	top: 15%;
	right: 1%;
	bottom: 15%;
	left: 1%;
	border: 2px solid ${secondary};
`

const TopBlind = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 15%;
	background-color: rgba(0, 0, 0, 0.5);
`
const RightBlind = styled.div`
	position: absolute;
	top: 15%;
	right: 0;
	bottom: 15%;
	width: 1%;
	background-color: rgba(0, 0, 0, 0.5);
`
const BottomBlind = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 15%;
	background-color: rgba(0, 0, 0, 0.5);
`
const LeftBlind = styled.div`
	position: absolute;
	top: 15%;
	bottom: 15%;
	left: 0;
	width: 1%;
	background-color: rgba(0, 0, 0, 0.5);
`

const VideoOverlay = styled.div`
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	left: 0.5em;
	display: flex;
	justify-content: space-between;
	z-index: ${GROUND + 1};
`

// scanner detection notification
const Notification = styled.span`
	padding: 0.25em;
	display: flex;
	flex: 1;
	align-self: start;
	align-items: center;
	margin-right: 0.5em;
	word-break: break-word;
	color: ${white};
	background-color: ${({ type }) => {
		switch (type) {
			case 'warning':
				return llmWarning
			case 'danger':
				return llmError
			default:
				return llmSuccess
		}
	}};
	opacity: 0.9;
`

const NotifIcon = styled(IonIcon)`
	margin-right: 0.25em;
`

const ButtonContainer = styled.div`
	margin-left: auto;
`
// close button to deactivate scanner
const CloseButton = styled(Button)`
	--padding-start: 0.75em;
	--padding-end: 0.75em;
`

const SwitchCameraButton = styled(Button)`
	--padding-start: 0.75em;
	--padding-end: 0.75em;
`

const TorchButton = styled(Button)`
	position: absolute;
	right: 0.5em;
	bottom: 0.5em;

	--padding-start: 0.75em;
	--padding-end: 0.75em;
`

// wraps ionic input
const InputWrapper = styled(IonItem)`
	flex: 1;
	margin: 1em 0;
	border: 1px solid ${({ error }) => (error ? danger : silver)};

	--min-height: 38px;
	--inner-padding-end: 0.25em;
`

const ButtonGroup = styled.div`
	display: flex;

	ion-button:last-child {
		flex: 1;
	}

	img {
		filter: brightness(0) invert(1);
	}
`

// list of ids
const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`

// list item (id)
const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;

	span {
		margin: 0.5em 0;
		span,
		img {
			vertical-align: middle;
		}
	}
`

// to display parcel IDs
const IdDisplay = styled.span`
	font-family: ${monospaceFont};
	font-size: 22px;
	word-break: break-all;
`

const SubIdDisplay = styled.div`
	font-family: ${monospaceFont};
	font-size: 14px;
	font-weight: bold;
	word-break: break-all;
	color: ${medium};
`

const DeleteButton = styled(IonButton)`
	margin: 0;
	font-size: 16px;

	--padding-start: 0.5em;
	--padding-end: 0;
`

// red warning text (block)
const InputError = styled.div`
	margin: -1em 0 1em 0;
	color: ${danger};
`

// input formatted value preview
const InputPreview = styled.div`
	margin: 0 0 1em 0;
	font-family: ${monospaceFont};
	font-size: 22px;

	${({ hasError }) =>
		hasError &&
		css`
			color: ${danger};
		`}
`

const CustomContent = styled(IonContent)`
	--ion-background-color: #f8f9fc;
`

const GridSeparator = styled.hr`
	border-top: 1px solid silver;
	margin: 0 1em;
`

const ModalCloseButton = styled(Button)`
	position: fixed;
	top: calc(var(--ion-safe-area-top, 0) + 0.5em);
	right: 0.5em;
	z-index: ${MODAL + 1};
	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

// list item component, contains id and delete button
const Item = ({
	id,
	clientId,
	clientName,
	orderId,
	onClickNoClient,
	onClickDelete,
	noPickup,
	displayFormat,
	readOnly,
	showSubInfo,
	simplified
}) => {
	const { t } = useTranslation()

	const handleClickDelete = () => {
		onClickDelete(id, displayFormat) // pass displayFormat to corectly render formatted value in dialog
	}

	return (
		<ListItem>
			<span>
				{/* <img src="assets/icon/parcel.svg" width="24" alt="parcel" style={{ marginRight: '0.5em' }} /> */}
				{!simplified && !clientId && (
					<IonChip
						outline
						color="danger"
						onClick={() => onClickNoClient(id)}
					>
						<IonIcon icon={alert}></IonIcon>
						<IonLabel>{t('No client')}</IonLabel>
					</IonChip>
				)}
				{!simplified && noPickup && (
					<IonChip color="danger">
						<IonLabel>{t('Form.message_no_pickup')}</IonLabel>
					</IonChip>
				)}
				<IdDisplay>{formatDisplay(id, displayFormat)}</IdDisplay>
				{/* {showSubInfo && (<SubIdDisplay>{orderId === 'LOADING' ? t('PORTAL.Login.button_loading') : `${t('Summary.label_order')}: ${orderId || 'N/A'} ${clientName ? `[${clientName}]` : ''}`}</SubIdDisplay>)} */}
				{!simplified && showSubInfo && (
					<SubIdDisplay id={`support-text-${id}`}>{`${
						orderId ? `${t('Summary.label_order')}: ${orderId}` : ''
					}${clientName ? ` [${clientName}]` : ''}`}</SubIdDisplay>
				)}
			</span>
			{!readOnly && onClickDelete !== noop && (
				<DeleteButton
					color="dark"
					fill="clear"
					onClick={handleClickDelete}
					className="gtm-btn-barcode-remove"
				>
					<IonIcon icon={close}></IonIcon>
				</DeleteButton>
			)}
		</ListItem>
	)
}

Item.defaultProps = {
	id: '', // item id
	onClickDelete: noop
}

const beep1 = new Audio('assets/audio/beep1.mp3')
const beep2 = new Audio('assets/audio/beep2.mp3')
let zxing
let stream
let stopScanLoop = false
// let scannerStarted = false
let notifTimeout = 0
let tempIds = []

let scanning = false // whether we are inside the 200ms (default) scan window
let scanTimeout // scan window timer
let scannedResults = {} // scanned result collection (includes meta)
let firstCode // the first scanned code, this opens the scan window
// (to be compared with the last scanned code that closes the scan window)
let numberOfUploads = 0 // number of concurrent uploads

let displayFormats = []

// main component, barcode section of dropoff form
const Barcode = ({
	clients,
	barcodes,
	onAdd,
	onUpdate,
	onUpdateByIndex,
	onDelete,
	onClear,
	onClose,
	onQRDetected,
	acceptQROnly,
	label,
	isRequired,
	valueFormats,
	defaultInputMode,
	formTag,
	fieldTag,
	formGroup,
	fieldId,
	clientId,
	selectedCamera,
	dispatchSelectCamera,
	scanWindow,
	submitting,
	readOnly,
	isHidden,
	blockMultiClients,
	forPickup,
	forSummary,
	error,
	otherError,
	dismissError,
	dispatchSetCameras,
	cameras,
	isNonBarcodeField,
	summaryParcelsObject
}) => {
	const inputRef = createRef()

	const { t } = useTranslation()

	const [showScanners, setShowScanners] = useState(false)
	const [_onlyManual, setOnlyManual] = useState(false)
	const [inputId, setInputId] = useState('')
	const [inputMode, setInputMode] = useState(defaultInputMode)

	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [dialogMessage, setDialogMessage] = useState('')
	const [dialogType, setDialogType] = useState('') // DELETE / WARNING
	const [dialogObj, setDialogObj] = useState(null) // parcel object relevant to dialog { id, format }

	const [isNotifOpen, setIsNotifOpen] = useState(false)
	const [notifMessage, setNotifMessage] = useState('')
	const [notifType, setNotifType] = useState('') // success / warning

	const [inputError, setInputError] = useState({})

	const [hasTorch, setHasTorch] = useState(false)
	const [isTorchOn, setIsTorchOn] = useState(false)

	const [clientlessBarcode, setClientlessBarcode] = useState(null) // barcode object with missing client
	const [clientBarcodes, setClientBarcodes] = useState([]) // list of clients with matching barcodes

	const [blockedBarcode, setBlockedBarcode] = useState(null)
	const [barcodeInputDisabled, setBarcodeInputDisabled] = useState(false)
	const dispatch = useDispatch()

	const history = useHistory()

	const isDriverMode = getIsDriverMode()

	const getCameras = async () => {
		// enumerate media devices:
		const devices = await navigator.mediaDevices.enumerateDevices()
		if (devices.length <= 0) {
			return []
		}

		if (devices[0].kind) {
			const result = devices.filter(
				item => item.kind === 'videoinput' && (item.deviceId || item.id)
			)
			// store video output only
			dispatchSetCameras(result)
			return result
		} else {
			dispatchSetCameras(devices)
			return devices
		}
	}
	const handleVisibilityChange = () => {
		// close scanners when app is hidden, e.g. in background, inactive tab, screen locked
		if (document.visibilityState === 'hidden') {
			stopScanner()
			if (forSummary) {
				onClose()
			}
		}
	}

	// to compare previous props values:
	const usePrevious = value => {
		const ref = useRef()
		useEffect(() => {
			ref.current = value
		})
		return ref.current
	}

	useIonViewWillLeave(() => {
		// close scanners when navigating to other pages
		stopScanner()
		if (forSummary) {
			onClose()
		}
	})

	useEffect(() => {
		if (forSummary) {
			// auto-start scanner for Summary Page's search by scanning:
			startScanner()
		}

		let savedFormats = JSON.parse(storage.getItem(DISPLAY_FORMATS)) || []
		savedFormats = savedFormats.filter(f => f.format && f.clientId)
		displayFormats = [] // reset displayFormats on new instance of barcode
		valueFormats.forEach(({ format, clientId }) => {
			if (format && !displayFormats.includes(format)) {
				displayFormats.push(format)
			}
			if (format && !savedFormats.some(f => f.format === format)) {
				savedFormats.push({ format, clientId })
			}
		})
		storage.setItem(DISPLAY_FORMATS, JSON.stringify(savedFormats))

		document.addEventListener(
			'visibilitychange',
			handleVisibilityChange,
			false
		)
		return () => {
			document.removeEventListener(
				'visibilitychange',
				handleVisibilityChange
			)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// close scanners when form changes
		stopScanner()

		let manualOnly = false
		if (valueFormats.length) {
			manualOnly = true
			valueFormats.forEach(({ type }) => {
				if (type !== MANUAL) {
					manualOnly = false
				}
			})
		} else {
			manualOnly = true
		}
		setOnlyManual(manualOnly)
	}, [formTag]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// close scanners when form is submitted
		if (submitting) {
			stopScanner()
		}
	}, [submitting]) // eslint-disable-line react-hooks/exhaustive-deps

	// previous prop value:
	const prev = usePrevious({ barcodes, selectedCamera })

	useEffect(() => {
		if (!selectedCamera) {
			const selectedCamera = storage.getItem(SELECTED_CAMERA)
			const selectedCameraLabel = storage.getItem(SELECTED_CAMERA_LABEL)
			dispatchSelectCamera(selectedCamera, selectedCameraLabel)
		}
		log.info('selectedCamera changed:', selectedCamera)
		// restart scanners if visible:
		if (showScanners && prev && prev.selectedCamera) {
			stopScanner()
			startScanner()
		}
	}, [selectedCamera]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		tempIds = barcodes
		if (!forSummary && !forPickup && fieldTag === 'BARCODE') {
			if (prev && barcodes.length > prev.barcodes.length) {
				const barcode = barcodes[barcodes.length - 1]
				getParcelInfo(barcode)
				if (!barcode.metas[0].clientId) {
					stopScanner()
					handleClickNoClient(barcode.value)
				}
			}
		}

		// hotfix for 2022-12-16 db loading issues
		setBarcodeInputDisabled(barcodes?.length >= 50)
	}, [barcodes]) // eslint-disable-line react-hooks/exhaustive-deps

	// vibrates and plays the given audio
	const vibrateAndPlayAudio = async audio => {
		try {
			if (window.navigator.vibrate) {
				window.navigator.vibrate(200)
			}
		} catch (error) {
			log.error('Vibration not supported/allowed', null, error.stack)
		}

		try {
			await audio.play()
		} catch (error) {
			log.error(
				'Audio auto-play not supported/allowed',
				null,
				error.stack
			)
		}
	}

	// toggle torch on/off
	const toggleTorch = () => {
		try {
			if (!stream) {
				return
			}
			const tracks = stream.getVideoTracks()
			if (!tracks || !tracks.length) {
				return
			}
			tracks[0].applyConstraints({ advanced: [{ torch: !isTorchOn }] })
			setIsTorchOn(!isTorchOn)
		} catch (e) {
			log.error('toggleTorch failed', null, e.stack)
		}
	}

	// finds clientId from summary data
	const findClientIdFromSummary = values => {
		if (clientId) {
			return clientId
		} // if the form has clientId use it
		// otherwise find it from summary:
		const parcel =
			summaryParcelsObject[values.find(v => summaryParcelsObject[v])]
		return parcel ? parcel.clientId : null
	}

	// checks if item is picked up:
	const checkPickedUp = (values, checkPrefix) => {
		let cltId = clientId
		let pickedUp = false
		if (checkPrefix) {
			const keys = Object.keys(summaryParcelsObject).filter(key =>
				values.some(v => key.startsWith(v))
			)
			for (const key of keys) {
				if (
					summaryParcelsObject[key].logs.some(
						log => log.status === PARCEL_STATUS.IN_PROGRESS
					) &&
					(!cltId || cltId === summaryParcelsObject[key].clientId)
				) {
					cltId = summaryParcelsObject[key].clientId
					pickedUp = true
					return { clientId: cltId, pickedUp }
				}
			}
			return { clientId: cltId, pickedUp }
		}
		const parcel =
			summaryParcelsObject[values.find(v => summaryParcelsObject[v])]
		if (
			parcel &&
			parcel.logs.some(log => log.status === PARCEL_STATUS.IN_PROGRESS) &&
			(!cltId || cltId === parcel.clientId)
		) {
			cltId = parcel.clientId
			pickedUp = true
		}
		return { clientId: cltId, pickedUp }
	}

	const getParcelInfo = async item => {
		if (item.metas[0].orderId) {
			return
		}

		const parcelId = item.value
		const parcel = summaryParcelsObject[item.value]
		if (parcel && parcel.orderId) {
			item.metas[0].orderId = parcel.orderId
			onUpdate(item)
			return
		}
		// fetch order ID from API:
		if (!item.metas[0].clientId) {
			return
		}
		const delivery = await fetchDelivery(item.metas[0].clientId, parcelId)
		if (delivery) {
			let newData

			// update existing data with info from order:
			if (delivery.order && parcel) {
				const { address, contacts, pickupAt, deliveryBy, createdAt } =
					delivery.order
				// for backward compatibility with old contacts:
				address.forEach(addr => {
					if (!addr.contacts && contacts && contacts.length) {
						addr.contacts = contacts
					}
				})
				const logs = parcel.logs || []
				if (
					!logs.some(
						log => log.status === PARCEL_STATUS.AWAITS_PICKUP
					)
				) {
					logs.push({
						status: PARCEL_STATUS.AWAITS_PICKUP,
						time: createdAt
					})
				}
				newData = { address, pickupAt, deliveryBy, logs }
			}

			// get the order ID:
			const orderId = (
				delivery.clientRefs.find(c => c.type === 'ORDER') || {}
			).value
			// save the order ID into existing data:
			if (orderId && parcel) {
				if (!newData) {
					newData = {}
				}
				newData.orderId = orderId
			}
			// if there is any updated data, save them:
			if (newData) {
				const payload = {
					...parcel,
					...newData
				}
				if (CROSSDOCK_STATUS.includes(delivery.status)) {
					dispatch(updateCrossdockParcel(payload))
				} else if (FILTERS.OFFLOADED.includes(delivery.status)) {
					dispatch(updateApiEndStateParcel(payload))
				} else {
					dispatch(updateApiParcel(payload))
				}
			}
			// render the order ID:
			if (orderId) {
				// eslint-disable-next-line require-atomic-updates
				item.metas[0].orderId = orderId
				onUpdate(item)
				return
			}
		}
		// eslint-disable-next-line require-atomic-updates
		item.metas[0].orderId = undefined
		onUpdate(item)
	}

	const createNewBarcode = (
		value,
		displayFormat,
		duration,
		manual,
		noPickup,
		metas
	) => ({
		value,
		displayFormat,
		duration,
		manual,
		noPickup,
		metas
	})

	// start barcode scanners
	const handleClickStartScan = async () => {
		await startScanner()
	}

	const onNonBarcodeSelectButtonClick = () => {
		let targetSummaryTab = SUMMARY_TAB.TO_PICK_UP
		if (typeof formTag === 'string') {
			if (formTag.startsWith('PICKUP_')) {
				targetSummaryTab = SUMMARY_TAB.TO_PICK_UP
			} else if (formTag.startsWith('DROP_OFF_')) {
				targetSummaryTab = SUMMARY_TAB.IN_THE_VEHICLE
			}
		}

		if (isDriverMode) {
			driverWebviewSdk.changeTab('/')
		}

		history.push('/summary', {
			tab: targetSummaryTab
		})
	}

	// stop and close barcode scanner
	const handleClickClose = () => {
		stopScanner()
		if (forSummary) {
			onClose()
		}
	}

	const startScanner = async (camera = null) => {
		if (!cameras || cameras.length <= 0) {
			if (!navigator.mediaDevices) {
				return
			}
			await getCameras()
		}
		try {
			setShowScanners(true)
			await startStream(camera)
			await startZxing()
			if (REACT_APP_ENABLE_LLMP_223_TORCH !== 'true') {
				return
			}
			// get torch capability:
			try {
				if (stream) {
					const tracks = stream.getVideoTracks()
					// firefox does not support getCapabilities as of v83 (2020-11-17):
					if (!tracks || !tracks.length) {
						return
					}
					if (tracks[0].getCapabilities) {
						const { torch } = tracks[0].getCapabilities()
						// true if torch is available, otherwise undefined
						setHasTorch(!!torch)
					}
					if (tracks[0].getSettings) {
						const { deviceId, label } = tracks[0].getSettings()
						if (deviceId && !selectedCamera) {
							dispatchSelectCamera(deviceId, label)
							storage.setItem(SELECTED_CAMERA, deviceId)
						}
					}
				} else {
					Sentry.captureMessage('Barcode scanner no stream', {
						level: 'debug'
					})
				}
			} catch (e) {
				Sentry.captureMessage(
					'Failed to get video capabilities and settings',
					{
						level: 'error',
						extra: {
							error: stringify(e)
						}
					}
				)
				log.error(
					'Failed to get video capabilities and settings',
					null,
					e.stack
				)
			}
		} catch (err) {
			Sentry.captureMessage('Failed to start scanner', {
				level: 'error',
				extra: {
					error: stringify(err)
				}
			})
			log.error('Failed to start scanner', null, err.stack)
			otherError({ message: err.toString() })
		}
	}

	// start and attach camera stream to video element
	const startStream = async targetCamera => {
		const cameraId =
			targetCamera || selectedCamera || storage.getItem(SELECTED_CAMERA)
		stream = await navigator.mediaDevices.getUserMedia({
			video: {
				facingMode: 'environment',
				...(cameraId && { deviceId: cameraId })
			}
		})

		return new Promise(resolve => {
			const videoElement = document.getElementById(`${fieldId}-video`)
			videoElement.srcObject = stream
			videoElement.setAttribute('autoplay', true)
			videoElement.setAttribute('muted', true)
			videoElement.setAttribute('playsinline', true)
			videoElement.addEventListener('loadedmetadata', () => {
				videoElement.play()
				log.info('Stream start')
				resolve()
			})
		})
	}

	// do the scan
	const scan = (canvasElm, formats) => {
		// reference https://discuss.elastic.co/t/uncaught-indexsizeerror-failed-to-execute-getimagedata-on-canvasrenderingcontext2d-the-source-width-is-0/174697/3
		const imgWidth = canvasElm.width === 0 ? 1280 : canvasElm.width
		const imgHeight = canvasElm.height === 0 ? 720 : canvasElm.height
		const imageData = canvasElm
			.getContext('2d')
			.getImageData(0, 0, imgWidth, imgHeight)
		const sourceBuffer = imageData.data

		const buffer = zxing._malloc(sourceBuffer.byteLength)
		zxing.HEAPU8.set(sourceBuffer, buffer)
		const result = zxing.readBarcodeFromPixmap(
			buffer,
			imgWidth,
			imgHeight,
			true,
			formats
		)
		zxing._free(buffer)
		return result
	}

	// TODO: disabled switch camera
	const switchCamera = async () => {
		await stopScanner()
		const cam = await getCameras()
		let index = 0
		if (selectedCamera) {
			index = cam.findIndex(
				camera =>
					camera.id === selectedCamera ||
					camera.deviceId === selectedCamera
			)
			if (index === cam.length - 1) {
				index = 0
			} else {
				index += 1
			}
		}
		if (cam[index]) {
			const cameraId = cam[index].id || cam[index].deviceId
			dispatchSelectCamera(cameraId, cam[index].label)
			storage.setItem(SELECTED_CAMERA, cameraId)
			storage.setItem(SELECTED_CAMERA_LABEL, cam[index].label)
			await startScanner(cameraId)
		} else {
			startScanner()
		}
	}

	// draws line between 2 points on the given canvas:
	const drawLine = (canvasCtx, begin, end) => {
		canvasCtx.beginPath()
		canvasCtx.moveTo(begin.x, begin.y)
		canvasCtx.lineTo(end.x, end.y)
		canvasCtx.lineWidth = 4
		canvasCtx.strokeStyle = danger
		canvasCtx.stroke()
	}

	// start ZXing scanner
	const startZxing = async () => {
		const video = document.getElementById(`${fieldId}-video`)
		const imgCanvasElm = document.createElement('canvas')
		const imgCanvasCtx = imgCanvasElm.getContext('2d')

		const lineCanvasElm = document.getElementById(`${fieldId}-canvas`)
		const lineCanvasCtx = lineCanvasElm.getContext('2d')

		// prepare expected formats (barcode types):
		const expectedFormats = []
		let isAll = false
		if (valueFormats.length) {
			valueFormats.forEach(({ type }) => {
				const normType = JS_TO_CPP_FORMAT[type] || type
				if (normType !== MANUAL) {
					if (!expectedFormats.includes(normType)) {
						expectedFormats.push(normType)
					}
				}
				if (normType === 'ALL') {
					isAll = true
				}
			})
		}
		const formats =
			expectedFormats.length && !isAll ? expectedFormats.join('|') : ''

		zxing = await ZXing()
		stopScanLoop = false

		// the scan loop:
		const loop = () => {
			if (stopScanLoop) {
				stopScanLoop = false
				return
			}

			if (video.readyState === video.HAVE_ENOUGH_DATA) {
				imgCanvasElm.height = video.videoHeight
				imgCanvasElm.width = video.videoWidth
				imgCanvasCtx.drawImage(
					video,
					0,
					0,
					imgCanvasElm.width,
					imgCanvasElm.height
				)

				lineCanvasElm.height = video.videoHeight
				lineCanvasElm.width = video.videoWidth

				const result = scan(imgCanvasElm, formats)
				const { error, format, position, text } = result
				if (error) {
					// has error
					log.error(`ZXing returns error: ${error}`)
				} else if (format) {
					// has result
					if (position) {
						const { topLeft, topRight, bottomRight, bottomLeft } =
							position
						drawLine(lineCanvasCtx, topLeft, topRight)
						drawLine(lineCanvasCtx, topRight, bottomRight)
						drawLine(lineCanvasCtx, bottomRight, bottomLeft)
						drawLine(lineCanvasCtx, bottomLeft, topLeft)
					}
					if (acceptQROnly) {
						handleQRDetection(text, format)
					} else {
						handleDetection(cleanClientRef(text), format)
					}
				}
			}
			setTimeout(() => loop(), TIME_BETWEEN_SCANS)
		}
		loop()

		// scannerStarted = true
		log.info('Scanner start')
	}

	// resets the scan window
	const resetWindow = () => {
		scanning = false
		clearTimeout(scanTimeout)
	}

	// resets data collected within a scan window
	const resetData = () => {
		firstCode = undefined
		scannedResults = {}
	}

	// uploads video frame:
	const uploadFrame = async (name, data, externalResult = {}) => {
		if (!name || !data) {
			return
		}
		try {
			numberOfUploads += 1
			const result = await axiosApiClient.post(
				'upload',
				{ name, data },
				{
					timeout: UPLOAD_TIMEOUT * 1000
				}
			)
			// eslint-disable-next-line require-atomic-updates
			numberOfUploads -= 1
			externalResult.frame = result?.data?.url || null
		} catch (error) {
			Sentry.captureMessage('uploadFrame:failure', {
				level: 'warning',
				extra: {
					errorMessage: error.message,
					error: stringify(error),
					name,
					data,
					externalResult
				}
			})
			// eslint-disable-next-line require-atomic-updates
			numberOfUploads -= 1
			log.error(
				'Failed to upload frame image',
				{ category: 'API' },
				error.stack
			)
		}
	}

	// capture and upload video frame, then
	// store new unique scanned result into collection
	const handleScanResult = (
		value,
		rawValue,
		format,
		formattedValue,
		displayFormat,
		valueClientId,
		matchingValues
	) => {
		const timestamp = Date.now()
		const { REACT_APP_AWS_S3_HOST, REACT_APP_AWS_S3_FOLDER } = process.env
		const name = `${rawValue}-${format}-${timestamp}`

		const result = {
			displayFormat,
			format,
			formattedValue,
			frame: null,
			hits: 1, // first hit
			rawValue,
			scannedAt: new Date().toISOString(),
			timestamp, // for sorting
			value,
			valueClientId,
			clientId,
			matchingValues
		}
		// capture and upload video frame image:
		// only if not PICKUP forms
		// only if concurrent upload is below limit
		// only if not duplicate
		if (
			!forSummary &&
			!forPickup &&
			numberOfUploads < UPLOAD_LIMIT &&
			!tempIds.find(tempId => tempId.value === value)
		) {
			result.frame = `${REACT_APP_AWS_S3_HOST}/${REACT_APP_AWS_S3_FOLDER}/${name}.jpeg`
			const data = captureFrame() // capture video frame
			uploadFrame(name, data, result) // upload video frame
		}

		const key = `${rawValue}-${format}`
		scannedResults[key] = result
	}

	// constructs value from multiple matches:
	// TODO: configurable separator, display formatting..
	const createValueFromMatches = (matches, separator = '-') => {
		// eslint-disable-next-line prefer-const
		let [raw, value] = matches
		if (matches.length > 2) {
			value = matches
				.slice(1)
				.filter(i => i)
				.join(separator)
		}
		return [raw, value]
	}

	// handles detection result from all scanners:
	const handleDetection = (code, format) => {
		log.info('Detected', code, format)

		if (code.length > CLIENT_REF_LIMIT) {
			vibrateAndPlayAudio(beep1)
			setNotifMessage(t('Form.message_clientref_length_error'))
			setNotifType('danger')
			setIsNotifOpen(true)
			clearTimeout(notifTimeout)
			notifTimeout = setTimeout(() => {
				setIsNotifOpen(false)
				clearTimeout(notifTimeout)
			}, NOTIF_TIMEOUT * 1000)
			return false
		}
		// try to match with regexps:
		let matches = []
		// by default, value === rawValue:
		let rawValue = code
		let value = code
		let formattedValue = false
		let displayFormat = ''
		let valueClientId = null
		const matchingValues = { all: [], byClient: {} }
		let matchCount = 0

		const { clientId, pickedUp } = checkPickedUp([value])
		const isPickedUp = forSummary || forPickup || pickedUp

		if (valueFormats.length) {
			matches = []
			let filteredValueFormats = [...valueFormats]
			if (isPickedUp && clientId) {
				filteredValueFormats = valueFormats.filter(
					i => i.clientId === clientId
				)
			}
			filteredValueFormats.some(obj => {
				const normType = JS_TO_CPP_FORMAT[obj.type] || obj.type
				if (normType === 'ALL' || !obj.regexp.length) {
					if (!matches) {
						matches = []
					}

					// If the item wasn't picked up previously we don't match it
					if (!matches.length && !isPickedUp) {
						matches = null
					}
					return false
				}
				if (format !== normType) {
					return false
				}
				// skip formats of unknown client:
				if (obj.clientId && !clients.some(c => c.id === obj.clientId)) {
					return false
				}
				let currentMatches = null
				for (const re of obj.regexp) {
					currentMatches = code.match(re)
					if (!(matches && matches.length && !currentMatches)) {
						matches = currentMatches
					}
					if (currentMatches) {
						break
					}
				}
				if (currentMatches) {
					displayFormat = obj.format
					valueClientId = obj.clientId
					const [currentRaw, currentValue] =
						createValueFromMatches(currentMatches)
					matchingValues.all.push(currentValue || currentRaw)
					matchingValues.byClient[obj.clientId || clientId] = {
						value: currentValue || currentRaw,
						format: obj.format
					}
					matchCount += 1
				}
				return !!currentMatches && obj.clientId === clientId
			})
			if (matchCount < 1) {
				valueClientId = null
			}
		}

		if (!matches) {
			// no match with any regexp, silently ignore:
			log.warning('Invalid barcode:', rawValue, format)
			return
		}
		if (matches && matches.length) {
			const [raw, processed] = createValueFromMatches(matches)
			rawValue = raw
			// if no matching substring, value === rawValue:
			value = processed || raw
			// formattedValue depends on matching substring:
			formattedValue = !!processed
		}

		const key = `${code}-${format}`

		// no scan window logic for:
		// - 2D Barcodes (e.g. QR Code, PDF417, etc.)
		// - Search-by-scan in Summary Page
		// directly process result:
		if (BARCODES_2D_FORMATS.includes(format) || forSummary) {
			resetData()
			handleScanResult(
				value,
				rawValue,
				format,
				formattedValue,
				displayFormat,
				valueClientId,
				matchingValues
			)
			const results = Object.values(scannedResults)
			processResult(
				results[0].value,
				results[0].displayFormat,
				0,
				results
			)
			return
		}

		// BEGIN SCAN WINDOW LOGIC:
		if (!scanning) {
			// if currently not inside scan window

			let maxHitsCount = 0
			let maxHitsCode = firstCode
			Object.entries(scannedResults).forEach(([k, v]) => {
				if (v.hits > maxHitsCount) {
					maxHitsCount = v.hits
					maxHitsCode = k
				}
			})

			if (
				Object.keys(scannedResults).length === 0 ||
				key !== firstCode ||
				key !== maxHitsCode
			) {
				// if no scanned result, OR
				// has scanned results but closing code doesn't match with (opening code OR code with most hits), start scanning
				if (key !== firstCode || key !== maxHitsCode) {
					// if has scanned results but closing code doesn't match with (opening code OR code with most hits), reset results
					resetData()
				}
				firstCode = key // remember first code
				// Capture, upload frame. Put to scanned results collection:
				handleScanResult(
					value,
					rawValue,
					format,
					formattedValue,
					displayFormat,
					valueClientId,
					matchingValues
				)
				scanning = true // now we are inside scan window
				// start scan window:
				clearTimeout(scanTimeout)
				scanTimeout = setTimeout(() => {
					resetWindow()
				}, scanWindow)
			} else {
				const closingTime = Date.now()
				// if there are scanned results, process them
				scannedResults[key].hits += 1
				// sort results by hits desc, then time asc:
				const results = Object.values(scannedResults).sort((a, b) => {
					if (a.hits === b.hits) {
						return a.timestamp - b.timestamp
					}
					return b.hits - a.hits
				})
				// selected value is the 1st in the sorted list:
				const duration = closingTime - results[0].timestamp
				processResult(
					results[0].value,
					results[0].displayFormat,
					duration,
					results
				) // process the result
				resetData() // reset
			}
		} else {
			// if currently inside scan window, collect results
			if (scannedResults[key]) {
				// if result is already in collection, increment hits
				scannedResults[key].hits += 1
			} else {
				// otherwise, add new scanned result
				handleScanResult(
					value,
					rawValue,
					format,
					formattedValue,
					displayFormat,
					valueClientId,
					matchingValues
				)
			}
		}
		// END SCAN WINDOW LOGIC;
	}

	// process detection result from all scanners:
	const processResult = (value, displayFormat, duration, metas) => {
		let matchedValue = value
		let matchedFormat = displayFormat
		if (metas && metas.length) {
			metas.forEach(meta => {
				// if no clientId, try to find from form aggregates,
				// if still not found, use clientId from regexp matching during scan:
				if (!meta.clientId) {
					meta.clientId =
						findClientIdFromSummary([
							value,
							...meta.matchingValues.all
						]) || meta.valueClientId
				}
			})
			const meta = metas[0] // only do multi-clients matching with the first meta:
			if (meta.clientId && meta.matchingValues.byClient[meta.clientId]) {
				matchedValue = meta.matchingValues.byClient[meta.clientId].value
				matchedFormat =
					meta.matchingValues.byClient[meta.clientId].format
				meta.value = matchedValue
				meta.displayFormat = matchedFormat
			}
		}

		const { pickedUp } = checkPickedUp([matchedValue])
		const noPickup = !forSummary && !forPickup && !pickedUp
		const duplicate = tempIds.find(
			tempId =>
				metas[0].matchingValues.all.includes(tempId.value) ||
				value === tempId.value
		)

		if (forSummary) {
			const ids = [...Object.keys(summaryParcelsObject)]
			const found = ids.includes(matchedValue)
			if (found) {
				vibrateAndPlayAudio(beep1)
				setNotifMessage(
					t('Summary.message_scan_search_item_found', {
						item: formatDisplay(matchedValue, matchedFormat)
					})
				)
				setNotifType('success')
			} else {
				vibrateAndPlayAudio(beep2)
				setNotifMessage(
					t('Summary.message_scan_search_item_not_found', {
						item: formatDisplay(matchedValue, matchedFormat)
					})
				)
				setNotifType('danger')
			}
			if (!duplicate) {
				onAdd(
					createNewBarcode(
						matchedValue,
						matchedFormat,
						duration,
						false,
						false,
						metas
					)
				)
			}
		} else if (
			blockMultiClients &&
			tempIds.length &&
			metas[0].clientId &&
			tempIds[0].metas[0].clientId !== metas[0].clientId
		) {
			// block multiple clients
			vibrateAndPlayAudio(beep2)
			setNotifMessage(
				t('Form.message_client_mismatch_item', {
					item: formatDisplay(matchedValue, matchedFormat)
				})
			)
			setNotifType('danger')
		} else if (duplicate) {
			// duplicate
			if (Date.now() - duplicate.metas[0].timestamp <= 3000) {
				return
			}
			vibrateAndPlayAudio(beep2)
			setNotifMessage(
				t('Form.message_duplicate_item', {
					item: formatDisplay(matchedValue, matchedFormat)
				})
			)
			setNotifType('warning')
		} else if (tempIds.length >= MAX_NUMBER_OF_ID_SUBMITTABLE) {
			vibrateAndPlayAudio(beep2)
			setNotifMessage(t('Form.message_max_item_error'))
			setNotifType('warning')
		} else if (noPickup) {
			vibrateAndPlayAudio(beep2)
			setNotifMessage(
				`${t('Form.message_no_pickup')}: ${formatDisplay(
					matchedValue,
					matchedFormat
				)}`
			)
			setNotifType('warning')
			onAdd(
				createNewBarcode(
					matchedValue,
					matchedFormat,
					duration,
					false,
					true,
					metas
				)
			)
		} else {
			vibrateAndPlayAudio(beep1)
			setNotifMessage(
				t('Form.message_item_added', {
					item: formatDisplay(matchedValue, matchedFormat)
				})
			)
			setNotifType('success')
			onAdd(
				createNewBarcode(
					matchedValue,
					matchedFormat,
					duration,
					false,
					false,
					metas
				)
			)
		}
		setIsNotifOpen(true)
		clearTimeout(notifTimeout)
		notifTimeout = setTimeout(() => {
			setIsNotifOpen(false)
			clearTimeout(notifTimeout)
		}, NOTIF_TIMEOUT * 1000)
	}

	const handleQRDetection = (code, format) => {
		log.info('QR Detected', code, format)
		const params = inflateQRCodeData(code) // our qrcode data is compressed using `deflateQRCodeData`
		onClear()

		// TODO: update hard-coded BARCODE:
		if (params.BARCODE) {
			// contains something like 'ACME:123,3456|UMBRELLA:45432,2345678,323,2222222'
			const metas = parseCrossDockQRCodeData(params.BARCODE)

			for (const meta of metas) {
				onAdd(
					createNewBarcode(
						meta[0].value,
						findFormat(displayFormats, meta[0].value),
						0,
						false,
						false,
						meta
					)
				)
			}
			onQRDetected(params)
			stopScanner()
		}
	}

	const stopStream = () => {
		// stop the stream that was started:
		if (!stream) {
			return
		}
		const tracks = stream.getVideoTracks()
		if (!tracks || !tracks.length) {
			return
		}
		tracks.forEach(track => {
			stream.removeTrack(track)
			track.stop()
		})
		log.info('Stream stop')
	}

	const stopZxing = () => {
		// stop ZXing scanner:
		stopScanLoop = true
		// scannerStarted = false
		log.info('Scanner stop')
	}

	const stopScanner = () => {
		setIsTorchOn(false)
		setShowScanners(false)

		stopZxing()
		stopStream()

		setIsNotifOpen(false)
		resetData()
		resetWindow()
	}

	// manually add ID
	const manuallyAddId = async () => {
		if (!inputId) {
			return
		}
		const upperCasedId = cleanClientRef(inputId)

		const metas = [
			// default meta for MANUAL input:
			{
				format: MANUAL,
				formattedValue: false,
				frame: null,
				hits: 1,
				rawValue: upperCasedId, // rawValue equals to value for MANUAL
				scannedAt: new Date().toISOString(),
				timestamp: Date.now(),
				value: upperCasedId,
				// try to find from form aggregates:
				clientId: findClientIdFromSummary([upperCasedId])
			}
		]
		const { clientId, pickedUp } = checkPickedUp([upperCasedId])
		const manualDisplayFormats = []
		const filteredValueFormats = valueFormats.filter(
			i => i.clientId === clientId
		)
		filteredValueFormats.forEach(({ format }) => {
			if (format && !manualDisplayFormats.includes(format)) {
				manualDisplayFormats.push(format)
			}
		})
		if (
			blockMultiClients &&
			barcodes.length &&
			metas[0].clientId &&
			barcodes[0].metas[0].clientId !== metas[0].clientId
		) {
			// block multiple clients
			setInputError({ key: 'Form.message_client_mismatch' })
		} else if (barcodes.find(barcode => barcode.value === upperCasedId)) {
			// duplicate
			setInputError({ key: 'Form.dialog_duplicate_item' })
		} else if (barcodes.length >= MAX_NUMBER_OF_ID_SUBMITTABLE) {
			setInputError({ key: 'Form.message_max_item_error' })
		} else if (!forSummary && !forPickup && !pickedUp) {
			// no pickup
			onAdd(
				createNewBarcode(
					upperCasedId,
					findFormat(displayFormats, upperCasedId),
					0,
					true,
					true,
					metas
				)
			)
			setInputId('')
		} else {
			// if pickedUp, then format according to client valueFormats
			onAdd(
				createNewBarcode(
					upperCasedId,
					findFormat(manualDisplayFormats, upperCasedId),
					0,
					true,
					false,
					metas
				)
			)
			setInputId('')
		}
	}

	// on id input change
	const handleInputChange = e => {
		const { value } = e.target
		setInputId(value)
		let err = {}
		if (value) {
			const { pickedUp } = checkPickedUp([cleanClientRef(value)], true)
			const noPickup = !forSummary && !forPickup && !pickedUp
			if (noPickup) {
				err = { key: 'Form.message_no_pickup' }
			}
		}
		setInputError(err)
	}
	// manually add id from input
	const handleClickAdd = () => {
		if (inputId) {
			// add new ID if input is filled
			manuallyAddId()
		}
		stopScanner()
	}

	// handle enter key press on input
	const handleInputKeyPress = e => {
		if (e.key === 'Enter') {
			e.preventDefault()
			if (inputId) {
				manuallyAddId()
			}
		}
	}

	// on delete id
	const handleDelete = (id, format) => {
		setDialogObj({ id, format })
		setDialogMessage(t('Form.dialog_delete_scanned'))
		setDialogType('DELETE')
		setIsDialogOpen(true)
	}

	// closes dialog
	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	// confirm dialog
	const confirmDialog = () => {
		setIsDialogOpen(false)
		if (dialogType === 'DELETE') {
			onDelete(dialogObj.id)
		}
	}

	// disable button if:
	// input is empty and,
	// scanner is closed or scanner is opened but list is empty
	const addButtonDisabled =
		!inputId && (!showScanners || (showScanners && !barcodes.length))

	// text is ADD {{item}} by default
	let addButtonText = t('Common.button_add_item', { item: label })
	if (!addButtonDisabled && !inputId) {
		// if the button is just meant to close scanner, change text
		addButtonText = t('Common.button_ok')
	}

	// captures video frame into base64 image
	const captureFrame = () => {
		const video = document.getElementById(`${fieldId}-video`)
		const canvas = document.createElement('canvas')
		const context = canvas.getContext('2d')
		let width = video.videoWidth
		let height = video.videoHeight
		// resize width or height if larger than max frame length:
		if (width > height) {
			if (width > MAX_FRAME_LENGTH) {
				height *= MAX_FRAME_LENGTH / width
				width = MAX_FRAME_LENGTH
			}
		} else {
			if (height > MAX_FRAME_LENGTH) {
				width *= MAX_FRAME_LENGTH / height
				height = MAX_FRAME_LENGTH
			}
		}
		canvas.width = width
		canvas.height = height
		context.drawImage(video, 0, 0, width, height)
		const base64 = canvas.toDataURL('image/jpeg')
		return base64
	}

	// toggle between inputmodes: TEXT/NUMERIC
	// and set focus to bring up the keyboard
	const toggleInputMode = () => {
		setInputMode(inputMode === 'TEXT' ? 'NUMERIC' : 'TEXT')
		setFocus(inputRef)
	}

	const handleErrorDismissed = () => {
		dismissError(error.errorName)
	}

	// when a clientless barcode item is clicked:
	const handleClickNoClient = id => {
		// find the clientless barcode object by id
		const noClientBarcode = barcodes.find(b => b.value === id)
		// populate matching clients for this barcode:
		const matchingClients = []
		if (valueFormats.length) {
			if (noClientBarcode.metas[0].format === 'MANUAL') {
				valueFormats.forEach(obj => {
					if (!obj.format) {
						return
					}
					const formatLength = obj.format.split('?').length - 1
					if (id.length === formatLength) {
						matchingClients.push({
							clientId: obj.clientId,
							value: id,
							displayFormat: obj.format
						})
					}
				})
			} else {
				const { rawValue } = noClientBarcode.metas[0]
				let matches = []
				valueFormats.forEach(obj => {
					for (const re of obj.regexp) {
						matches = rawValue.match(re)
						if (matches) {
							break
						}
					}
					if (matches && matches.length) {
						const [, value] = createValueFromMatches(matches)
						matchingClients.push({
							clientId: obj.clientId,
							value,
							displayFormat: obj.format
						})
					}
				})
			}
		}
		setClientBarcodes(matchingClients)
		setClientlessBarcode(noClientBarcode)
	}

	// when a client is selected:
	const handleClientClick = id => {
		const barcode = { ...clientlessBarcode }
		if (
			blockMultiClients &&
			barcodes.length > 1 &&
			barcodes[0].metas[0].clientId !== id
		) {
			onDelete(clientlessBarcode.value)
			setClientlessBarcode(null)
			const { translations } = clients.find(c => c.id === id) || {}
			setBlockedBarcode({
				displayValue: formatDisplay(
					barcode.value,
					barcode.displayFormat
				),
				client: getTranslationValue(translations || [], id, 'name')
			})
		}
		// update clientId of clientless barcode
		barcode.metas[0].clientId = id
		const index = barcodes.findIndex(b => b.value === barcode.value)
		const clientBarcode = clientBarcodes.find(cb => cb.clientId === id)
		if (clientBarcode) {
			const { value, displayFormat } = clientBarcode
			barcode.value = value
			barcode.displayFormat = displayFormat
			barcode.metas[0].value = value
			barcode.metas[0].displayFormat = displayFormat
		}
		onUpdateByIndex(barcode, index)
		setClientlessBarcode(null)

		if (fieldTag === 'BARCODE') {
			getParcelInfo(barcode)
		}
	}

	const handleRemoveClick = () => {
		onDelete(clientlessBarcode.value)
		setClientlessBarcode(null)
	}

	const translateData = data => {
		const d = {}
		for (const [k, v] of Object.entries(data)) {
			d[k] = t(v)
		}
		return d
	}

	const clientNames = clients.reduce((acc, client) => {
		const { id, translations } = client
		acc[id] = getTranslationValue(translations, id, 'name')
		return acc
	}, {})

	let notifIcon
	switch (notifType) {
		case 'danger':
			notifIcon = closeCircle
			break
		case 'warning':
			notifIcon = warning
			break
		default:
			notifIcon = checkbox
	}

	const formatManualInputPreview = (displayFormats, inputId) => {
		let manualDisplayFormats = displayFormats
		const { clientId, pickedUp } = checkPickedUp(
			[cleanClientRef(inputId)],
			true
		)
		if (pickedUp && clientId) {
			manualDisplayFormats = []
			const filteredValueFormats = valueFormats.filter(
				i => i.clientId === clientId
			)
			filteredValueFormats.forEach(({ format }) => {
				if (format && !manualDisplayFormats.includes(format)) {
					manualDisplayFormats.push(format)
				}
			})
		}
		return findFormatAndDisplay(manualDisplayFormats, inputId)
	}

	return (
		<div style={{ ...(isHidden && { display: 'none' }) }}>
			<ErrorDisplay
				isOpen={!!error}
				blocking={false}
				message={error && error.message}
				duration={5}
				onDismiss={handleErrorDismissed}
			/>
			<Dialog
				isOpen={isDialogOpen}
				header={dialogMessage}
				message={
					dialogObj && formatDisplay(dialogObj.id, dialogObj.format)
				}
				onNo={closeDialog}
				onYes={confirmDialog}
				onDismiss={closeDialog}
				hasOptions={dialogType === 'DELETE'}
			/>

			{/* client selection modal: */}
			<CustomIonModal
				isOpen={!!clientlessBarcode}
				backdropDismiss={false}
				animated={false}
			>
				<CustomContent>
					<ModalCloseButton
						shape="round"
						onClick={handleRemoveClick}
						className={`gtm-btn-client-select-close-${formTag}.${fieldTag}`}
					>
						<IonIcon icon={close}></IonIcon>
					</ModalCloseButton>
					<br />
					<p style={{ textAlign: 'center' }}>
						{t('Form.label_select_client')}:
					</p>
					<ButtonGrid
						list={clients.filter(c =>
							clientBarcodes.some(cb => cb.clientId === c.id)
						)}
						onClick={handleClientClick}
					/>
					<GridSeparator />
					<ButtonGrid
						list={clients.filter(
							c =>
								!clientBarcodes.some(cb => cb.clientId === c.id)
						)}
						onClick={handleClientClick}
					/>
				</CustomContent>
			</CustomIonModal>

			<Dialog
				isOpen={!!blockedBarcode}
				header={t('Form.message_client_mismatch')}
				message={
					blockedBarcode
						? `${blockedBarcode.displayValue} [${blockedBarcode.client}]`
						: ''
				}
				onOk={() => setBlockedBarcode(null)}
				onDismiss={() => setBlockedBarcode(null)}
				hasOptions={false}
			/>

			<HeadingContainer id={fieldId}>
				<Heading label={label} isRequired={isRequired} />
				{!forSummary && (
					<Heading
						label={`${t('Form.label_only_number_item')}: `}
						value={barcodes.length}
					/>
				)}
			</HeadingContainer>
			{isNonBarcodeField && (
				<Button
					expand="block"
					onClick={onNonBarcodeSelectButtonClick}
					id={`${fieldId}-button-nonbarcode-select`}
				>
					{t('Form.button_nonbarcode_select')}
				</Button>
			)}
			<Button
				expand="block"
				onClick={handleClickStartScan}
				id={`${fieldId}-button-scan`}
				className={`gtm-btn-scanner-open-${formTag}.${fieldTag}`}
				disabled={barcodeInputDisabled}
			>
				{t(acceptQROnly ? 'Form.button_scan_qr' : 'Form.button_scan')}
			</Button>
			{showScanners && (
				<VideoWrapper id={`${fieldId}-viewport`} show={showScanners}>
					<Video id={`${fieldId}-video`} />
					<canvas id={`${fieldId}-canvas`} />
					{REACT_APP_ENABLE_LLMP_224_DETECTION_AREA === 'true' && (
						<>
							<TopBlind />
							<RightBlind />
							<BottomBlind />
							<LeftBlind />
							<DetectionArea />
						</>
					)}
					<VideoOverlay>
						{isNotifOpen && (
							<Notification type={notifType}>
								<NotifIcon icon={notifIcon} size="large" />
								{notifMessage}
							</Notification>
						)}
						<ButtonContainer>
							<SwitchCameraButton
								shape="round"
								size="large"
								onClick={switchCamera}
								className={`gtm-btn-scanner-close-${formTag}.${fieldTag}`}
							>
								<IonIcon icon={reverseCamera}></IonIcon>
							</SwitchCameraButton>
							<CloseButton
								shape="round"
								size="large"
								onClick={handleClickClose}
								className={`gtm-btn-scanner-close-${formTag}.${fieldTag}`}
							>
								<IonIcon icon={close}></IonIcon>
							</CloseButton>
						</ButtonContainer>
					</VideoOverlay>
					{hasTorch && (
						<TorchButton
							shape="round"
							size="large"
							onClick={toggleTorch}
							className={`gtm-btn-scanner-torch-${formTag}.${fieldTag}`}
						>
							<IonIcon
								icon={isTorchOn ? flash : flashOff}
							></IonIcon>
						</TorchButton>
					)}
				</VideoWrapper>
			)}
			{!readOnly && !forSummary && (
				<>
					<InputWrapper lines="none" error={inputError.key}>
						<IonInput
							clearInput
							type="text"
							inputmode={inputMode}
							ref={inputRef}
							placeholder={t('Form.label_enter_item', {
								item: label
							})}
							maxlength={CLIENT_REF_LIMIT}
							value={inputId}
							onIonChange={handleInputChange}
							onKeyPress={handleInputKeyPress}
							color={inputError.key ? 'danger' : undefined}
							id={`${formGroup}-BARCODE-input`}
							className={`gtm-input-${formTag}.${fieldTag}`}
							disabled={
								(fieldTag === 'ORDER_NO' &&
									barcodes.length >= 1) ||
								barcodeInputDisabled
							}
						></IonInput>
					</InputWrapper>
					{inputId && (
						<InputPreview hasError={inputError.key}>
							{formatManualInputPreview(displayFormats, inputId)}
						</InputPreview>
					)}
					{inputError.key && (
						<InputError>
							{t(
								inputError.key,
								translateData(inputError.data || {})
							)}
						</InputError>
					)}
					<ButtonGroup>
						<Button
							onClick={toggleInputMode}
							className={`gtm-btn-inputmode-${formTag}.${fieldTag}`}
						>
							<img
								src="assets/icon/keyboard.svg"
								width="32"
								alt="keyboard"
							/>
						</Button>
						<Button
							onClick={handleClickAdd}
							disabled={
								addButtonDisabled ||
								(fieldTag === 'ORDER_NO' &&
									barcodes.length >= 1)
							}
							id={`${formGroup}-BARCODE-button-add`}
							className={`gtm-btn-add-${formTag}.${fieldTag}`}
						>
							{addButtonText}
						</Button>
					</ButtonGroup>
				</>
			)}
			<List>
				{!forSummary &&
					barcodes
						// new parcel IDs should be added to the end of array in data
						// reverse here just for display
						.slice()
						.reverse()
						.map(barcode => (
							<Item
								id={barcode.value}
								clientId={barcode.metas[0].clientId}
								clientName={
									clientNames[barcode.metas[0].clientId]
								}
								orderId={barcode.metas[0].orderId}
								onClickNoClient={handleClickNoClient}
								onClickDelete={handleDelete}
								key={barcode.value}
								displayFormat={barcode.displayFormat}
								readOnly={readOnly}
								showSubInfo={
									!forPickup && fieldTag === 'BARCODE'
								}
								noPickup={!forPickup && barcode.noPickup}
								simplified={forSummary}
							></Item>
						))}
			</List>
			{barcodeInputDisabled && (
				<MessageToast
					type="WARNING"
					message={'Cannot submit more than 50 parcels at a time'}
					buttonText={t('Common.button_close')}
					sticky
				/>
			)}
		</div>
	)
}

Barcode.defaultProps = {
	clients: [],
	barcodes: [], // array of barcodes
	onAdd: noop,
	onUpdate: noop,
	onUpdateByIndex: noop,
	onDelete: noop,
	onClear: noop,
	onClose: noop,
	onQRDetected: noop,
	selectCamera: noop,
	acceptQROnly: false,
	label: 'Barcode',
	isRequired: false,
	valueFormats: [],
	defaultInputMode: 'TEXT',
	fieldId: '',
	scanWindow: parseInt(REACT_APP_DEFAULT_SCAN_WINDOW_MS),
	submitting: false,
	readOnly: false,
	isHidden: false,
	blockMultiClients: false,
	forPickup: false,
	forSummary: false,
	isNonBarcodeField: false,
	summaryParcelsObject: {}
}

const mapStateToProps = state => ({
	cameras: getCameras(state),
	selectedCamera: getSelectedCamera(state),
	error: makeErrorSelector(['OTHER'])(state)
})

export default connect(mapStateToProps, dispatch => ({
	dispatchSelectCamera: (camera, cameraLabel) =>
		dispatch(selectCamera(camera, cameraLabel)),
	dispatchSetCameras: cameras => dispatch(setCameras(cameras)),
	otherError,
	dismissError
}))(Barcode)
