import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonSpinner
} from '@ionic/react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import Button from 'components/Button'
import Header from 'components/Header'
import { danger, medium, silver } from 'styles/colors'
import { getRoles, getUserId, parseUrlParams, setFocus } from 'utils/helpers'
import log from 'utils/log'
import storage, {
	LAST_PAGE,
	LOGIN_ID,
	TOKEN,
	FORM_VALUES,
	SUMMARY,
	SUBMIT_ATTEMPTS,
	PHONE
} from 'utils/storage'
import { pushEvent } from 'utils/tracking'
import axiosApiClient from 'api/axiosApiClient'

const CODE_LENGTH = 6

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1em;
`

const InputWrapper = styled(IonItem)`
	margin: 0.5em 0;
	border: 1px solid ${({ error }) => (error ? danger : silver)};
	/* text-align: center; */
	font-size: 38px;
	span {
		color: ${medium};
	}

	--min-height: 38px;
	--inner-padding-end: 0.25em;
`

const InputError = styled.div`
	margin: -1em 0 1em 0;
	color: ${danger};
`

const StyledButton = styled(Button)`
	margin: 1.5em 0;
`

const Verification = ({ history, location }) => {
	const { t } = useTranslation()
	const [code, setCode] = useState('')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)

	const codeInputRef = useRef()
	useEffect(() => {
		if (codeInputRef.current) {
			setTimeout(() => {
				setFocus(codeInputRef)
			}, 200)
		}
	}, [codeInputRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleCodeChange = e => {
		const code = e.target.value
		if (code.length > 6) {
			return
		}
		setCode(code)
		setError('')
		if (code.length === CODE_LENGTH) {
			submitVerficationCode(code)
		}
	}

	const handleCancelClick = () => {
		setCode('')
		history.push('/login')
	}

	const submitVerficationCode = async code => {
		const { id } = parseUrlParams(location.search)
		const data = { code }
		setLoading(true)
		try {
			const response = await axiosApiClient.patch(`login/${id}`, data)
			const { token } = response.data
			clearData(id)
			storage.setItem(LOGIN_ID, id)
			storage.setItem(TOKEN, token)
			axiosApiClient.defaults.headers.common.Authorization = `Bearer ${token}`
			const { referrer } = location.state
			const lastPage = storage.getItem(LAST_PAGE)
			let goto = '/'
			if (referrer && !referrer.includes('login')) {
				goto = referrer
			} else if (lastPage && !lastPage.includes('login')) {
				goto = lastPage
			}

			//tracking:
			Sentry.setUser({
				id: getUserId(),
				roles: getRoles(),
				// phone only available in PWA login
				phone: storage.getItem(PHONE),
				// for search in Sentry
				email: storage.getItem(PHONE)
			})
			let tags = []
			try {
				const { data: self } = await axiosApiClient.get('users/self')
				tags = self.tags
				log.changeClientId(self.id.toString())
			} catch (err) {
				log.error(
					'Failed to fetch user tags',
					{ category: 'API' },
					err.stack
				)
			}
			pushEvent('Login', { tags })

			window.location.replace(goto)
		} catch (error) {
			setError(t('Login.error_verification'))
		}
		setLoading(false)
	}

	const clearData = id => {
		const prevId = storage.getItem(LOGIN_ID)
		if (prevId && prevId !== id) {
			storage.removeItem(SUMMARY)
			storage.removeItem(FORM_VALUES)
			storage.removeItem(SUBMIT_ATTEMPTS)
		}
	}

	const calculateTimeLeft = () => {
		const { expireAt } = parseUrlParams(location.search)
		const difference = expireAt - moment().utc().valueOf()
		let timeLeft = {}
		if (difference > 0) {
			timeLeft = {
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			}
		}
		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)
		return () => clearTimeout(timer)
	})

	const timerComponents = []
	Object.keys(timeLeft).forEach(interval => {
		if (!timeLeft[interval]) {
			return
		}
		timerComponents.push(
			<span key={interval}>
				{' '}
				{timeLeft[interval]} {interval}{' '}
			</span>
		)
	})

	const phone = storage.getItem(PHONE)
	const { reference } = parseUrlParams(location.search)

	return (
		<IonPage>
			<Header title={t('Login.title')} hideMenu />
			<IonContent>
				<Container>
					<form onSubmit={e => e.preventDefault()}>
						<IonLabel>
							{t('Login.label_verification_code', { phone })}
						</IonLabel>
						<br />
						<br />
						{/* <IonLabel>{t('Login.label_reference')}: {reference}</IonLabel><br/> */}
						<IonLabel>
							{!!timerComponents.length && (
								<span>
									Code will expire in {timerComponents}
								</span>
							)}
						</IonLabel>
						<IonLabel color="danger">
							{!timerComponents.length && (
								<span>Code expired. Please try again.</span>
							)}
						</IonLabel>
						<InputWrapper lines="none" error={error}>
							{reference && <span>{reference}-</span>}
							<IonInput
								type="number"
								inputmode="numeric"
								maxlength={CODE_LENGTH}
								autofocus
								ref={codeInputRef}
								clearInput
								value={code}
								onIonChange={handleCodeChange}
								color={error ? 'danger' : undefined}
								id="verification-input-code"
								className="gtm-input-verification-code"
							/>
						</InputWrapper>
						{error && <InputError>{error}</InputError>}
					</form>
					<StyledButton
						expand="block"
						disabled={loading}
						onClick={handleCancelClick}
						className="gtm-btn-verification-cancel"
					>
						{loading ? (
							<IonSpinner />
						) : (
							t('Login.button_cancel_try_again')
						)}
					</StyledButton>
				</Container>
			</IonContent>
		</IonPage>
	)
}

export default withRouter(Verification)
