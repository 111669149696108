import React from 'react'
import { IonToast } from '@ionic/react'
import { noop } from 'utils/helpers'

const Toast = ({ duration, isOpen, message, onDismiss, requestId }) => {
	return (
		<IonToast
			animated={false}
			position="top"
			color="danger"
			isOpen={isOpen}
			header={message}
			message={requestId && `Request ID: ${requestId}`}
			showCloseButton
			duration={duration}
			onDidDismiss={onDismiss}
		/>
	)
}

Toast.defaultProps = {
	duration: 0,
	isOpen: false,
	message: '',
	onDismiss: noop,
	requestId: ''
}

export default Toast
