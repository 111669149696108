// local storage keys:
export const LAST_PAGE = 'lastVisitedPage'
export const CLIENTS = 'clients'
export const LOCATION = 'location' // current location
export const LOCATIONS = 'locations' // all locations
export const TIMEZONE = 'timezone' // all locations
export const SHIFT_REGISTRATION_CUTOFF_AT = 'shiftRegistrationCutoffAt'
export const LOCALE = 'locale'
export const LOGIN_ID = 'loginId'
export const TOKEN = 'token'
export const FORM_MODELS = 'formModels'
export const FORM_VALUES = 'formValues'
export const UPLOAD_ATTEMPTS = 'uploadAttempts' // photo uploads
export const SUBMIT_ATTEMPTS = 'submitAttempts' // form submissions
export const DISPLAY_FORMATS = 'displayFormats'
export const SELECTED_CAMERA = 'selectedCamera'
export const SELECTED_CAMERA_LABEL = 'selectedCameraLabel'
export const CROSSDOCKS = 'crossDocks'
export const SUMMARY = 'summary'
export const SUMMARY_QUICK_ACTIONS = 'summaryQuickActions'
export const MENU_CONFIG_CACHE = 'menuConfigCache'
export const PHONE = 'phone' // current phone
export const USER_ID = 'userId'
export const ZOOM_SCALE = 'zoomScale'
export const REGIONS = 'regions'
export const CURRENT_REGION = 'currentRegion'
export const DATA_CENTER = 'dataCenter'
export const IS_DRIVER_MODE = 'isDriverMode'
export const DRIVER_USER_ID = 'driverUserId'
export const DRIVER_ROLES = 'driverRoles'

export class Storage {
	constructor() {
		this._tempStorage = {}
		// for debugging:
		window._tempStorage = this._tempStorage // eslint-disable-line no-underscore-dangle
	}

	static isSupported() {
		try {
			const TEST_KEY = 'LLMP_COURIER_TEST_KEY'
			window.localStorage.setItem(TEST_KEY, '')
			window.localStorage.removeItem(TEST_KEY)
			return true
		} catch (e) {
			return false
		}
	}

	key(index) {
		if (Storage.isSupported()) {
			return window.localStorage.key(index)
		}
		return Object.keys(this._tempStorage)[index]
	}

	getItem(name) {
		if (Storage.isSupported()) {
			return window.localStorage.getItem(name)
		}
		return this._tempStorage[name] || null
	}

	setItem(name, value = '') {
		if (Storage.isSupported()) {
			window.localStorage.setItem(name, value)
		} else {
			this._tempStorage[name] = String(value)
		}
	}

	removeItem(name) {
		if (Storage.isSupported()) {
			window.localStorage.removeItem(name)
		} else {
			delete this._tempStorage[name]
		}
	}

	clear() {
		if (Storage.isSupported()) {
			window.localStorage.clear()
		} else {
			this._tempStorage = {}
		}
	}
}

export default new Storage()
