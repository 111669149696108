import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
	IonContent,
	IonPage,
	IonIcon,
	IonSelect,
	IonSelectOption
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'
import { reverseCamera, logOut } from 'ionicons/icons'
import { changeLocale, noop, clearCacheData } from 'utils/helpers'
import Header from 'components/Header'
import Button, { TertiaryButton, SecondaryButton } from 'components/Button'
import { dark } from 'styles/colors'
import ZoomScaleArray from 'styles/zoomScale'
import axiosApiClient from 'api/axiosApiClient'
import {
	getCameras,
	getSelectedCamera,
	getSelectedCameraLabel,
	selectCamera,
	setCameras
} from 'redux/config'
import storage, {
	LOCATION,
	LOCATIONS,
	SELECTED_CAMERA,
	SELECTED_CAMERA_LABEL,
	ZOOM_SCALE,
	DATA_CENTER
} from 'utils/storage'
import log from 'utils/log'
import { featureFlags } from 'config'
import { getDriverAppMode } from 'redux/config'
import { logout } from 'utils/helpers'
import { driverWebviewSdk } from 'utils/driverApp'
import MessageToast from 'components/Form/MessageToast'
import { setPendingParcels } from 'redux/summary'

const MainContainer = styled.div`
	margin: 24px 12px;
`
const StyledButton = styled(Button)`
	margin: 4px 0;
	label {
		margin: 0.5em;
	}
`

const StyledTertiaryButton = styled(TertiaryButton)`
	margin-top: 32px;

	label {
		margin: 0.5em;
	}
`

const StyledLogoutButton = styled(SecondaryButton)`
	margin-top: 12px;
	---webkit-box-shadow: none;
	---moz-box-shadow: none;
	--box-shadow: none;

	--background-hover: transparent;

	label {
		margin: 0.5em;
		color: red;
	}
`
const MutedLabel = styled.div`
	> div:first-child {
		font-size: 14px;
		font-weight: bold;
	}
`

const UserInfoContainer = styled.div`
	margin-bottom: 24px;
	line-height: 1.3;
`

const UserInfoHeader = styled.div`
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 4px;
`

const StyledIonSelect = styled(IonSelect)`
	font-size: 14px;
	border: 1px solid #ccc;
	padding: 8px 12px;
`

const Settings = ({
	cameras,
	selectedCamera,
	selectedCameraLabel,
	selectCamera,
	setCameras,
	setPendingParcels
}) => {
	const { i18n, t } = useTranslation()
	const [locales, setLocales] = useState([])
	const [userInfo, setUserInfo] = useState({
		aliases: [],
		info: { name: '' }
	})
	const [isLoadingLang, setIsLoadingLang] = useState(true)
	const [disableSwitchCamera, setDisableSwitchCamera] = useState(true)
	const [zoomScale, setZoomScale] = useState(
		storage.getItem(ZOOM_SCALE) || '1'
	)
	const [showCacheMessageToast, setShowCacheMessageToast] = useState(false)
	const isDriverMode = useSelector(getDriverAppMode)

	const currentDataCenter = storage.getItem(DATA_CENTER)
	const { REACT_APP_BR_TELEGRAM_BOT_URL, REACT_APP_SG_TELEGRAM_BOT_URL } =
		process.env
	const GlobalStyle = createGlobalStyle`
		body {
			zoom: ${({ zoomScale }) => zoomScale || 1};
		}
	`
	const enumerateDevices = async () => {
		try {
			if (!navigator.mediaDevices) {
				return
			}
			// enumerate media devices:
			const devices = await navigator.mediaDevices.enumerateDevices()
			log.info('media devices', devices)
			const cameras = []
			devices.forEach(device => {
				// only get video input devices i.e. camera
				if (
					device.kind === 'videoinput' &&
					(device.deviceId || device.id)
				) {
					cameras.push({
						id: device.deviceId || device.id,
						label: device.label
					})
				}
			})
			log.info('video device ids', cameras)
			setCameras(cameras) // store them

			// get previously selected camera:
			const currentCamera = storage.getItem(SELECTED_CAMERA)
			const currentCameraLabel = storage.getItem(SELECTED_CAMERA_LABEL)
			if (currentCamera) {
				selectCamera(currentCamera, currentCameraLabel)
			}
		} catch (e) {
			log.error('Failed to get video input devices', null, e.stack)
		}
	}
	useEffect(() => {
		const location = storage.getItem(LOCATION)
		const locationsData = JSON.parse(storage.getItem(LOCATIONS))
		if (location && locationsData) {
			const localesData = locationsData.find(
				l => l.id === location
			).locales
			setLocales(localesData)
		}
		setIsLoadingLang(false)
		const getUserInfo = async () => {
			const { data: self } = await axiosApiClient.get('users/self')
			setUserInfo(self)
		}
		getUserInfo()
		enumerateDevices()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setDisableSwitchCamera(cameras.length < 2)
	}, [cameras, selectedCamera]) // eslint-disable-line react-hooks/exhaustive-deps

	const isLinkedTelegram = userInfo =>
		userInfo.aliases.some(info => info.type === 'TELEGRAM')

	const renderMultilingual = (translations, currentLang) => {
		if (translations.length === 1) {
			return translations[0].value
		}
		return translations.reduce((acc, item) => {
			const mainCon = item.locale === currentLang
			if (mainCon) {
				return `${item.value}${acc}`
			}
			return `${acc}/${item.value}`
		}, '')
	}

	// cylce through the available cameras:
	const changeCamera = () => {
		let index = 0
		if (selectedCamera) {
			index = cameras.findIndex(camera => camera.id === selectedCamera)
			if (index === cameras.length - 1) {
				index = 0
			} else {
				index += 1
			}
		}
		selectCamera(cameras[index].id, cameras[index].label)
		storage.setItem(SELECTED_CAMERA, cameras[index].id)
		storage.setItem(SELECTED_CAMERA_LABEL, cameras[index].label)
	}

	const updateLanguage = async lang => {
		if (lang !== i18n.language) {
			setIsLoadingLang(true)
			await changeLocale(lang)
			setIsLoadingLang(false)
		}
	}

	const handleClearCacheData = () => {
		clearCacheData()
		setPendingParcels([])
		setShowCacheMessageToast(true)
	}

	const changeZoomScale = async val => {
		await setZoomScale(val)
		await storage.setItem(ZOOM_SCALE, val)
	}

	const handleSwitchApp = () => {
		driverWebviewSdk.switchDeliveryMode('core')
	}

	const handleLogOut = () => {
		logout()
	}

	useEffect(() => {
		let timeoutId
		if (showCacheMessageToast) {
			timeoutId = setTimeout(() => setShowCacheMessageToast(false), 3000)
		}
		return () => clearTimeout(timeoutId)
	}, [showCacheMessageToast])

	return (
		<IonPage id="formpage">
			<Header title={t('SETTINGS.title')} hideMenu={false} />
			{showCacheMessageToast &&
				featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
					<MessageToast
						type="SUCCESS"
						message={t('SETTINGS.clear_cache_toast_message')}
					/>
				)}
			<IonContent>
				<MainContainer>
					<UserInfoContainer>
						<UserInfoHeader>
							{t('SETTINGS.driver_name')}
						</UserInfoHeader>
						{userInfo.info.name}
					</UserInfoContainer>
					<UserInfoContainer>
						<UserInfoHeader>{t('SETTINGS.alias')}</UserInfoHeader>
						{userInfo.aliases
							.sort((a, b) => (a.type > b.type ? 1 : -1))
							.map(alias => {
								switch (alias.type) {
									case 'PHONE':
										return (
											<div key={alias.id}>
												- {alias.id}
											</div>
										)
									case 'TELEGRAM':
										return !featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE ? (
											<div key={alias.id}>
												-{' '}
												{t('SETTINGS.telegram_linked')}
											</div>
										) : (
											''
										)
									default:
										return ''
								}
							})}
					</UserInfoContainer>
					{!isLoadingLang && (
						<>
							<UserInfoContainer>
								{featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
									<UserInfoContainer>
										<UserInfoHeader>
											<MutedLabel>
												{t(
													'SETTINGS.telegram_section_title'
												)}
											</MutedLabel>
										</UserInfoHeader>
										{!isLinkedTelegram(userInfo) ? (
											<StyledButton
												expand="block"
												href={
													currentDataCenter === 'sg'
														? REACT_APP_SG_TELEGRAM_BOT_URL
														: REACT_APP_BR_TELEGRAM_BOT_URL
												}
												target="_blank"
											>
												<label>
													{t(
														'SETTINGS.link_telegram'
													)}
												</label>
											</StyledButton>
										) : (
											<div>
												{t('SETTINGS.telegram_linked')}
											</div>
										)}
									</UserInfoContainer>
								)}
								<UserInfoHeader>
									{t('SETTINGS.language')}
								</UserInfoHeader>
								<StyledIonSelect
									value={i18n.language}
									onIonChange={e => {
										updateLanguage(e.detail.value)
									}}
								>
									{locales.map(({ id, translations }) => (
										<IonSelectOption value={id} key={id}>
											{renderMultilingual(
												translations,
												i18n.language
											)}
										</IonSelectOption>
									))}
								</StyledIonSelect>
							</UserInfoContainer>

							<UserInfoContainer>
								<UserInfoHeader>
									{t('SETTINGS.zoom_scale.text')}
								</UserInfoHeader>
								<StyledIonSelect
									value={zoomScale}
									onIonChange={e => {
										changeZoomScale(e.detail.value)
									}}
								>
									{ZoomScaleArray.map(
										({ text, value }, i) => (
											<IonSelectOption
												value={value}
												key={'zoom' + i}
											>
												{t(text)}
											</IonSelectOption>
										)
									)}
								</StyledIonSelect>
							</UserInfoContainer>
						</>
					)}
					<UserInfoContainer>
						{!featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
							<>
								<MutedLabel>
									<div>{t('Form.label_camera')}:</div>
									<div>
										{selectedCameraLabel ||
											'Default Camera'}
									</div>
								</MutedLabel>
								<StyledButton
									expand="block"
									disabled={disableSwitchCamera}
									onClick={changeCamera}
									className="gtm-btn-change-camera"
								>
									<IonIcon icon={reverseCamera}></IonIcon>
									<label>
										{t('Form.button_change_camera')}
									</label>
								</StyledButton>
							</>
						)}
						{featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
							<>
								<MutedLabel>
									<UserInfoHeader>
										{t('SETTINGS.clear_cache_title')}
									</UserInfoHeader>
								</MutedLabel>
								<StyledButton
									expand="block"
									onClick={handleClearCacheData}
								>
									<label>
										{t('SETTINGS.clear_cache_btn_title')}
									</label>
								</StyledButton>
								{isDriverMode ? (
									<>
										<StyledTertiaryButton
											expand="block"
											onClick={handleSwitchApp}
										>
											<label>
												{t(
													'SETTINGS.switch_platform_button_title'
												)}
											</label>
										</StyledTertiaryButton>
									</>
								) : (
									<>
										<StyledTertiaryButton
											expand="block"
											href="https://lalamove.page.link/sea_plus_courier"
										>
											<label>
												{t(
													'SETTINGS.open_lalamove_button_title'
												)}
											</label>
										</StyledTertiaryButton>
									</>
								)}
								<StyledLogoutButton
									expand="block"
									onClick={handleLogOut}
								>
									<IonIcon
										icon={logOut}
										color="danger"
									></IonIcon>
									<label>{t('Common.button_logout')}</label>
								</StyledLogoutButton>
							</>
						)}
					</UserInfoContainer>
				</MainContainer>
			</IonContent>
			<GlobalStyle zoomScale={zoomScale} />
		</IonPage>
	)
}

Header.defaultProps = {
	title: '',
	icon: '',
	onClickTitle: noop,
	bgColor: dark,
	cameras: [],
	setCameras: noop,
	selectCamera: noop,
	setPendingParcels: noop
}

const mapStateToProps = state => ({
	cameras: getCameras(state),
	selectedCamera: getSelectedCamera(state),
	selectedCameraLabel: getSelectedCameraLabel(state)
})

export default connect(mapStateToProps, {
	setCameras,
	selectCamera,
	setPendingParcels
})(Settings)
