import { useSelector } from 'react-redux'
import { crossDockParcelFilter, FILTERS } from 'utils/helpers'
import { PARCEL_STATUS, SUMMARY_TAB } from 'utils/constants'
import { useState, useEffect } from 'react'
import _ from 'assets/lodash.min'
import { getCancelledParcelTab } from 'utils/summary'

/** @typedef {import("api/summaries").DeliveryItem} DeliveryItem */

/** @type {function(): Record<keyof SUMMARY_TAB, number>} */
export const createDefaultGroupedCount = () => ({
	[SUMMARY_TAB.TO_PICK_UP]: 0,
	[SUMMARY_TAB.IN_THE_VEHICLE]: 0,
	[SUMMARY_TAB.OFFLOADED]: 0
})

/** @type {function(): Record<keyof PARCEL_STATUS, number>} */
export const createDefaultStatusCount = () => ({
	[PARCEL_STATUS.AWAITS_PICKUP]: 0,
	[PARCEL_STATUS.CANCELED]: 0,
	[PARCEL_STATUS.IN_PROGRESS]: 0,
	[PARCEL_STATUS.IN_THE_VEHICLE_CANCELED]: 0,
	[PARCEL_STATUS.FAILED_PICKUP]: 0,
	[PARCEL_STATUS.RESCHEDULED]: 0,
	[PARCEL_STATUS.FAILED]: 0,
	[PARCEL_STATUS.DELIVERED]: 0,
	[PARCEL_STATUS.RETURNED]: 0,
	[PARCEL_STATUS.CROSSDOCKED]: 0,
	[PARCEL_STATUS.PENDING]: 0,
	[PARCEL_STATUS.REJECTED]: 0,
	[PARCEL_STATUS.APPROVED]: 0
})

export const useSummaryGroupedByBarcodeParcels = () => {
	/** @type {DeliveryItem} */
	const apiEndStateParcels = useSelector(
		state => state.summary.apiEndStateParcels
	)
	/** @type {DeliveryItem} */
	const apiParcels = useSelector(state => state.summary.apiParcels)
	/** @type {DeliveryItem} */
	const crossdockParcels = useSelector(
		state => state.summary.crossdockParcels
	)
	/** @type {[DeliveryItem[], React.Dispatch<SetStateAction<DeliveryItem[]>>]} */
	const [parcels, setParcels] = useState({})

	useEffect(() => {
		setParcels(
			_.mapValues(
				_.groupBy(
					[...apiEndStateParcels, ...apiParcels, ...crossdockParcels],
					'id'
				),
				_.head
			)
		)
	}, [apiEndStateParcels, apiParcels, crossdockParcels])

	return parcels
}

export const useSummaryAggregatedCount = () => {
	const tab = useSelector(state => state.summary.tab)
	/** @type {DeliveryItem[]} */
	const apiParcels = useSelector(state => state.summary.apiParcels)
	/** @type {DeliveryItem[]} */
	const apiEndStateParcels = useSelector(
		state => state.summary.apiEndStateParcels
	)
	/** @type {{ updatedAt: Date, data: { BARCODE: string, DOCKID: string }[] }} */
	const crossdockParcelsMetadataList = useSelector(
		state => state.summary.crossdockParcelsMetadata.list
	)
	/** @type {[Record<'TO_PICK_UP' | 'IN_THE_VEHICLE' | 'OFFLOADED', number>, React.Dispatch<SetStateAction<Record<'TO_PICK_UP' | 'IN_THE_VEHICLE' | 'OFFLOADED', number>>>]} */
	const [groupedCount, setGroupedCount] = useState(createDefaultGroupedCount)
	/** @type {[Record<keyof PARCEL_STATUS, number>, React.Dispatch<SetStateAction<Record<keyof PARCEL_STATUS, number>>>]} */
	const [statusCount, setStatusCount] = useState(createDefaultStatusCount)
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const allParcels = [...apiEndStateParcels, ...apiParcels]
		const currentGroupedCount = createDefaultGroupedCount()
		for (const key of Object.keys(FILTERS)) {
			const statusSet = FILTERS[key]
			const normalParcelsCount = allParcels.filter(parcel => {
				if (parcel.status === PARCEL_STATUS.CANCELED) {
					return getCancelledParcelTab(parcel) === key
				}
				return statusSet.includes(parcel.status)
			}).length

			const crossDockParcelsCount =
				crossdockParcelsMetadataList?.filter(cd =>
					crossDockParcelFilter(cd, key)
				).length || 0

			currentGroupedCount[key] =
				normalParcelsCount + crossDockParcelsCount
		}

		const currentStatusCount = createDefaultStatusCount()
		/** @type {[string, DeliveryItem[]][]} */
		const groupedParcels = _.toPairs(_.groupBy(allParcels, 'status'))
		for (const [status, parcels] of groupedParcels) {
			if (status === PARCEL_STATUS.CANCELED) {
				currentStatusCount[status] = parcels.filter(parcel => {
					return getCancelledParcelTab(parcel) === tab
				}).length
			} else {
				currentStatusCount[status] = parcels.length
			}
		}
		setGroupedCount(currentGroupedCount)
		setStatusCount(currentStatusCount)
		setTotal(
			allParcels.length +
				(crossdockParcelsMetadataList?.filter(parcel => {
					// filter out all approved cross dock parcels that its updatedAt(submittedAt) is not current date
					if (parcel.data?.DOCKSTATUS === PARCEL_STATUS.APPROVED) {
						return (
							new Date().toDateString() ===
							new Date(parcel.submittedAt).toDateString()
						)
					}
					return true
				}).length || 0)
		)
	}, [tab, apiEndStateParcels, apiParcels, crossdockParcelsMetadataList])

	return { total, groupedCount, statusCount }
}
