import { initDeviceMetaData } from 'utils/helpers'

// 1. Action types:
export const UPDATE_LOCATION = 'UPDATE_LOCATION'

// 2. Action creators:
export const updateLocation = coords => ({
	type: UPDATE_LOCATION,
	coords
})

// 3. Initial states:
const initState = initDeviceMetaData()
// e.g.:
// app: 'courier',
// ver: '1.3.1',
// browser: 'Chrome'
// standalone: true,
// os: 'Linux; Android 10; ONEPLUS A6013',
// lat: 22.335282,
// lng: 114.176100,

// 4. Selectors:
export const getMeta = state => state.meta

export const getLocation = state => ({
	lat: state.meta.lat,
	lng: state.meta.lng
})

// 5. Reducers:
export default function reducer(state = initState, { type, coords }) {
	switch (type) {
		case UPDATE_LOCATION:
			return { ...state, lat: coords.lat, lng: coords.lng }
		default:
			// return current state
			return state
	}
}
