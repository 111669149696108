import React, { useState } from 'react'
import styled from 'styled-components'
import {
	IonButton,
	IonButtons,
	IonIcon,
	IonItem,
	IonInput,
	IonSegment,
	IonSegmentButton
} from '@ionic/react'
import SharedHeader from 'components/Header'
import { useTranslation } from 'react-i18next'
import { getTranslationValue } from 'utils/helpers'
import Button from 'components/Button'
import Barcode from 'components/Form/Barcode'
import { barcode, close, search, funnel, swap } from 'ionicons/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setSummaryFilter, setSummaryTab } from 'redux/summary'
import {
	useSummaryAggregatedCount,
	useSummaryGroupedByBarcodeParcels
} from 'pages/Summary/hooks/aggregate'
import { useSummaryBarcodeAttributes } from 'pages/Summary/hooks/config'
import { white } from 'styles/colors'
import { monospaceFont } from 'styles/fonts'
import Heading from 'components/Form/Heading'
import { StatusFilter } from './StatusFilter'
import {
	RESET,
	initState as initialMessage,
	updateMessage
} from 'redux/message'
import MessageToast from 'components/Form/MessageToast'
import _ from 'assets/lodash.min'
import { trySubmitQueue } from 'containers/ConnectedForm'
import { DeliveryStagePopover } from './DeliveryStagePopover'
import { SortingPopover } from './SortingPopover'

/** @typedef {import("redux/summary").SummaryState} SummaryState */

const HeaderSectionStickyLayer = styled.div`
	position: fixed;
	top: 0;
	z-index: 1;
	width: 100%;
`

// hardcoded height for sticky summary header
const HeaderSectionContainer = styled.div`
	background: #f8f9fc;
	padding-top: ${prop => (prop.hasMessage ? '116px' : '56px')};
`

const HeaderSectionFilterContainer = styled.div`
	padding: 8px 0 0;
`

const HeaderButton = styled(IonButton)`
	&&& {
		width: 36px;
		height: 36px;
		--padding-top: 8px;
		--padding-end: 8px;
		--padding-bottom: 8px;
		--padding-start: 8px;
	}
`
const IconReorder = styled(IonIcon)`
	transform: rotate(90deg);
`

const SearchBar = styled.div`
	display: flex;
	align-items: center;
	> ion-item {
		flex: 1;
	}
	> ion-button {
		width: 38px;
		height: 38px;
		margin: 0 1em 0 0;
		--padding-start: 0;
		--padding-end: 0;
		> ion-icon {
			width: 32px;
			height: 32px;
		}
	}
`

const InputWrapper = styled(IonItem)`
	margin: 1em;
	border: 1px solid silver;
	border-radius: 0;

	--min-height: 38px;
	--padding-start: 0.5em;
	--inner-padding-end: 0.25em;
	--background: ${white};

	ion-input {
		margin: 0 0.2em;
	}
`

const SearchList = styled.ul`
	padding-left: 0;
	margin: 0;
	> li {
		list-style: none;
		&:before {
			content: '';
			display: inline-block;
			height: 24px;
			width: 24px;
			background-image: url('assets/icon/parcel.svg');
		}

		> span {
			margin-left: 0.5em;
			vertical-align: top;
			font-family: ${monospaceFont};
			font-size: 22px;
			word-break: break-all;
		}
	}
`

const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1em;
`

/** @type {React.NamedExoticComponent<{ translation: any }>} */
export const Header = React.memo(function SummaryHeader({ translation }) {
	const { t } = useTranslation()

	/** @type {[PopoverType, (type: PopoverType) => void]} */
	const [popoverType, setPopoverType] = useState(null)
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [showScanner, setShowScanner] = useState(false)
	/** @type {SummaryState["filter"]["text"]} */
	const searchText = useSelector(state => state.summary.filter.text)
	/** @type {SummaryState["tab"]} */
	const tab = useSelector(state => state.summary.tab)
	const message = useSelector(state => state.message || initialMessage)
	/** @type {SummaryState["filter"]["scannedBarCode"]} */
	const scannedBarCode = useSelector(
		state => state.summary.filter.scannedBarCode
	)
	/** @type {SummaryState["filter"]["deliveryStage"]} */
	const deliveryStage = useSelector(
		state => state.summary.filter.deliveryStage
	)
	/** @type {boolean} */
	const hasParcels = useSelector(
		state =>
			state.summary.apiParcels.length > 0 ||
			state.summary.apiEndStateParcels.length > 0 ||
			state.summary.crossdockParcels.length > 0
	)
	const { attributes, clients } = useSummaryBarcodeAttributes()
	const { groupedCount, total } = useSummaryAggregatedCount()
	const groupedByBarcodeParcels = useSummaryGroupedByBarcodeParcels()
	const dispatch = useDispatch()

	const setMessage = (type, config) => dispatch(updateMessage(type, config))
	const setSearchText = text => dispatch(setSummaryFilter({ text }))
	const setScannedBarCode = scannedBarCode =>
		dispatch(setSummaryFilter({ scannedBarCode }))

	const onSearchBarChange = e => {
		setSearchText(e.target.value.toUpperCase())
		setScannedBarCode([])
	}
	const onBarcodeChange = barcode => {
		setSearchText('')
		setScannedBarCode(_.uniqBy([...scannedBarCode, barcode], 'value'))
	}
	const onScannerIconClick = () => {
		setSearchText('')
		setShowSearchBar(false)
		setShowScanner(true)
	}
	const onTabChange = e => {
		dispatch(setSummaryTab(e.target.value))
		dispatch(setSummaryFilter({ statusTag: '' }))
	}
	const onClearButtonClick = () => {
		setScannedBarCode([])
		setSearchText('')
	}

	return (
		<>
			<HeaderSectionContainer hasMessage={message.variant}>
				<HeaderSectionStickyLayer>
					<SharedHeader
						title={`${getTranslationValue(
							translation,
							t('Summary.title')
						)} ×${total}`}
					>
						{hasParcels && (
							<IonButtons slot="end">
								<HeaderButton
									onClick={() => setPopoverType('sorting')}
								>
									<IconReorder
										slot="icon-only"
										color="light"
										icon={swap}
									/>
								</HeaderButton>
								<HeaderButton
									onClick={() =>
										setPopoverType('deliveryStage')
									}
								>
									<IonIcon
										slot="icon-only"
										color={
											deliveryStage === 'all'
												? 'light'
												: 'header_button_active'
										}
										icon={funnel.ios}
									/>
								</HeaderButton>
								<HeaderButton
									onClick={() =>
										setShowSearchBar(pre => !pre)
									}
								>
									<IonIcon
										slot="icon-only"
										color={
											searchText === ''
												? 'light'
												: 'header_button_active'
										}
										icon={search}
									/>
								</HeaderButton>
							</IonButtons>
						)}
					</SharedHeader>
					{message.variant && (
						<MessageToast
							type={message.variant}
							message={t(
								message.content.key,
								message.content.data
							)}
							buttonText={t(
								message.dismissable
									? 'Common.button_close'
									: 'Form.button_retry_now'
							)}
							buttonClick={
								message.dismissable
									? () => setMessage(RESET)
									: () =>
											trySubmitQueue(
												groupedByBarcodeParcels
											)
							}
							shouldShowButton={message.hasAction}
						/>
					)}
				</HeaderSectionStickyLayer>
				{showSearchBar && (
					<SearchBar>
						<InputWrapper lines="none" style={{ flex: 1 }}>
							<IonIcon icon={search}></IonIcon>
							<IonInput
								type="text"
								value={searchText}
								onIonChange={onSearchBarChange}
								placeholder={t('Summary.label_search')}
								inputmode="NUMERIC"
								className="gtm-input-summary-search"
							></IonInput>
							{/* cannot use clearInput because it would be hidden onBlur */}
							{searchText && (
								<IonIcon
									icon={close}
									onClick={() => setSearchText('')}
								></IonIcon>
							)}
						</InputWrapper>
						{process.env
							.REACT_APP_ENABLE_LLMP_519_SUMMARY_SCAN_SEARCH ===
							'true' && (
							<Button
								color="dark"
								fill="clear"
								onClick={onScannerIconClick}
								className="gtm-btn-summary-scan-search"
							>
								<IonIcon icon={barcode}></IonIcon>
							</Button>
						)}
					</SearchBar>
				)}
				{showScanner && (
					<div style={{ padding: '0 1em' }}>
						<Barcode
							summaryParcelsObject={groupedByBarcodeParcels}
							clients={clients.current}
							barcodes={scannedBarCode}
							onAdd={onBarcodeChange}
							onClose={() => setShowScanner(false)}
							label={t('Summary.heading_scan_to_search')}
							scanWindow={attributes.current?.scanWindow}
							valueFormats={attributes.current?.valueFormats}
							defaultInputMode={
								attributes.current?.defaultInputMode
							}
							formGroup="SUMMARY"
							formTag="SUMMARY"
							fieldTag="BARCODE"
							fieldId="SEARCH"
							submitting={!showScanner} // when `submitting` value changes to true, the scanner is stopped..
							forSummary // moved this as prop to avoid multiple checks inside component
						/>
					</div>
				)}
				{scannedBarCode.length > 0 && (
					<div style={{ padding: '0 1em' }}>
						<h6 style={{ margin: '16px 0 10px' }}>
							{t('Summary.heading_scan_searched')}:
						</h6>
						<SearchList>
							{scannedBarCode.map(b => (
								<li key={b.value}>
									<span>{b.value}</span>
								</li>
							))}
						</SearchList>
					</div>
				)}
				{hasParcels && (
					<HeaderSectionFilterContainer>
						{!scannedBarCode.length && !searchText ? (
							<>
								<IonSegment
									color="dark"
									value={tab}
									onIonChange={onTabChange}
								>
									{Object.entries(groupedCount).map(
										([key, count]) => (
											<IonSegmentButton
												key={key}
												value={key}
												id={`${key}-button`}
												className={`gtm-btn-summary-tab-${key}`}
											>
												<div>
													{t(
														`Summary.label_${key.toLowerCase()}`
													)}
												</div>
												<div>×{count}</div>
											</IonSegmentButton>
										)
									)}
								</IonSegment>
								<StatusFilter />
							</>
						) : (
							<HeadingContainer>
								<Heading
									label={`${t(
										'Summary.heading_scan_results'
									)}:`}
								/>
								<Button
									size="small"
									onClick={onClearButtonClick}
								>
									{t('Common.button_clear')}
								</Button>
							</HeadingContainer>
						)}
					</HeaderSectionFilterContainer>
				)}
			</HeaderSectionContainer>
			{popoverType === 'sorting' && (
				<SortingPopover onClose={() => setPopoverType(null)} />
			)}
			{popoverType === 'deliveryStage' && (
				<DeliveryStagePopover onClose={() => setPopoverType(null)} />
			)}
		</>
	)
})
