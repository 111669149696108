/* eslint-disable no-console */
import { noop } from 'utils/helpers'

const log = {
	// fallback for ldev:
	changeLogLevel: noop,
	changeUrl: noop,
	changeLocation: noop,
	changeLocale: noop,
	changeClientId: noop,
	debug: (...args) => {
		console.debug(...args)
	},
	info: (...args) => {
		console.info(...args)
	},
	warning: (...args) => {
		console.warn(...args)
	},
	error: (...args) => {
		console.error(...args)
	},
	fatal: (...args) => {
		console.error(...args)
	}
}

export default log
