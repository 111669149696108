import log from 'utils/log'

export function getCurrentGeolocationData() {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(resolve, reject, {
			timeout: 5000,
			enableHighAccuracy: false
		})
	})
}

// https://en.wikipedia.org/wiki/Haversine_formula
export const coordDistance = (p1, p2) => {
	const R = 6371e3 // earth radius in metres
	const toRad = Math.PI / 180
	const lat1 = p1.lat * toRad
	const lat2 = p2.lat * toRad
	const latDiff = (p2.lat - p1.lat) * toRad
	const lngDiff = (p2.lng - p1.lng) * toRad

	const a =
		Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
		Math.cos(lat1) *
			Math.cos(lat2) *
			Math.sin(lngDiff / 2) *
			Math.sin(lngDiff / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	const d = R * c
	return d
}

const getNearestCountry = (countries, currentGeolocation) => {
	let nearestDistance = Number.MAX_SAFE_INTEGER
	if (!countries.length) {
		return null
	}
	let nearestCountry = countries[0]
	for (let i = 0; i < countries.length; i += 1) {
		let currentDistance = Number.MAX_SAFE_INTEGER
		if (countries[i].lat && countries[i].lng) {
			currentDistance = coordDistance(currentGeolocation, countries[i])
		}
		if (currentDistance < nearestDistance) {
			nearestDistance = currentDistance
			nearestCountry = countries[i]
		}
	}
	return nearestCountry
}

export const getNearestCountryFromGeolocation = async countries => {
	try {
		const coords = await getCurrentGeolocationData()
		const nearestCountry = getNearestCountry(countries, {
			...coords,
			lat: coords.coords.latitude,
			lng: coords.coords.longitude
		})
		return nearestCountry
	} catch (err) {
		log.error('getNearestCountryFromGeolocation error: ', err)
		return null
	}
}
