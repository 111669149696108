import storage, { REGIONS, CURRENT_REGION, DATA_CENTER } from 'utils/storage'
import _ from 'lodash'
import axiosApiClient from 'api/axiosApiClient'
import { getNearestCountryFromGeolocation } from 'utils/geolocation'
import { featureFlags } from 'config/index'
import * as Sentry from '@sentry/react'

export const getRegions = async () => {
	// NOTE: avoid calling in ui test for saving api quotas
	if (process.env.NODE_ENV === 'test') {
		return
	}

	const regionsInLocalStorage = storage.getItem(REGIONS)
	const currentRegionInLocalStorage = storage.getItem(CURRENT_REGION)

	if (regionsInLocalStorage?.length > 0 && currentRegionInLocalStorage) {
		return
	}

	const { defaultRegion, data: regions } = _.get(
		await axiosApiClient.get('/v1/regions'),
		'data',
		{}
	)

	const currentRegion =
		defaultRegion ||
		(await getNearestCountryFromGeolocation(regions)) ||
		_.find(regions, { id: 'HK' }) // fallback to HK

	// set dc to define api base url
	storage.setItem(REGIONS, JSON.stringify(regions))
	storage.setItem(CURRENT_REGION, JSON.stringify(currentRegion))
	storage.setItem(
		DATA_CENTER,
		getPlusDcByRegionDc.get(currentRegion.datacenter) || 'sg'
	)
}

export const updateDataCenter = location => {
	const locationPrefix = location.split('_')[0]
	const regions = JSON.parse(storage.getItem(REGIONS)) || []
	const currentRegion = _.find(regions, { id: locationPrefix })

	if (currentRegion) {
		const oldDataCenter = storage.getItem(DATA_CENTER)
		const newDataCenter =
			getPlusDcByRegionDc.get(currentRegion.datacenter) || 'sg'
		storage.setItem(CURRENT_REGION, JSON.stringify(currentRegion))
		storage.setItem(DATA_CENTER, newDataCenter)
		if (oldDataCenter !== newDataCenter) {
			return true
		}
	}
	return false
}

export const getPlusDcByRegionDc = new Map([
	['SIN', 'sg'],
	['SAO', 'br']
])

// info from api-core lbs.enum.ts CITY_ID
export const CITY_ID = {
	IN_BOM: 11001,
	IN_DEL: 11002,
	IN_BMM: 11003,
	IN_DSR: 11004,
	IN_EAS: 11005,
	IN_MPR: 11006,
	IN_RAJ: 11007,
	IN_SOU: 11008,
	IN_WES: 11009,
	IN_YNK: 11010,
	IN_HYD: 11011,
	IN_ICD: 11012,
	IN_BLR: 11013,
	IN_AMD: 11014,
	IN_PNQ: 11015,
	IN_MAA: 11016,
	IN_IXL: 11999,
	BR_SAO: 21001,
	BR_RIO: 21002,
	BR_BHZ: 21003,
	BR_CWB: 21004,
	BR_POA: 21005,
	BR_BSB: 21006,
	BR_GYN: 21007,
	BR_SSA: 21008,
	BR_REC: 21009,
	BR_FOR: 21010,
	BR_AJU: 21011,
	BR_BEL: 21012,
	BR_FLN: 21013,
	BR_RAO: 21014,
	BR_MCZ: 21015,
	BR_SLZ: 21016,
	BR_MAO: 21017,
	BR_RBR: 21999,
	SG_SIN: 31001,
	SG_SEN: 31999,
	MY_KUL: 41001,
	MY_NTL: 41002,
	MY_JHB: 41003,
	MY_PUA: 41999,
	PH_MNL: 51001,
	PH_CEB: 51002,
	PH_PAM: 51003,
	PH_JOL: 51999,
	MX_MEX: 61001,
	MX_DCS: 61999,
	TW_TPE: 81001,
	TW_TXG: 81002,
	TW_KHH: 81003,
	TW_TNN: 81004,
	TW_KNH: 81999,
	HK_HKG: 91001,
	VN_HAN: 101001,
	VN_SGN: 101002,
	VN_HPH: 101003,
	VN_PQC: 101999,
	TH_BKK: 111001,
	TH_PYX: 111003,
	TH_KSM: 111999,
	ID_JKT: 121001,
	ID_BDO: 121002,
	ID_SUB: 121003,
	ID_BLT: 121999
}

/**
 * Get locale used in driver app mode, will fallback to en-HK if locale doesn't supported in selected location
 * https://huolala.feishu.cn/wiki/wikcnksfvi0JEZmu0fGvPL1PlXg#rjbfSl
 *
 * @param {string[]} locationLocales Locales of selected location
 * @param {string | undefined} driverAppLocale
 * @returns {string}
 */
export const getDriverModeLocale = (locationLocales, driverAppLocale) => {
	const defaultLocale = 'en-HK'
	if (
		featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE_PHASE2 &&
		driverAppLocale
	) {
		const isDriverLocaleSupported =
			locationLocales.includes(driverAppLocale)
		if (!isDriverLocaleSupported) {
			Sentry.captureMessage(
				`'getCurrentLocale - driver selected locale (${driverAppLocale}) not supported, falling back to default locale en-HK'`,
				{
					level: 'debug',
					extra: {
						locationLocales,
						driverAppLocale
					}
				}
			)
		}

		return isDriverLocaleSupported ? driverAppLocale : defaultLocale
	}
	return locationLocales[0] || defaultLocale
}
