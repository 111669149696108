import React, { useState } from 'react'
import { IonItem, IonList, IonListHeader } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	llmError,
	llmSuccess,
	llmWarning,
	medium,
	white,
	dark
} from 'styles/colors'
import QRCodeDisplay from 'components/QRCodeDisplay'
import { findFormat, formatDisplay, noop } from 'utils/helpers'
import { parseCrossDockQRCodeData } from 'utils/qrcode'
import { IonLabel } from '@ionic/react'
import { monospaceFont } from 'styles/fonts'

const Status = styled.span`
	min-width: 9em;
	border: 1px solid ${({ color }) => color};
	border-radius: 0;
	padding: 0.4em 0;
	text-align: center;
	color: ${({ active, color }) => (active ? white : color)};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	background-color: ${({ active, color }) => (active ? color : white)};
	filter: ${({ disabled }) => (disabled ? 'grayscale(60%)' : 'none')};
`

const Text = styled(IonLabel)`
	margin: 0 0.5em 0 0;

	&& {
		white-space: normal;
		font-family: ${monospaceFont};
		font-size: ${({ large }) => (large ? 24 : 20)}px;
		font-weight: ${({ large }) => (large ? 'bold' : 'normal')};
	}
`

const MutedText = styled.span`
	color: ${medium};
`

const HighlightedText = styled.strong`
	color: ${dark};
`

const SubText = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: ${medium};
`

const highlightMatch = (text, searchString) => {
	if (!searchString) {
		return ''
	}
	const index = text.indexOf(searchString)
	if (index < 0) {
		return <MutedText>{text}</MutedText>
	}
	const prefix = text.slice(0, index)
	const match = text.slice(index, index + searchString.length)
	const suffix = text.slice(index + searchString.length)
	return (
		<>
			<MutedText>{prefix}</MutedText>
			<HighlightedText>{match}</HighlightedText>
			<MutedText>{suffix}</MutedText>
		</>
	)
}

const PARCEL_STATUS = {
	REVIEWING: 'PENDING',
	APPROVED: 'CROSSDOCKED',
	REJECTED: 'NOT_CROSSDOCKED'
}
const COLOR_MAP = {
	REVIEWING: llmWarning,
	APPROVED: llmSuccess,
	REJECTED: llmError
}

const { REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 } = process.env
// for phase 1, default status is CROSSDOCKED:
const DEFAULT_STATUS =
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 === 'true'
		? 'REVIEWING'
		: 'CROSSDOCKED'

const SectionItem = styled(IonItem)`
	--padding-start: 0;
	--inner-padding-end: 0;
	ion-list {
		width: 100%;
	}
	ion-item {
		--min-height: 36px;
	}
`

const SectionList = styled(IonList)`
	background-color: ${white};
`

const Title = styled.span`
	font-weight: bold;
`

const SubTitle = styled.div`
	font-size: 14px;
	font-weight: normal;
	color: ${medium};
`

const ListItem = styled(IonItem)`
	--background: ${white};
	img {
		width: 24px;
		margin-right: 0.5em;
	}
`

/**
 * @typedef {{
 * 	data: import('redux/summary').CrossdockParcelMetadataData["data"],
 * 	submittedAt: Date,
 * 	courier: any,
 * 	onItemClick: () => void,
 * 	displayFormats: import('pages/Summary/hooks/config').SummaryDisplayFormats,
 * 	searchString: string,
 * 	clientNames: import('pages/Summary/hooks/config').SummaryClientNames,
 * 	squared: any
 * }} SummarySectionProps
 */

/**
 * Main SummarySection component for cross-docking items
 *
 * @type {React.FC<SummarySectionProps>}
 */
const SummarySection = ({
	data,
	submittedAt,
	courier,
	onItemClick,
	displayFormats,
	searchString,
	clientNames,
	squared
}) => {
	const { i18n, t } = useTranslation()
	const [isQrOpen, setIsQrOpen] = useState(false)

	const { DOCKSTATUS, BARCODE } = data
	const cdQRCodeData = parseCrossDockQRCodeData(BARCODE)
	cdQRCodeData.forEach(i => {
		const data = i[0]
		data.format = findFormat(
			displayFormats
				.filter(f => f.clientId === data.clientId)
				.map(f => f.format),
			data.value
		)
	})

	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	}
	const datetime = new Date(submittedAt).toLocaleString(
		i18n.language,
		options
	)

	return (
		<>
			<SectionItem lines="none">
				<SectionList>
					<IonListHeader>
						<Title>
							{t('Summary.heading_cross_docked', {
								datetime,
								interpolation: { escapeValue: false }
							})}
							{courier && (
								<SubTitle>
									{t('Summary.label_courier_info', {
										...courier
									})}
								</SubTitle>
							)}
						</Title>
						<img
							src="assets/icon/qrcode.svg"
							alt="QR Code"
							width="24"
							style={{ width: '24px', margin: '0 16px' }}
							onClick={() => setIsQrOpen(true)}
						/>
					</IonListHeader>
					{cdQRCodeData.map((qrData, i) => {
						const data = qrData[0]
						return (
							<ListItem
								button
								onClick={() =>
									onItemClick(
										`${data.clientId}:${data.value}`
									)
								}
								key={data.value}
								lines="none"
							>
								<Text>
									{searchString
										? highlightMatch(
												data.value,
												searchString
										  )
										: formatDisplay(
												data.value,
												data.format
										  )}
									<SubText>{`[${
										clientNames[data.clientId]
									}]`}</SubText>
								</Text>
								{i === 0 && (
									<Status
										active
										color={
											COLOR_MAP[
												DOCKSTATUS || DEFAULT_STATUS
											]
										}
										squared={squared}
									>
										{t(
											`PARCEL_STATUS.${
												PARCEL_STATUS[
													DOCKSTATUS || DEFAULT_STATUS
												]
											}`
										)}
									</Status>
								)}
							</ListItem>
						)
					})}
				</SectionList>
			</SectionItem>
			<QRCodeDisplay
				isOpen={isQrOpen}
				backdropDismiss
				onClose={() => setIsQrOpen(false)}
				data={data}
				headingLabel={`${t('Form.label_only_number_item')}: `}
				headingValue={cdQRCodeData.length}
				id={data.DOCKID}
			/>
		</>
	)
}

SummarySection.defaultProps = {
	onItemClick: noop,
	displayFormats: [],
	searchString: '',
	squared: false
}

export default React.memo(SummarySection)
