import {
	DRIVER_ROLES,
	DRIVER_USER_ID,
	IS_DRIVER_MODE,
	LOCATION,
	TOKEN
} from 'utils/storage'
import { updateDataCenter } from 'utils/region'
// This module will handle all app-wide configs

// 1. Action types:
export const SET_CAMERAS = 'SET_CAMERAS'
export const SELECT_CAMERA = 'SELECT_CAMERA'

export const SET_DRIVER_APP_TOKEN = 'SET_DRIVER_APP_TOKEN'

export const SET_LAST_SELECTED_CLIENT_ID = 'SET_LAST_SELECTED_CLIENT_ID'

// 2. Action creators:
export const setCameras = cameras => ({
	type: SET_CAMERAS,
	cameras
})

export const selectCamera = (camera, cameraLabel) => ({
	type: SELECT_CAMERA,
	camera,
	cameraLabel
})

export const setDriverAppToken = ({ token, locationId }) => ({
	type: SET_DRIVER_APP_TOKEN,
	token,
	locationId
})

export const setLastSelectedClientId = lastSelectedClientId => ({
	type: SET_LAST_SELECTED_CLIENT_ID,
	lastSelectedClientId
})

// 3. Initial states:
const initState = {
	cameras: [],
	isDriverMode: false,
	lastSelectedClientId: null
	// .. other configs in the future..
}

// 4. Selectors:
export const getCameras = state => {
	return state.config.cameras
}
export const getSelectedCamera = state => state.config.selectedCamera
export const getSelectedCameraLabel = state => state.config.selectedCameraLabel

export const getDriverAppMode = state => state.config.isDriverMode

export const getLastSelectedClientId = state =>
	state.config.lastSelectedClientId

// 5. Reducers:
export default function reducer(
	state = initState,
	{
		type,
		cameras,
		camera,
		cameraLabel,
		token,
		driverUserId,
		driverRoles,
		locationId,
		lastSelectedClientId
	}
) {
	switch (type) {
		case SET_CAMERAS:
			return { ...state, cameras }
		case SELECT_CAMERA:
			return {
				...state,
				selectedCamera: camera,
				...(cameraLabel && { selectedCameraLabel: cameraLabel })
			}
		case SET_LAST_SELECTED_CLIENT_ID:
			return {
				...state,
				lastSelectedClientId
			}
		case SET_DRIVER_APP_TOKEN:
			if (token && locationId) {
				localStorage.setItem(TOKEN, token)
				localStorage.setItem(IS_DRIVER_MODE, true)
				localStorage.setItem(LOCATION, locationId)
				localStorage.setItem(DRIVER_USER_ID, driverUserId)
				localStorage.setItem(DRIVER_ROLES, JSON.stringify(driverRoles))
				updateDataCenter(locationId)
			}
			return {
				...state,
				isDriverMode: true
			}
		default:
			return state
	}
}
