import React, { useState } from 'react'
import { IonIcon, IonModal } from '@ionic/react'
import { close } from 'ionicons/icons'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import Button from 'components/Button'
import { dark, white } from 'styles/colors'
import { noop } from 'utils/helpers'
import Dialog from './Dialog'
import Heading from './Heading'

const {
	REACT_APP_AWS_S3_HOST,
	REACT_APP_AWS_S3_FOLDER,
	REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD
} = process.env

const MAX_LENGTH = 1000 // max photo width/height in pixels
const CONCURRENT_PHOTO_UPLOAD_LIMIT = 5
const MAX_NUMBER_OF_PHOTO_TO_SUBMIT = 25

// make input to look like button
const Label = styled.label`
	display: block;
	margin: 1em 0.125em;
	border-radius: 4px;
	padding: 0.75em;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	color: ${white};
	background-color: ${({ theme: { bgColor } }) => bgColor || dark};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
`

// hide the actual input
const Input = styled.input`
	display: none;
`

// grid item (photo)
const PhotoCell = styled.div`
	float: left;
	position: relative;
	width: 48%;
	padding-bottom: 48%;
	margin: 1%;
	background-color: black;
	background-image: url(${({ src }) => src});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	${({ status }) =>
		status &&
		css`
			box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
		`}
`

// button to delete taken photos
const DeleteButton = styled(Button)`
	position: absolute;
	top: 0.5em;
	right: 0.5em;

	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

const PhotoStatus = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-weight: bold;
	color: ${white};
`

// modal to show photo preview
const PreviewModal = styled(IonModal)`
	.modal-wrapper {
		background-color: rgba(0, 0, 0, 0.75);
	}
`

const PreviewContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;

	img {
		margin: auto;
	}
`

// close button to close photo preview
const CloseButton = styled(Button)`
	position: absolute;
	top: calc(var(--ion-safe-area-top, 0) + 0.5em);
	right: 0.5em;

	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

// list item component, contains photo and delete button
const Item = ({ photo, onClickThumbnail, onClickDelete }) => {
	const { t } = useTranslation()

	// when thumbnails are clicked
	const handleClickThumbnail = () => {
		onClickThumbnail(photo.id)
	}

	// when delete buttons are clicked
	const handleClickDelete = e => {
		e.stopPropagation()
		onClickDelete(photo.id)
	}

	const showStatus =
		REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD === 'true' && photo.status

	return (
		<PhotoCell
			src={photo.data || photo.url}
			status={showStatus}
			onClick={handleClickThumbnail}
			className="gtm-btn-photo-preview-open"
		>
			<DeleteButton
				color="danger"
				size="small"
				onClick={handleClickDelete}
				className="gtm-btn-photo-remove"
			>
				<IonIcon icon={close}></IonIcon>
			</DeleteButton>
			{showStatus && <PhotoStatus>{t(photo.status)}</PhotoStatus>}
		</PhotoCell>
	)
}

Item.defaultProps = {
	photo: {},
	onClickDelete: noop
}

// main component, photo section of dropoff form
const Photo = ({
	photos,
	onAdd,
	onDelete,
	label,
	isArray,
	isRequired,
	galleryAccess,
	formTag,
	fieldTag,
	fieldId
}) => {
	const { t } = useTranslation()
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [idToDelete, setIdToDelete] = useState('') // id of photo to be deleted
	const [idPreview, setIdPreview] = useState('') // id of photo currently being previewed

	// on preview photo by id
	const handlePreview = id => {
		setIdPreview(id)
	}

	// on delete photo by id
	const handleDelete = id => {
		setIdToDelete(id)
		setIsDialogOpen(true)
	}

	// cancel delete
	const handleDialogNo = () => {
		setIsDialogOpen(false)
	}

	// confirm delete
	const handleDialogYes = () => {
		setIsDialogOpen(false)
		onDelete(idToDelete)
	}

	// capture photo from camera and resize it
	const handleCapturePhoto = inputEvent => {
		const file = inputEvent.target.files[0]
		if (!file) {
			return
		}
		const reader = new FileReader()
		reader.onload = readerEvent => {
			const image = new Image()
			image.onload = () => {
				const canvas = document.createElement('canvas')
				const context = canvas.getContext('2d')
				let width = image.width
				let height = image.height
				if (width > height) {
					if (width > MAX_LENGTH) {
						height *= MAX_LENGTH / width
						width = MAX_LENGTH
					}
				} else {
					if (height > MAX_LENGTH) {
						width *= MAX_LENGTH / height
						height = MAX_LENGTH
					}
				}
				canvas.width = width
				canvas.height = height
				context.drawImage(image, 0, 0, width, height)
				const data = canvas.toDataURL('image/jpeg')
				const id = uuid()
				const url = `${REACT_APP_AWS_S3_HOST}/${REACT_APP_AWS_S3_FOLDER}/${id}.jpeg`
				const status = 'Form.message_upload_pending_short'
				onAdd({ id, data, url, status })
			}
			image.src = readerEvent.target.result
		}
		reader.readAsDataURL(file)
	}

	const isMaxNumberOfPhotoExceeded = () => {
		return photos.length >= MAX_NUMBER_OF_PHOTO_TO_SUBMIT
	}

	const isConcurrentUploadLimitExceeded = () => {
		return (
			photos.filter(p => p.status).length >= CONCURRENT_PHOTO_UPLOAD_LIMIT
		)
	}

	const getPhotoSrcById = id => {
		const p = photos.find(p => p.id === id) || {}
		return p.data || p.url
	}

	const getSubmitButtonLabelKey = () => {
		switch (true) {
			case isConcurrentUploadLimitExceeded():
				return 'Form.message_upload_pending_short'
			case isMaxNumberOfPhotoExceeded():
				return 'Form.message_max_item_error'
			default:
				return `Form.button_${galleryAccess ? 'upload' : 'take'}_photo`
		}
	}

	const shouldDisableButton =
		REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD === 'true' &&
		(isConcurrentUploadLimitExceeded() || isMaxNumberOfPhotoExceeded())

	return (
		<>
			{/* Modal to show photo preview */}
			<PreviewModal isOpen={!!idPreview} animated={false}>
				<PreviewContainer onClick={() => setIdPreview('')}>
					<img src={getPhotoSrcById(idPreview)} alt="preview" />
					<CloseButton
						shape="round"
						className="gtm-btn-photo-preview-close"
					>
						<IonIcon icon={close}></IonIcon>
					</CloseButton>
				</PreviewContainer>
			</PreviewModal>
			{/* Delete photo confirmation dialog */}
			<Dialog
				isOpen={isDialogOpen}
				header={t('Form.dialog_delete_photo')}
				message={`<img src="${getPhotoSrcById(
					idToDelete
				)}" alt="parcel" />`}
				onNo={handleDialogNo}
				onYes={handleDialogYes}
				onDismiss={handleDialogNo}
			/>
			<Heading label={label} isRequired={isRequired} />
			{/* The take photo button */}
			{(isArray || !photos.length) && (
				<Label id={fieldId} disabled={shouldDisableButton}>
					<Input
						type="file"
						accept="image/*"
						capture={galleryAccess ? null : 'environment'}
						onChange={handleCapturePhoto}
						disabled={shouldDisableButton}
						className={`gtm-btn-add-${formTag}.${fieldTag}`}
					/>
					{t(getSubmitButtonLabelKey())}
				</Label>
			)}
			{/* Photo grid */}
			{/* new photos should be added to the end of array in data
			reverse here just for display */}
			{photos
				.slice()
				.reverse()
				.map(photo => (
					<Item
						photo={photo}
						onClickDelete={handleDelete}
						onClickThumbnail={handlePreview}
						key={photo.id}
					></Item>
				))}
			<div style={{ clear: 'both' }}></div>
		</>
	)
}

Photo.defaultProps = {
	photos: [], // array of photos
	onAdd: noop,
	onDelete: noop,
	label: 'Photo',
	isArray: false,
	isRequired: false,
	galleryAccess: false,
	formTag: '',
	fieldTag: '',
	fieldId: ''
}

export default Photo
