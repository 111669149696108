import { useDispatch, useSelector } from 'react-redux'
import storage, { CROSSDOCKS } from 'utils/storage'
import {
	safeJSONParse,
	updateCrossDockItemsInSummary,
	getCrossdock
} from 'utils/helpers'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import {
	fetchEndStateSummaryParcels,
	fetchNonEndStateSummaryParcels,
	updateV1ParcelsFromAggregatedParcels
} from 'utils/summary'
import {
	setCrossdockParcels,
	setApiEndStateParcels,
	setApiParcels
} from 'redux/summary'
import { setCrossdockParcelsMetadata } from 'redux/summary'
import { parseCrossDockQRCodeData } from 'utils/qrcode'
import { useSummaryGroupedByBarcodeParcels } from './aggregate'

/** @type {function(string): void} */
export const useSummaryRouteRefreshEffect = onRouteChange => {
	const dispatch = useDispatch()
	const location = useLocation()

	useEffect(() => {
		const refreshAllSummaryParcels = async () => {
			const [
				{ parcels: apiEndStateParcels },
				{ parcels: apiParcels, crossDockParcels }
			] = await Promise.all([
				fetchEndStateSummaryParcels(),
				fetchNonEndStateSummaryParcels()
			])
			dispatch(setApiParcels(apiParcels))
			dispatch(setApiEndStateParcels(apiEndStateParcels))
			dispatch(setCrossdockParcels(crossDockParcels))
		}
		if (onRouteChange(location.pathname)) {
			refreshAllSummaryParcels()
		}
	}, [location.pathname, dispatch, onRouteChange])
}

/** @type {function(): void} */
export const useSummaryCrossDockEffect = () => {
	const crossDockTimer = useRef(null)
	const eTags = useRef({})
	const crossdockParcelsMetadataList = useSelector(
		state => state.summary.crossdockParcelsMetadata.list || []
	)
	const dispatch = useDispatch()
	const groupedByBarcodeParcels = useSummaryGroupedByBarcodeParcels()

	useEffect(() => {
		const CD_STATUS_UPDATE_INTERVAL = 10 * 1000 // seconds
		const updateCrossdockParcelsMetadata = () => {
			clearInterval(crossDockTimer.current)
			crossDockTimer.current = setInterval(async () => {
				let hasUpdate = false
				const cds = crossdockParcelsMetadataList.slice()
				for (const cd of cds) {
					if (cd.data.DOCKSTATUS) {
						continue
					}
					const { DOCKID } = cd.data
					const res = await getCrossdock(
						DOCKID,
						eTags.current[DOCKID]
					)
					if (!res) {
						continue
					}
					const { status } = res.data
					if (res.headers.etag) {
						eTags.current[DOCKID] = res.headers.etag // eslint-disable-line require-atomic-updates
					}
					if (status !== 'REVIEWING') {
						cd.data.DOCKSTATUS = status
						updateCrossDockItemsInSummary(
							parseCrossDockQRCodeData(cd.data.BARCODE).map(
								data => data[0].value
							),
							status,
							groupedByBarcodeParcels,
							updateV1ParcelsFromAggregatedParcels
						)
						hasUpdate = true
					}
				}
				if (hasUpdate) {
					const json =
						safeJSONParse(storage.getItem(CROSSDOCKS)) || {}
					json.list = cds
					dispatch(
						setCrossdockParcelsMetadata({
							list: cds,
							updatedAt: json.updatedAt
						})
					)
					storage.setItem(CROSSDOCKS, JSON.stringify(json))
					if (!cds.some(c => !c.data.DOCKSTATUS)) {
						clearInterval(crossDockTimer.current)
						eTags.current = {} // eslint-disable-line require-atomic-updates
					}
				}
			}, CD_STATUS_UPDATE_INTERVAL)
		}

		if (process.env.REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 === 'true') {
			if (crossdockParcelsMetadataList.some(c => !c.data.DOCKSTATUS)) {
				updateCrossdockParcelsMetadata()
			} else {
				clearInterval(crossDockTimer.current)
				eTags.current = {}
			}
		}
		return () => {
			clearInterval(crossDockTimer.current)
			eTags.current = {}
		}
	}, [crossdockParcelsMetadataList, dispatch, groupedByBarcodeParcels])
}
