import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { IonCardContent } from '@ionic/react'
import { StyledIonCard } from './Common'
import { PathButton } from 'components/Button'

const StyledCard = styled(StyledIonCard)`
	padding: 11px 5px;
	text-align: center;
`

const StyledContainer = styled.div`
	margin: 4px 0;
`

export const IncorrectDeliveryInfoCard = () => {
	const { t } = useTranslation()
	return (
		<StyledCard>
			<IonCardContent>
				<StyledContainer>
					{t(
						'INCORRECT_DELIVERY_INFO.incorrect_delivery_info_card_description'
					)}
				</StyledContainer>
				<StyledContainer>
					<NavLink to="/incorrect-delivery-info">
						<PathButton>
							{t('INCORRECT_DELIVERY_INFO.request_button_title')}
						</PathButton>
					</NavLink>
				</StyledContainer>
			</IonCardContent>
		</StyledCard>
	)
}
