import React, { createRef, useEffect, useState } from 'react'
import {
	IonChip,
	IonContent,
	IonDatetime,
	IonIcon,
	IonInput,
	IonItem,
	IonModal,
	IonTextarea
} from '@ionic/react'
import { close } from 'ionicons/icons'
import styled, { ThemeProvider } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import ButtonGrid from 'components/ButtonGrid'
import {
	ARRAY_ADD_END,
	ARRAY_REMOVE_ID,
	ARRAY_REMOVE_ITEM,
	ARRAY_REMOVE_VALUE,
	ARRAY_UPDATE_VALUE,
	ARRAY_CLEAR_ITEMS,
	STRING_CHANGE,
	STRING_TOGGLE,
	PICKUP
} from 'redux/form'
import { APPROVED, REJECTED } from 'redux/message'
import { danger, llmError, llmSuccess } from 'styles/colors'
import { monospaceFont, primaryFont } from 'styles/fonts'
import { MODAL } from 'styles/zIndex'
import {
	getSelectionStart,
	getSelectionEnd,
	getTranslationValue,
	noop,
	setFocus
} from 'utils/helpers'
import i18n from 'utils/i18n'
import Heading from './Heading'
import Barcode from './Barcode'
import Radio from './Radio'
import Signature from './Signature'
import Photo from './Photo'
import Checkbox from './Checkbox'
import Counter from './Counter'
import { FIELD_TYPE } from './constants'
import moment from 'moment-timezone'
import storage, { LOCALE } from 'utils/storage'
import _ from 'lodash'
import { useSummaryGroupedByBarcodeParcels } from 'pages/Summary/hooks/aggregate'

const {
	REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD,
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK
} = process.env

const CustomIonModal = styled(IonModal)`
	.ion-page {
		padding-top: var(--ion-safe-area-top, 0);
	}
`

const StyledForm = styled.form`
	height: calc(100% - 50px);
	display: flex;
	flex-direction: column;
`

const Fields = styled.div`
	flex: 1;
`

// form field item
const FormItem = styled.div`
	margin: 0;
	:not(:last-child) {
		margin-bottom: 1em;
	}

	&& input,
	&& textarea {
		font-family: ${monospaceFont};
		font-size: 22px;

		::placeholder {
			font-family: ${primaryFont};
			font-size: 16px;
		}
	}
`

// section heading container, contains section title and clear button
const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

// wraps ionic form fields
const FieldWrapper = styled.div`
	border: 1px solid ${({ error }) => (error ? danger : 'silver')};
`

// wraps ionic input component
const InputWrapper = styled(IonItem)`
	margin: 1em 0;
	border: 1px solid silver;

	--min-height: 38px;
	--inner-padding-end: 0.25em;
`

const ChipsWrapper = styled.div`
	margin-top: -1em;
`

const Chip = styled(IonChip)`
	margin: 0.25em 0.25em 0 0;
`

// error message section
const ErrorWrapper = styled.div`
	padding: 0.5em;
	border-width: 0;
	border-radius: 4px;
	color: ${danger};
	background-color: #fbeaed;
`

const SubmitButton = styled(Button)`
	margin: 1em -1em -1em -1em;
`

const ButtonContainer = styled.div`
	display: flex;
	margin: 1em -1em -1.5em -1em;
	ion-button {
		flex: 1;
		margin: 0;
	}
`

const RoundButton = styled(Button)`
	top: 0.5em;
	right: 0.5em;
	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

const IdDisplay = styled.div`
	margin: 1em 0;
	text-align: center;
	font-family: ${monospaceFont};
	font-size: 22px;
`

// TODO: move to common components..
const CustomContent = styled(IonContent)`
	--ion-background-color: #f8f9fc;
`

const GridSeparator = styled.hr`
	border-top: 1px solid silver;
	margin: 0 1em;
`

const ModalCloseButton = styled(Button)`
	position: fixed;
	top: calc(var(--ion-safe-area-top, 0) + 0.5em);
	right: 0.5em;
	z-index: ${MODAL + 1};
	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

const CustomTextarea = styled(IonTextarea)`
	> .native-textarea.sc-ion-textarea-md {
		&::placeholder {
			white-space: pre-line;
		}
	}
`

const ClientSelect = styled(IonInput)`
	color: ${({ error }) => (error ? danger : 'black')};
	input {
		opacity: 1 !important;
	}
`

/**
 * Check if barcode already in values
 *
 * @param {*} state
 * @param values
 * @param {string} tag
 * @param {string} id
 * @returns {boolean}
 */
const isBarcodeExist = (values, tag, id) =>
	values[tag] && values[tag].some(item => item.value === id.value)

const Form = ({
	clients,
	clientId,
	model,
	values,
	formGroup,
	submitting,
	onAction,
	onLock,
	onReset,
	onSubmit,
	onCancel,
	onPhotoAdded,
	onInlineFormClick
}) => {
	const { t } = useTranslation()

	const recipientRef = createRef()

	// field errors
	const [errors, setErrors] = useState([])

	const [crossDockData, setCrossDockData] = useState(null)
	const currentLocale = storage.getItem(LOCALE)

	const isOutbound =
		REACT_APP_ENABLE_LLMP_289_CROSSDOCK === 'true' &&
		model.attributes.crossdock &&
		model.attributes.crossdock.position === 'outbound'

	const [clientSelectTag, setClientSelectTag] = useState(null)
	const summaryParcelsObject = useSummaryGroupedByBarcodeParcels()

	useEffect(() => {
		setErrors([])
		if (isOutbound) {
			const { qrFieldTags } = model.attributes.crossdock
			if (qrFieldTags) {
				for (const tag of qrFieldTags) {
					onAction(ARRAY_CLEAR_ITEMS, tag)
				}
			}
		}
	}, [model.id]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// auto-fill forms fields from QR code data:
		if (crossDockData) {
			const cdAttr = model.attributes.crossdock || {}
			if (cdAttr.qrFieldTags) {
				for (const qrTag of cdAttr.qrFieldTags) {
					if (qrTag !== 'BARCODE' && crossDockData[qrTag]) {
						const field = model.fields.find(f => f.tag === qrTag)
						if (field) {
							if (field.isArray) {
								onAction(ARRAY_CLEAR_ITEMS, qrTag)
								const _values = crossDockData[qrTag].split(',')
								for (const value of _values) {
									// Don't allow same barcode
									if (isBarcodeExist(values, qrTag, value)) {
										continue
									}

									onAction(ARRAY_ADD_END, qrTag, value)
								}
							} else {
								onAction(
									STRING_CHANGE,
									qrTag,
									crossDockData[qrTag]
								)
							}
						}
					}
				}
			}
			onLock()
		}
		setErrors([])
	}, [crossDockData]) // eslint-disable-line react-hooks/exhaustive-deps

	// validate field values, create submit payload
	const submit = crossDockStatus => {
		const errorMessages = []
		const fields = []
		// additional `Barcode` metadata for `Form`:
		let manualBarcode = false
		let isFlag = false

		model.fields.forEach(
			({ tag, type, isRequired, translations, attributes }) => {
				const valueTemplates = (attributes.valueFormats || []).reduce(
					(acc, cur) => ({ ...acc, [cur.type]: cur.template }),
					{}
				)
				const label = getTranslationValue(translations, tag)
				let fieldValues
				if (Array.isArray(values[tag])) {
					fieldValues = values[tag]
				} else {
					fieldValues = values[tag] ? [values[tag]] : []
				}
				if (isRequired && !values[tag].length) {
					errorMessages.push(
						t('Form.label_required', { field: label })
					)
				}
				// special handling for barcode values:
				if (type === FIELD_TYPE.BARCODE) {
					const barcodeValues = []
					const metaValues = []
					const { clientRefFields, singleClientLimit } =
						model.attributes
					const blockMultiClients =
						singleClientLimit &&
						clientRefFields &&
						clientRefFields.some(crf => crf.tag === tag)
					let prevClientId
					fieldValues.forEach(item => {
						const { value, metas, lat, lng, duration, manual } =
							item
						let formattedValue = value
						metas.forEach(meta => {
							const template = valueTemplates[meta.format]
							if (template) {
								const barcodeData = { value, ...meta }
								barcodeData.scannedAt = moment(
									barcodeData.scannedAt
								).format('YYYY-MM-DD')
								formattedValue = t(
									template
										.replace(/{{/g, '{')
										.replace(/}}/g, '}'),
									barcodeData
								)
							}
							meta.lat = lat
							meta.lng = lng
							meta.duration = duration
							if (
								blockMultiClients &&
								meta.clientId &&
								prevClientId &&
								meta.clientId !== prevClientId
							) {
								errorMessages.push(
									t('Form.message_client_mismatch_item', {
										item: value
									})
								)
							}
							prevClientId = meta.clientId
							if (!meta.clientId) {
								// if clientId is missing, push error message to block submission
								errorMessages.push(
									`This item has no client: ${value}`
								)
							}
							delete meta.displayFormat
							delete meta.timestamp
							delete meta.value
							delete meta.valueClientId
							delete meta.matchingValues
						})
						barcodeValues.push(formattedValue)
						metaValues.push(metas)
						if (manual) {
							manualBarcode = manual
						}
					})
					fields.push({
						tag,
						values: barcodeValues,
						meta: metaValues
					})
				} else if (type === FIELD_TYPE.PHOTO) {
					fields.push({
						tag,
						values: fieldValues.map(v =>
							REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD === 'true'
								? v.url
								: v.data
						)
					})
				} else if (type === FIELD_TYPE.DATE) {
					fields.push({
						tag,
						values: fieldValues.map(v => v.split('T')[0])
					})
				} else if (type === FIELD_TYPE.TIME) {
					fields.push({
						tag,
						values: fieldValues.map(v =>
							v.split('T')[1].slice(0, 5)
						)
					})
				} else {
					fields.push({ tag, values: fieldValues })
				}
			}
		)
		setErrors(errorMessages)
		if (errorMessages.length === 0) {
			isFlag = manualBarcode
			const crossDock = { ...crossDockData, DOCKSTATUS: crossDockStatus }
			const cdAttr = model.attributes.crossdock || {}
			if (cdAttr.qrFieldTags) {
				for (const qrTag of cdAttr.qrFieldTags) {
					if (
						qrTag !== 'BARCODE' &&
						crossDock[qrTag] &&
						!model.fields.some(f => f.tag === qrTag)
					) {
						fields.push({
							tag: qrTag,
							values: crossDock[qrTag].split(',')
						})
					}
				}
			}
			setCrossDockData(null)
			onSubmit(fields, { manualBarcode }, isFlag, crossDock)
		}
	}

	// on submit
	const handleSubmit = e => {
		e.preventDefault()
		submit()
	}

	const handleClientSelectClick = tag => {
		setClientSelectTag(tag)
	}

	const handleClientClick = id => {
		onAction(STRING_CHANGE, clientSelectTag, id)
		setClientSelectTag(null)
	}

	const handleClientSelectClose = () => {
		setClientSelectTag(null)
	}

	let singleClientId
	let clientRefField
	const { clientRefFields } = model.attributes
	if (clientRefFields) {
		for (const crf of clientRefFields) {
			const t = crf.tag
			const v = values[t]
			if (
				v &&
				v.length &&
				v[0].metas &&
				v[0].metas.length &&
				v[0].metas[0] &&
				v[0].metas[0].clientId
			) {
				clientRefField = t
				singleClientId = v[0].metas[0].clientId
				break
			}
		}
	}

	// generate form item based on type of field
	const renderFormItem = field => {
		const {
			BARCODE,
			TEXT,
			RADIO,
			SIGNATURE,
			TEXTAREA,
			PHOTO,
			DATE,
			TIME,
			DROPDOWN,
			CHECKBOX
		} = FIELD_TYPE
		const {
			type,
			translations,
			tag,
			isRequired,
			attributes,
			isArray,
			isHidden,
			isClientRefRequired,
			isNonBarcodeDelivery: isNonBarcodeDeliveryField
		} = field
		const isNonBarcodeEnabled =
			model.attributes.nonBarcodeDeliveryEnabled &&
			isNonBarcodeDeliveryField
		const label = getTranslationValue(translations, tag)
		const fieldId = `${formGroup}-${tag}`
		const isReadOnly =
			isOutbound &&
			model.attributes.crossdock.qrFieldTags &&
			model.attributes.crossdock.qrFieldTags.includes(tag)

		switch (type) {
			case BARCODE: {
				let valueFormats =
					attributes && attributes.valueFormats
						? attributes.valueFormats
						: []
				// filter out formats of unknown clients:
				valueFormats = valueFormats.filter(
					obj =>
						!obj.clientId ||
						clients.find(c => c.id === obj.clientId)
				)
				if (clientId) {
					// if form is client-specific, restrict to client specific formats:
					valueFormats = valueFormats.filter(
						obj => !obj.clientId || obj.clientId === clientId
					)
				}
				// convert all regexp string to RegExp object:
				valueFormats.forEach(obj => {
					obj.regexp = (obj.regexp || []).map(str => new RegExp(str))
					if (isOutbound) {
						obj.type = 'QR_CODE'
					}
				})
				// block multiple clients if singleClientLimit is true && this field is specified as clientRefField:
				const { clientRefFields, singleClientLimit } = model.attributes
				const blockMultiClients =
					singleClientLimit &&
					clientRefFields &&
					clientRefFields.some(crf => crf.tag === tag)

				const onBarcodeAdded = id => {
					// Don't allow same barcode
					if (isBarcodeExist(values, tag, id)) {
						return
					}

					onAction(ARRAY_ADD_END, tag, id)
				}

				return (
					<Barcode
						clients={clients}
						summaryParcelsObject={summaryParcelsObject}
						barcodes={values[tag]}
						onAdd={onBarcodeAdded}
						onUpdate={barcode =>
							onAction(ARRAY_UPDATE_VALUE, tag, barcode)
						}
						onUpdateByIndex={(barcode, index) =>
							onAction(
								'ARRAY_UPDATE_INDEX',
								tag,
								barcode,
								null,
								index
							)
						}
						onDelete={id => onAction(ARRAY_REMOVE_VALUE, tag, id)}
						onClear={() => onAction(ARRAY_CLEAR_ITEMS, tag)}
						onQRDetected={qrData => setCrossDockData(qrData)}
						acceptQROnly={isOutbound}
						isRequired={isRequired}
						label={label}
						valueFormats={valueFormats}
						defaultInputMode={
							attributes && attributes.defaultInputMode
						}
						formGroup={formGroup}
						formTag={model.tag}
						fieldTag={tag}
						clientId={clientId}
						fieldId={fieldId}
						scanWindow={attributes.scanWindow}
						submitting={submitting}
						readOnly={isReadOnly}
						isHidden={isHidden}
						blockMultiClients={blockMultiClients}
						isNonBarcodeField={isNonBarcodeEnabled}
						// moved this as prop to avoid multiple checks inside component:
						forPickup={formGroup === PICKUP}
					/>
				)
			}
			case TEXT: {
				const suggestions = attributes.suggestions || []
				return (
					<>
						<Heading label={label} isRequired={isRequired} />
						<InputWrapper lines="none">
							<IonInput
								type="text"
								clearInput
								ref={recipientRef}
								value={values[tag]}
								onIonChange={e =>
									onAction(STRING_CHANGE, tag, e.target.value)
								}
								placeholder={t('Form.label_enter_item', {
									item: label
								})}
								id={fieldId}
								inputmode={attributes.defaultInputMode}
								className={`gtm-input-${model.tag}.${tag}`}
							/>
						</InputWrapper>
						{!!suggestions.length && (
							<ChipsWrapper>
								{suggestions.map(s => (
									<Chip
										key={s.value}
										onClick={() => {
											// insert selected suggestion at caret position:
											// (if any text is selected, replace selected text):
											const value = values[tag] // prev value
											const start =
												getSelectionStart(recipientRef)
											const end =
												getSelectionEnd(recipientRef)
											// decide if need leading/trailing space:
											let leading = ''
											let trailing = ''
											if (start === end) {
												// if no selection
												if (
													end - 1 >= 0 &&
													value[end - 1] !== ' '
												) {
													leading = ' '
												}
												if (value[end] !== ' ') {
													trailing = ' '
												}
											}
											// construct new value:
											const newValue = `${value.substring(
												0,
												start
											)}${leading}${
												s.value
											}${trailing}${value.substring(end)}`
											onAction(
												STRING_CHANGE,
												tag,
												newValue
											) // update to new value
											setFocus(recipientRef) // keep input focused
										}}
										className={`gtm-btn-suggestion-${model.tag}.${tag}`}
									>
										{s.translations
											? getTranslationValue(
													s.translations,
													s.value
											  )
											: s.value}
									</Chip>
								))}
							</ChipsWrapper>
						)}
					</>
				)
			}
			case 'COUNTER': {
				return (
					<Counter
						value={values[tag]}
						onChange={value =>
							onAction(STRING_CHANGE, tag, value.toString())
						}
						min={0}
						isRequired={isRequired}
						label={label}
						fieldId={fieldId}
					/>
				)
			}
			case RADIO:
			case DROPDOWN:
			case CHECKBOX: {
				const options = field.options.map(option => ({
					tag: option.tag,
					label: getTranslationValue(option.translations, option.tag)
				}))
				const Comp = type === CHECKBOX ? Checkbox : Radio
				let props = {
					options,
					label,
					isArray,
					isRequired,
					formTag: model.tag,
					fieldTag: tag,
					fieldId,
					dropdown: type === DROPDOWN
				}
				if (isArray) {
					props = {
						...props,
						selectedTags: values[tag],
						onAdd: optionTag =>
							onAction(ARRAY_ADD_END, tag, optionTag),
						onRemove: optionTag =>
							onAction(ARRAY_REMOVE_ITEM, tag, optionTag),
						onClear: () => onAction(ARRAY_CLEAR_ITEMS, tag)
					}
				} else {
					props = {
						...props,
						selectedTag: values[tag],
						onSelect: optionTag =>
							onAction(STRING_TOGGLE, tag, optionTag)
					}
				}
				return <Comp {...props} />
			}
			case SIGNATURE:
				return (
					<Signature
						label={label}
						isRequired={isRequired}
						signature={values[tag]}
						onSignatureUpdate={image =>
							onAction(STRING_CHANGE, tag, image)
						}
						formTag={model.tag}
						fieldTag={tag}
						fieldId={fieldId}
					/>
				)
			case TEXTAREA: {
				let formattedPlaceholder = ''

				const placeholderTranslations = _.get(
					field,
					'attributes.placeholder.translations'
				)
				if (placeholderTranslations) {
					const placeholder = placeholderTranslations.find(
						translation => translation.locale === currentLocale
					)?.value
					if (placeholder) {
						formattedPlaceholder = placeholder.replace(/\\n/g, '\n')
					}
				}

				return (
					<>
						<HeadingContainer>
							<Heading label={label} isRequired={isRequired} />
							{values[tag] && (
								<Button
									size="small"
									onClick={() =>
										onAction(STRING_CHANGE, tag, '')
									}
									className={`gtm-btn-clear-${model.tag}.${tag}`}
								>
									{t('Common.button_clear')}
								</Button>
							)}
						</HeadingContainer>
						<FieldWrapper>
							<CustomTextarea
								rows={3}
								value={values[tag]}
								onIonChange={e =>
									onAction(STRING_CHANGE, tag, e.target.value)
								}
								id={fieldId}
								className={`gtm-textarea-${model.tag}.${tag}`}
								placeholder={formattedPlaceholder}
								autoGrow
							/>
						</FieldWrapper>
					</>
				)
			}

			case PHOTO:
				return (
					<Photo
						photos={values[tag] || []}
						onAdd={photo => {
							onAction(ARRAY_ADD_END, tag, photo)
							onPhotoAdded({ tag, ...photo })
						}}
						onDelete={id => {
							onAction(ARRAY_REMOVE_ID, tag, id)
							onCancel(id)
						}}
						label={label}
						isArray={isArray}
						isRequired={isRequired}
						galleryAccess={attributes.galleryAccess}
						formTag={model.tag}
						fieldTag={tag}
						fieldId={fieldId}
					/>
				)
			case DATE:
			case TIME: {
				let displayFormat, pickerFormat
				if (type === DATE) {
					displayFormat = i18n.exists('Datetime.date_display')
						? t('Datetime.date_display')
						: 'D MMM YYYY'
					pickerFormat = i18n.exists('Datetime.date_picker')
						? t('Datetime.date_picker')
						: 'DD MMM YYYY'
				} else {
					// TIME
					displayFormat = i18n.exists('Datetime.time_display')
						? t('Datetime.time_display')
						: 'h:mm A'
					pickerFormat = i18n.exists('Datetime.time_picker')
						? t('Datetime.time_picker')
						: 'hh:mm A'
				}
				return (
					<>
						<HeadingContainer>
							<Heading label={label} isRequired={isRequired} />
							{values[tag] && (
								<Button
									size="small"
									onClick={() =>
										onAction(STRING_CHANGE, tag, '')
									}
									className={`gtm-btn-clear-${model.tag}.${tag}`}
								>
									{t('Common.button_clear')}
								</Button>
							)}
						</HeadingContainer>
						<FieldWrapper>
							<IonDatetime
								displayFormat={displayFormat}
								pickerFormat={pickerFormat}
								minuteValues={
									tag === 'SCHEDULE' ? [0] : undefined
								} // TODO..
								placeholder={t('Form.label_enter_item', {
									item: label
								})}
								cancelText={t('Common.button_cancel')}
								doneText={t('Common.button_ok')}
								monthShortNames={
									i18n.exists('Datetime.month_names_short')
										? t('Datetime.month_names_short')
										: null
								}
								value={values[tag]}
								onIonChange={e =>
									onAction(STRING_CHANGE, tag, e.target.value)
								}
								id={fieldId}
								pickerOptions={{ animated: false }}
							/>
						</FieldWrapper>
					</>
				)
			}
			case 'INLINE_FORM': {
				let clientRefTags = []
				if (model.attributes.clientRefFields) {
					clientRefTags = model.attributes.clientRefFields.map(
						c => c.tag
					)
				}
				let allEmpty = true
				for (const crTag of clientRefTags) {
					if (
						values[crTag] &&
						values[crTag].length &&
						values[crTag][0].metas &&
						values[crTag][0].metas.length &&
						values[crTag][0].metas[0].clientId
					) {
						allEmpty = false
					}
				}
				const disableInlineFormButton = isClientRefRequired && allEmpty
				// const disableInlineFormButton = true && allEmpty // for test
				return (
					<>
						<HeadingContainer>
							<Heading label={label} />
						</HeadingContainer>
						{/* TODO: Matteo will come up with UI updates for this */}
						<Button
							expand="block"
							onClick={() =>
								onInlineFormClick(attributes.inlineFormTag)
							}
							disabled={disableInlineFormButton}
							className={`gtm-btn-open-${model.tag}.${tag}`}
						>
							{`${label} ${
								values[tag]
									? `(${t('Form.label_updated')})`
									: ''
							}`}
						</Button>
					</>
				)
			}
			case 'CLIENT_SELECT': {
				const { translations } =
					clients.find(c => c.id === values[tag]) || {}
				const refField = model.fields.find(
					f => f.tag === clientRefField
				)
				const error =
					singleClientId &&
					values[tag] &&
					values[tag] !== singleClientId &&
					t('Form.message_item_mismatch_item', {
						item1: label,
						item2: getTranslationValue(
							refField.translations,
							refField.tag
						)
					})
				return (
					<>
						<HeadingContainer>
							<Heading label={label} isRequired={isRequired} />
							{values[tag] && (
								<Button
									size="small"
									onClick={() =>
										onAction(STRING_CHANGE, tag, '')
									}
									className={`gtm-btn-clear-${model.tag}.${tag}`}
								>
									{t('Common.button_clear')}
								</Button>
							)}
						</HeadingContainer>
						<FieldWrapper error={error}>
							<ClientSelect
								type="text"
								value={
									values[tag]
										? getTranslationValue(
												translations,
												values[tag],
												'name'
										  )
										: ''
								}
								placeholder={t('Form.label_select_form', {
									form: label
								})}
								onClick={() => handleClientSelectClick(tag)}
								error={error}
								id={fieldId}
								disabled
								className={`gtm-btn-select-${model.tag}.${tag}`}
							/>
						</FieldWrapper>
						<div style={{ marginTop: '0.25em', color: danger }}>
							{error}
						</div>
					</>
				)
			}
			default:
				return null
		}
	}

	const theme = { bgColor: model.attributes.bgColor }
	const submitButtonText =
		getTranslationValue(model.translations, '', 'submitButton') ||
		t('Common.button_submit')

	const isUploadingRequiredPhoto = () => {
		let retval = false
		const requiredPhotoFields = model.fields.filter(
			field => field.type === FIELD_TYPE.PHOTO && field.isRequired
		)
		if (requiredPhotoFields.length) {
			requiredPhotoFields.forEach(({ tag }) => {
				if (
					values[tag] &&
					values[tag].length &&
					values[tag].some(photo => photo.status)
				) {
					retval = true
				}
			})
		}
		return retval
	}
	const uploading = isUploadingRequiredPhoto()
	const disableSubmit = submitting || uploading

	return (
		<ThemeProvider theme={theme}>
			{isOutbound && crossDockData && (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					{/* <Button
						type="button"
						expand="block"
						theme={{ bgColor: llmWarning }}
						onClick={() => { setCrossDockData(null); onReset() }}
					>
						{t('Common.button_cancel')}
					</Button> */}
					<RoundButton
						type="button"
						shape="round"
						onClick={() => {
							setCrossDockData(null)
							onReset()
						}}
					>
						<IonIcon icon={close} />
					</RoundButton>
				</div>
			)}
			<StyledForm onSubmit={handleSubmit}>
				<Fields>
					{/* form fields */}
					{model.fields.map((field, i) => (
						<FormItem key={`${field.tag}-${i}`}>
							{renderFormItem(field)}
						</FormItem>
					))}
					{!!errors.length && (
						<FormItem>
							<ErrorWrapper>
								<h6 className="danger">
									{t('Form.label_error')}:
								</h6>
								<ul>
									{errors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</ErrorWrapper>
						</FormItem>
					)}
				</Fields>
				<FormItem>
					{crossDockData && crossDockData.DOCKID && (
						<IdDisplay>{`ID: ${crossDockData.DOCKID.substring(0, 7)
							.toUpperCase()
							.replace(
								/^([A-Z0-9]{3})([A-Z0-9]{3})([A-Z0-9]{1})$/,
								'$1 $2 $3'
							)}`}</IdDisplay>
					)}
					{isOutbound && crossDockData && (
						<ButtonContainer>
							<Button
								type="button"
								expand="full"
								size="large"
								disabled={disableSubmit}
								theme={{ bgColor: llmError }}
								onClick={() => submit(REJECTED)}
							>
								{t('Form.button_reject')}
							</Button>
							<Button
								type="button"
								expand="full"
								size="large"
								disabled={disableSubmit}
								theme={{ bgColor: llmSuccess }}
								onClick={() => submit(APPROVED)}
							>
								{t('Form.button_approve')}
							</Button>
						</ButtonContainer>
					)}
					{!isOutbound && (
						<SubmitButton
							type="submit"
							expand="full"
							size="large"
							disabled={disableSubmit}
							id={`${formGroup}-button-submit`}
							className={`gtm-btn-form-submit-${model.tag}`}
							data-form={model.tag}
						>
							{disableSubmit
								? t(
										`Form.message_uploading${
											uploading ? '_photo' : ''
										}`
								  )
								: submitButtonText}
						</SubmitButton>
					)}
				</FormItem>
			</StyledForm>
			{/* client selection modal: */}
			<CustomIonModal
				isOpen={!!clientSelectTag}
				backdropDismiss={false}
				animated={false}
			>
				<CustomContent>
					<ModalCloseButton
						shape="round"
						onClick={handleClientSelectClose}
					>
						<IonIcon icon={close} />
					</ModalCloseButton>
					<br />
					<p style={{ textAlign: 'center' }}>
						{t('Form.label_select_client')}:
					</p>
					<ButtonGrid
						list={clients.filter(c => c.id === singleClientId)}
						onClick={handleClientClick}
					/>
					<GridSeparator />
					<ButtonGrid
						list={clients.filter(c => c.id !== singleClientId)}
						onClick={handleClientClick}
					/>
				</CustomContent>
			</CustomIonModal>
		</ThemeProvider>
	)
}

Form.defaultProps = {
	clients: [],
	model: null,
	values: null,
	submitting: false,
	onAction: noop,
	onLock: noop,
	onReset: noop,
	onSubmit: noop,
	onCancel: noop,
	onPhotoAdded: noop,
	onInlineFormClick: noop
}

export default Form
