import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	${props => (props.isSearching ? 'height: auto;' : 'flex: 1;')}
`

export const EmptyState = React.memo(function EmptyState() {
	const { t } = useTranslation()
	const searchText = useSelector(state => state.summary.filter.text)
	const scannedBarCode = useSelector(
		state => state.summary.filter.scannedBarCode
	)
	/** @type {boolean} */
	const hasParcels = useSelector(
		state =>
			state.summary.apiParcels.length > 0 ||
			state.summary.apiEndStateParcels.length > 0 ||
			state.summary.crossdockParcels.length > 0
	)
	const isSearching = Boolean(searchText || scannedBarCode.length > 0)

	return (
		<Content isSearching={isSearching}>
			{isSearching ? (
				<>
					<img
						src="assets/icon/noresult.svg"
						alt="No match"
						width="200"
						height="200"
					/>
					<div>
						{t(
							`Summary.label_no_${
								scannedBarCode.length ? 'scan_' : ''
							}match`,
							{ searchString: searchText }
						)}
					</div>
				</>
			) : (
				!hasParcels && (
					<>
						<img
							src="assets/icon/norecord.svg"
							alt={t('Summary.label_empty')}
							width="200"
							height="200"
						/>
						<div>{t('Summary.label_empty')}</div>
					</>
				)
			)}
		</Content>
	)
})
