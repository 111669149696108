import pako from 'pako'
import log from 'utils/log'

// deflateQRCodeData takes a JSON object and return it as a compressed string
export const deflateQRCodeData = data => {
	const stringifiedQRCodeData = JSON.stringify(data)

	let compressedQRCodeData = ''

	try {
		compressedQRCodeData = pako.deflate(stringifiedQRCodeData, {
			to: 'string',
			level: 9
		})
	} catch (e) {
		log.error('Failed to deflate QRCode data', null, e.stack)
	}

	return compressedQRCodeData
}

// parseCrossDockQRCodeData takes our cross dock QRCode data as string and returns a list of qrcode object
// the data looks like that: 'ACME:123,3456|UMBRELLA:45432,2345678,323,2222222'
export const parseCrossDockQRCodeData = data => {
	const result = []
	if (!data) {
		return result
	}
	const clientsStrings = data.split('|')
	for (const client of clientsStrings) {
		const clientAndIDs = client.split(':')
		const clientId = clientAndIDs[0]
		const IDs = clientAndIDs[1].split(',')

		for (const id of IDs) {
			result.push([
				{
					format: 'QR_CODE',
					formattedValue: false,
					frame: null,
					hits: 1,
					rawValue: id, // rawValue equals to value for QR_CODE
					scannedAt: new Date().toISOString(),
					timestamp: Date.now(),
					value: id,
					clientId: clientId
				}
			])
		}
	}

	return result
}

export const generateCrossDockQRCodeData = (id, data, tags) => {
	const qrCodeData = { data: { DOCKID: id }, meta: { numberOfItems: 0 } }
	const clientMap = new Map()

	for (const tag of tags) {
		const fieldData = data.fields.find(f => f.tag === tag)

		if (fieldData) {
			for (let i = 0; i < fieldData.values.length; i++) {
				qrCodeData.meta.numberOfItems++
				const clientId = fieldData.meta[i][0].clientId
				if (clientMap.has(clientId)) {
					clientMap.get(clientId).push(fieldData.values[i])
				} else {
					clientMap.set(clientId, [fieldData.values[i]])
				}
			}

			const compactMap = []

			for (const [k, v] of clientMap) {
				compactMap.push(`${k}:${v.join(',')}`)
			}

			qrCodeData.data[tag] = compactMap.join('|')
		}
	}

	return qrCodeData
}

// inflateQRCodeData takes a compressed string and return it as a JSON Object
export const inflateQRCodeData = compressedData => {
	let qrCodeBuffer = '{}'

	try {
		qrCodeBuffer = pako.inflate(compressedData, { to: 'string' })
	} catch (e) {
		log.error('Failed to inflate QRCode data', null, e.stack)
	}

	return JSON.parse(qrCodeBuffer)
}
