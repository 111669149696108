import React, { useState } from 'react'
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToolbar
} from '@ionic/react'
import { settings } from 'ionicons/icons'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dark } from 'styles/colors'
import { DEFAULT_LOCALE, logout } from 'utils/helpers'
import log from 'utils/log'
import { getTelegramBotURL } from 'utils/telegram'
import { logOut } from 'ionicons/icons'
import { featureFlags } from 'config'

const { REACT_APP_VERSION } = process.env

// top header bar
const StyledToolbar = styled(IonToolbar)`
	--background: ${dark};
`

const MenuToggle = styled(IonMenuToggle)`
	width: 100%;
	height: 100%;
`

const Icon = styled.img`
	margin-right: 0.5em;
`

const StyledLink = styled.a`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	color: ${dark};
	text-decoration: none;
`

const StyledItem = styled(IonItem)`
	margin: 0 -0.5em 1em 1em;

	div {
		border-top: 1px solid red;
	}
`

const StyledNavLink = StyledLink.withComponent(NavLink)

const MenuContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	ion-list {
		flex: 1;
	}
`

const Footer = styled.div`
	padding: 1em 1em 1em 0;
	margin-left: -1em;
	text-align: right;
	font-size: 12px;
	color: silver;
`

const StyledIcon = styled(IonIcon)`
	margin-right: 0.25em;
	font-size: 32px;
`

const VersionDisplay = styled.span`
	margin-left: 1em;
	font-size: 12px;
	color: silver;
`

const Menu = ({ disabled, formGroups }) => {
	const { i18n, t } = useTranslation()
	const [loading, setLoading] = useState(false)

	// manually instruct the SW to fetch update now
	const update = async () => {
		if ('serviceWorker' in navigator) {
			try {
				const registration = await navigator.serviceWorker.ready
				setLoading(true)
				log.info('[man] Fetching update...')
				const swRegObj = await registration.update()
				log.info('[man] update-reg', swRegObj)
				setLoading(false)
			} catch (e) {
				log.error('Service worker update failed', null, e.stack)
				setLoading(false)
			}
		}
	}

	const translate = (translations, id) => {
		let translated = translations.find(
			translation => translation.locale === i18n.language
		)

		if (!translated) {
			translated = translations.find(
				translation => translation.locale.indexOf(DEFAULT_LOCALE) !== -1
			)
		}

		return translated ? translated.value : id
	}

	const createItem = item => {
		if (item.id === 'TELEGRAM') {
			return (
				<IonItem key="MENU_TELEGRAM">
					<MenuToggle>
						<StyledLink
							href={getTelegramBotURL()}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon src={item.iconPath} width="32" target="" />
							<IonLabel>
								{translate(item.translations, item.id)}
							</IonLabel>
						</StyledLink>
					</MenuToggle>
				</IonItem>
			)
		}

		return (
			<IonItem key={`MENU_${item.id}`}>
				<MenuToggle>
					<StyledNavLink to={item.path}>
						<Icon src={item.iconPath} width="32" />
						<IonLabel>
							{translate(item.translations, item.id)}
						</IonLabel>
					</StyledNavLink>
				</MenuToggle>
			</IonItem>
		)
	}

	return (
		<IonMenu disabled={disabled} contentId="formpage" swipeGesture={false}>
			<IonLoading
				isOpen={loading}
				onDidDismiss={() => setLoading(false)}
				message={'Please wait...'}
			/>
			<IonHeader>
				<StyledToolbar>
					<IonTitle color="light">{t('App.menu')}</IonTitle>
				</StyledToolbar>
			</IonHeader>
			<IonContent>
				<MenuContent>
					<IonList>
						{Array.from(formGroups.items.values(), i =>
							createItem(i)
						)}
						<IonItem>
							<MenuToggle>
								<StyledNavLink to="/settings">
									<StyledIcon icon={settings} />
									<IonLabel>{t('SETTINGS.title')}</IonLabel>
								</StyledNavLink>
							</MenuToggle>
						</IonItem>
					</IonList>
					<Footer>
						{!featureFlags.REACT_APP_ENABLE_DRIVER_APP_MERGE && (
							<StyledItem>
								<MenuToggle>
									<StyledLink
										onClick={logout}
										className="gtm-link-logout"
									>
										<StyledIcon
											icon={logOut}
											color="danger"
										></StyledIcon>
										<IonLabel color="danger">
											{t('Common.button_logout')}
										</IonLabel>
									</StyledLink>
								</MenuToggle>
							</StyledItem>
						)}
						<button
							onClick={update}
							className="gtm-btn-check-update"
						>
							Check for update
						</button>
						<VersionDisplay>{`v${REACT_APP_VERSION}`}</VersionDisplay>
					</Footer>
				</MenuContent>
			</IonContent>
		</IonMenu>
	)
}

Menu.defaultProps = {
	disabled: false,
	formGroups: {
		items: new Map()
	}
}

export default Menu
