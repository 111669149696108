import {
	dark,
	lightBlue,
	llmBlue,
	llmError,
	llmInfo,
	llmSuccess,
	llmSUmmaryCanceled,
	llmSummaryFailedPickup,
	llmWarning
} from 'styles/colors'

export const PARCEL_STATUS = {
	AWAITS_DRIVER: 'AWAITS_DRIVER',
	AWAITS_PICKUP: 'AWAITS_PICKUP',
	FAILED_PICKUP: 'FAILED_PICKUP',
	IN_PROGRESS: 'IN_PROGRESS', // DELIVERING, kept as IN_PROGRESS for backward compatibility (quick actions)
	RETURNED: 'RETURNED',
	RESCHEDULED: 'RESCHEDULED',
	DELIVERED: 'DELIVERED',
	FAILED: 'FAILED',
	CROSSDOCKED: 'CROSSDOCKED',
	NOT_CROSSDOCKED: 'NOT_CROSSDOCKED',
	PENDING: 'PENDING',
	REJECTED: 'REJECTED',
	APPROVED: 'APPROVED',
	CANCELED: 'CANCELED',
	IN_THE_VEHICLE_CANCELED: 'IN_THE_VEHICLE_CANCELED'
}

export const FORM_TAG_STATUS_MAP = {
	PICKUP_LAST_MILE: PARCEL_STATUS.IN_PROGRESS,
	PICKUP_FIRST_MILE: PARCEL_STATUS.IN_PROGRESS,
	PICKUP_FAIL: PARCEL_STATUS.IN_PROGRESS,
	DROP_OFF_SUCCESS: PARCEL_STATUS.DELIVERED,
	DROP_OFF_FAILURE: PARCEL_STATUS.FAILED,
	DROP_OFF_WAREHOUSE: PARCEL_STATUS.RETURNED,
	CROSSDOCK_DROP_OFF: PARCEL_STATUS.PENDING,
	CROSSDOCK_PICKUP: PARCEL_STATUS.IN_PROGRESS
}

export const COLOR_MAP = {
	AWAITS_DRIVER: lightBlue,
	FAILED_PICKUP: llmSummaryFailedPickup,
	AWAITS_PICKUP: llmInfo,
	CANCELED: llmSUmmaryCanceled,
	IN_PROGRESS: llmBlue,
	RESCHEDULED: llmWarning,
	DELIVERED: llmSuccess,
	RETURNED: llmBlue,
	FAILED: llmError,
	CROSSDOCKED: dark,
	PENDING: llmWarning,
	REJECTED: llmError,
	APPROVED: llmSuccess
}

export const DRIVER_APP_NAVIGATION_EVENT = 'driver-app-navigation'

export const CROSSDOCK_STATUS = [
	PARCEL_STATUS.PENDING,
	PARCEL_STATUS.REJECTED,
	PARCEL_STATUS.APPROVED
]

export const SUMMARY_TAB = {
	TO_PICK_UP: 'TO_PICK_UP',
	IN_THE_VEHICLE: 'IN_THE_VEHICLE',
	OFFLOADED: 'OFFLOADED'
}
