import storage, { DATA_CENTER } from 'utils/storage'

const { REACT_APP_BR_TELEGRAM_BOT_URL, REACT_APP_SG_TELEGRAM_BOT_URL } =
	process.env

export function getTelegramBotURL() {
	return storage.getItem(DATA_CENTER) === 'br'
		? REACT_APP_BR_TELEGRAM_BOT_URL
		: REACT_APP_SG_TELEGRAM_BOT_URL
}
